import { Box, Stack } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import { LogoWithTextSvg } from "static/image/common/SvgAssets";
import useIsInappWebview from "hooks/useIsInappWebview";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import PaymentModule from "./PaymentModule";
import HomeTaskList from "components/task-list/HomeTaskList";
import MyPageModule from "./MyPageModule";
import { theme } from "../../styles/theme";

export type SidebarMenuType = "patient" | "task";

export default function Sidebar({ isHome }: { isHome?: boolean }) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";
  const isInappWebview = useIsInappWebview();
  const { isCanceled, isExpired, isNotStarted } = useSubscriptionStatus();
  const isOnboardingTaskDone = user?.isOnboardingTaskDone === true;

  const isV2Program = user?.programVersion === "v2";
  const [currentMenu, setCurrentMenu] = useState<SidebarMenuType>(
    !isCoach ? "task" : "patient"
  );

  const backgroundColor = isInappWebview
    ? theme.vars.palette.Background.Level1
    : theme.vars.palette.Background.Level2;

  return (
    <Sheet>
      <Sheet
        className="Sidebar"
        sx={{
          position: !isHome && isInappWebview ? "fixed" : "sticky",

          transform:
            !isHome && isInappWebview ? "translateX(-100%)" : "translateX(0)",
          transition: "transform 0.4s, width 0.4s",
          width: "var(--Sidebar-width)",

          // paddingBottom: `${20}px`,
          height: "100%",
          top: 0,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          backgroundColor: backgroundColor,
          overflow: "hidden",
        }}
      >
        <Stack
          sx={
            {
              // backgroundColor: "white",
              // borderBottomWidth: "1px",
              // borderColor: "divider",
            }
          }
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              px: "24px",
              height: "var(--StickyHeader-height)",
              minHeight: "var(--StickyHeader-height)",
            }}
          >
            <LogoWithTextSvg />
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            width: "200%",
            minHeight: 0,
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "var(--Sidebar-width)",
              px: "20px",
              pb: 10,
              minHeight: 0,
              height: "100%",
              overflow: "hidden auto",
            }}
          ></Box>

          <Box
            sx={{
              width: "var(--Sidebar-width)",
              pb: 10,
              minHeight: 0,
              height: "100%",
              overflow: "hidden auto",
              transform:
                currentMenu === "task" ? "translateX(-100%)" : "translateX(0)",
              transition: "transform 0.4s, width 0.4s",
              backgroundColor: backgroundColor,
              zIndex: 10,
            }}
          >
            <HomeTaskList
              setCurrentMenu={setCurrentMenu}
              backgroundColor={backgroundColor}
            />
            {isInappWebview && (
              <Box sx={{}}>
                <MyPageModule />
              </Box>
            )}
          </Box>
        </Stack>
        {user?.role === "patient" &&
          (isCanceled ||
          isExpired ||
          (isV2Program && isNotStarted && isOnboardingTaskDone) ? (
            <Box>
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    zIndex: 10,
                    left: 0,
                    width: "100%",
                    height: "12px",
                  }}
                />
                <PaymentModule type="footer" />
              </Box>
            </Box>
          ) : (
            <></>
          ))}

        {!isInappWebview && (
          <Box
            sx={{
              paddingBottom: "20px",
            }}
          >
            <MyPageModule />
          </Box>
        )}
      </Sheet>
    </Sheet>
  );
}