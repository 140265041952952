import React, { useEffect, useRef } from "react";
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import { BottomNextButton } from "components/common/BottomNextButton";
import HabitChart from "components/common/HabitChart";
import { useOnboardingData } from "../../../../hooks/useOnboardingData";
import { selectOptionList } from "../goal/Goal_6";
import { useMutation } from "@tanstack/react-query";
import { onboardingApi } from "../../../../api/onboardingApi";

export default function Habit_4() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/habit/5`);
  };

  const { singleSelectionId } = useOnboardingData("goal_6");
  const option = selectOptionList.find((e) => e.id === singleSelectionId);
  const labelPercentage = option?.score || 24;

  const pageKey = "habit_4";

  const { mutate: savePage } = useMutation(
    (pageId: string) => onboardingApi.savePage(pageId),
    {
      onSuccess: () => {},
    }
  );

  function useEffectOnce(callback: () => void) {
    const ref = useRef(false);

    useEffect(() => {
      if (ref.current) return;
      ref.current = true;

      if (typeof callback === "function") {
        callback();
      }
    }, []);
  }

  useEffectOnce(() => {
    savePage(pageKey);
  });

  return (
    <Stack sx={{ paddingBottom: "120px" }}>
      <Stack
        spacing={1.5}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          {"지금까지의 답변을 분석한 예측입니다."}
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {`8주 뒤 증상이 ${labelPercentage}% 호전될 것으로 예상됩니다.`}
        </CustomTypography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // 수평 가운데 정렬
          }}
        >
          <Box padding="40px 24px" maxWidth="380px" width="100%">
            <HabitChart labelValue={`${labelPercentage}%`} />
          </Box>
        </Box>
      </Stack>
      <Stack spacing={3}>
        <CustomTypography
          variant="Body"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Medium,
          }}
        >
          {`아주 좋습니다. 나의 마음 관리 인식과 습관에 따르면, `}
          <Box
            component="span"
            sx={{
              color: theme.vars.palette.Content.Highlight,
              fontWeight: 700,
            }}
          >{`2주 더 빠르게`}</Box>
          {` ${labelPercentage}%의 증상 개선을 이룰 수 있을 것으로 예상됩니다.`}
        </CustomTypography>
        <CustomTypography
          variant="Body"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Medium,
          }}
        >
          <Box
            component="span"
            sx={{
              color: theme.vars.palette.Content.Highlight,
              fontWeight: 700,
            }}
          >{`더 큰 폭으로 개선될 여지도 있습니다.`}</Box>
          {
            " 보다 정확한 예측을 위해 나의 의지와 동기부여 수준을 살펴보도록 하겠습니다."
          }
        </CustomTypography>
        <CustomTypography
          variant="Caption"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Medium,
          }}
        >
          {"* 위 예측치는 15만 개의 데이터 포인트로 분석한 결과임"}
        </CustomTypography>
      </Stack>
      <BottomNextButton disabled={false} onClick={handleNext} text={"다음"} />
    </Stack>
  );
}