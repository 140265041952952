import React, { useEffect, useState } from "react";
import { Chip, Grid, Stack, Typography } from "@mui/joy";
import {
  AutomaticThought,
  AutomaticThoughtFieldKeyType,
  AutomaticThoughtFieldMap,
  conceptApi,
  IndividualThoughtType,
} from "api/conceptApi";

import DeleteAutomaticThoughtButton from "components/coachRightSidebar/conceptNote/DeleteAutomaticThoughtButton";

import AutomaticThoughtInput from "./AutomaticThoughtInput";
import { useMutation } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import AutomaticThoughtConfirmButton from "./AutomaticThoughtConfirmButton";
import ResetAutomaticThoughtButton from "../ResetAutomaticThoughtButton";

interface AutomaticThoughtListItemProps {
  automaticThought: AutomaticThought;
  automaticThoughtList: AutomaticThought[];
  onDelete?: (automaticThoughtId: string) => void;
  highlight?: boolean;
}

export default function AutomaticThoughtListItem({
  automaticThought,
  automaticThoughtList,
  onDelete,
  highlight = false,
}: AutomaticThoughtListItemProps) {
  const [localData, setLocalData] =
    useState<AutomaticThought>(automaticThought);
  const [canBeConfirmed, setCanBeConfirmed] = useState<boolean>(false);

  const patientState = useRecoilValue(patientAtom);

  useEffect(() => {
    setLocalData(automaticThought);
  }, [automaticThought]);

  useEffect(() => {
    const validSituation = !!localData.situation?.trim();
    // thoughtList 중 하나라도 thought, thoughtTrap 모두 채워진 게 있으면 만족
    const validThoughtList =
      (localData.thoughtList?.length ?? 0) >= 1 &&
      !!localData.thoughtList!![0]?.thought?.trim() &&
      !!localData.thoughtList!![0]?.thoughtTrap?.trim();

    const validEmotion = !!localData.emotion?.trim();
    const validPhysicalResponse = !!localData.physicalResponse?.trim();
    const validBehavior = !!localData.behavior?.trim();

    setCanBeConfirmed(
      validSituation &&
        validThoughtList &&
        validEmotion &&
        validPhysicalResponse &&
        validBehavior
    );
  }, [localData]);

  const { mutate: updateAutomaticThought } = useMutation<
    void, // 성공 응답 타입
    unknown, // 에러 타입
    AutomaticThoughtFieldMap
  >(
    async (payload) => {
      await conceptApi.updateAutomaticThought(
        `${patientState?.patientId}`,
        automaticThought.automaticThoughtId,
        payload
      );
    },
    {
      onSuccess: (_data, variables) => {},
      onError: (error) => {
        console.error("Note update failed:", error);
      },
    }
  );

  const handleSaveSituation = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      situation: newValue,
    }));

    updateAutomaticThought({
      situation: newValue,
    });
  };

  function handleUpdateThoughtAndThoughtTrapList(
    index: number,
    field: keyof IndividualThoughtType,
    newValue: string
  ) {
    const newThoughtList = [...(localData.thoughtList ?? [])];

    while (newThoughtList.length <= index) {
      newThoughtList.push({ thought: "", thoughtTrap: "" });
    }

    newThoughtList[index] = {
      ...newThoughtList[index],
      [field]: newValue,
    };

    const updatedLocalData = {
      ...localData,
      thoughtList: newThoughtList,
    };
    setLocalData(updatedLocalData);

    const automaticThoughtFieldKey =
      field === "thought"
        ? (`thought${index + 1}` as AutomaticThoughtFieldKeyType)
        : (`thought${index + 1}ThoughtTrap` as AutomaticThoughtFieldKeyType);

    updateAutomaticThought({
      [automaticThoughtFieldKey]: newValue,
    });
  }

  const handleSaveEmotion = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      emotion: newValue,
    }));

    updateAutomaticThought({
      emotion: newValue,
    });
  };

  const handleSavePhysicalResponse = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      physicalResponse: newValue,
    }));

    updateAutomaticThought({
      physicalResponse: newValue,
    });
  };

  const handleSaveBehavior = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      behavior: newValue,
    }));

    updateAutomaticThought({
      behavior: newValue,
    });
  };

  return (
    <Stack
      spacing={1}
      p={0.3}
      sx={{
        ...(highlight && {
          borderRadius: "4px",
          boxShadow: `0 0 0 1px rgba(15,85,167,0.3)`,
        }),
        backgroundColor: "unset",
        transition: "background-color 0.3s ease",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography level="body-xs">
            자동적 사고 {localData.sequence}
          </Typography>

          {(localData.automaticThoughtStatus === "note_confirmed" ||
            localData.automaticThoughtStatus === "confirmed") && (
            <>
              {localData.taskProgressStatus === "in_progress" ? (
                <Chip size="sm" color="success" variant="outlined">
                  진행 중
                </Chip>
              ) : localData.taskProgressStatus === "completed" ? (
                <Chip size="sm" color="neutral" variant="outlined">
                  완료
                </Chip>
              ) : (
                <Chip size="sm" color="warning" variant="outlined">
                  대기 중
                </Chip>
              )}
            </>
          )}
        </Stack>

        <Stack direction="row" spacing={1}>
          {localData.automaticThoughtStatus !== "preparing" && (
            <ResetAutomaticThoughtButton
              automaticThoughtId={localData.automaticThoughtId}
              thoughtRecordKey={localData.thoughtRecordKey}
            />
          )}
          {localData.automaticThoughtStatus === "preparing" && onDelete && (
            <DeleteAutomaticThoughtButton
              automaticThoughtId={localData.automaticThoughtId}
              thoughtRecordKey={localData.thoughtRecordKey}
            />
          )}
          {localData.automaticThoughtStatus === "preparing" && (
            <AutomaticThoughtConfirmButton
              automaticThoughtId={localData.automaticThoughtId}
              isOtherAutomaticThoughtAlreadyProgress={
                automaticThoughtList.filter(
                  (e) =>
                    e.taskProgressStatus === "in_progress" &&
                    !e.isForValue &&
                    e.automaticThoughtId !== localData.automaticThoughtId
                ).length >= 1
              }
              disabled={!canBeConfirmed}
            />
          )}
        </Stack>
      </Stack>

      {/* 상황 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">상황</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.situation ?? ""}
            onSave={handleSaveSituation}
          />
        </Grid>
      </Grid>

      {/* 생각(thoughtList) */}
      {(localData.thoughtList || []).map((item, idx) => (
        <Stack spacing={1} key={idx}>
          {/* 생각 */}
          <Grid container>
            <Grid xs={2.5} sx={{ pt: "10px" }}>
              {idx === 0 && <Typography level="body-xs">생각</Typography>}
            </Grid>
            <Grid xs={9.5}>
              <AutomaticThoughtInput
                type="textarea"
                initialValue={item.thought ?? ""}
                onSave={(newValue) =>
                  handleUpdateThoughtAndThoughtTrapList(
                    idx,
                    "thought",
                    newValue
                  )
                }
              />
            </Grid>
          </Grid>

          {/* 생각 함정(thoughtTrap) */}
          <Grid container>
            <Grid xs={2.5} sx={{ pt: "10px" }}></Grid>
            <Grid xs={9.5}>
              <AutomaticThoughtInput
                type="select"
                initialValue={item.thoughtTrap ?? ""}
                onSave={(newValue) =>
                  handleUpdateThoughtAndThoughtTrapList(
                    idx,
                    "thoughtTrap",
                    newValue
                  )
                }
              />
            </Grid>
          </Grid>
        </Stack>
      ))}

      {/* 감정 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">감정</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.emotion ?? ""}
            onSave={handleSaveEmotion}
          />
        </Grid>
      </Grid>

      {/* 신체 반응 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">신체 반응</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.physicalResponse ?? ""}
            onSave={handleSavePhysicalResponse}
          />
        </Grid>
      </Grid>

      {/* 행동 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">행동</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.behavior ?? ""}
            onSave={handleSaveBehavior}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
