import { Box, Stack, Typography } from "@mui/joy";
import {
  CompareWithCounseling,
  PaymentButton,
} from "components/task/payment/elements";
import React, { useEffect, useRef, useState } from "react";
import { SxProps } from "@mui/joy/styles/types";
import pcAndMobileImg from "static/image/payment/pc_and_mobile.png";
import reviewAvatar1Img from "static/image/payment/review_avatar_1.svg";
import reviewAvatar2Img from "static/image/payment/review_avatar_2.svg";
import reviewAvatar3Img from "static/image/payment/review_avatar_3.svg";
import numWithCircle1Img from "static/image/payment/NumWithCircle_1.svg";
import numWithCircle2Img from "static/image/payment/NumWithCircle_2.svg";
import numWithCircle3Img from "static/image/payment/NumWithCircle_3.svg";
import numWithCircle4Img from "static/image/payment/NumWithCircle_4.svg";
import checkIconImg from "static/image/payment/Check.svg";
import paybackMarkImg from "static/image/onboarding/payback_x2.svg";
import calendarImg from "static/image/payment/calendar.svg";
import manyImg from "static/image/payment/many.svg";
import cheaperImg from "static/image/payment/cheaper.svg";
import chartImg from "static/image/payment/chart.svg";
import sponsorImg from "static/image/payment/sponsor_payment.png";
import fivestarsImg from "static/image/onboarding/fivestars.svg";
import useGetDeviceInfo from "hooks/useGetDeviceInfo";
import useGetUrls from "hooks/useGetUrls";
import { sendMessage } from "utils/webview";
import useIsInappWebview from "hooks/useIsInappWebview";
import { theme } from "styles/theme";
import FloatingArea from "components/common/FloatingArea";
import { extractHexColor } from "../../../styles/hexOpacity";
import { useRecoilState } from "recoil";
import { paymentOrderAtom } from "../../../recoil/paymentOrderAtom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  PAYMENT_DOMAIN,
  PAYMENT_ORDER_DETAIL_ENDPOINT,
  paymentApi,
} from "api/paymentApi";
import { userAtom } from "../../../recoil/userAtom";
import CustomTypography from "../../common/CustomTypography";
import { onboardingApi } from "../../../api/onboardingApi";
import CustomTypographyHighlight from "../../common/CustomTypographyHighlight";
import QnAV2 from "./elements/QnAV2";
import ContentTemplateV2 from "../content/ContentTemplateV2";
import UserInfo from "../../user/UserInfo";
import CxButton from "../../sidebar/CXButton";
import LinkV2 from "../../common/LinkV2";
import ReviewItem from "./elements/ReviewItem";
import { compareVersions } from "../../../logic/logics_common";

const numberWithCicle = [
  numWithCircle1Img,
  numWithCircle2Img,
  numWithCircle3Img,
  numWithCircle4Img,
];
// 예시: numbered list 아이템
const majorTrainingPlan = [
  "알아차림과 주의력 통제하기",
  "생각을 심리적 사건으로 바라보기",
  "있는 그대로를 기꺼이 받아들이기",
  "가치를 발견하고 실천하기",
];

const bookCouponManual = [
  "책을 주문합니다.",
  "책이 오기 전, 프로그램을 결제하고 시작합니다.",
  "책이 도착하면 동봉된 쿠폰으로 페이백을 신청합니다.",
];

// 예시: check list 아이템
const programFeatures = [
  {
    main: "매일 30분 분량의 활동지",
    postLabel: "(휴일 제외)",
  },
  {
    main: "전문 코치의 상시 지원",
    postLabel: "(평일 근무시간 내)",
  },
  {
    main: "2주 간격의 트래킹 검사",
  },
  {
    main: "다양한 용도의 명상훈련지",
  },
  {
    main: "일상에서 연습할 생각기록지",
  },
];

export default function PaymentContent3Month({
  fullWidth = false,
}: {
  fullWidth?: boolean;
}) {
  const floatingAreaRef = useRef<HTMLDivElement>(null);
  const isInappWebview = useIsInappWebview();
  const { appVersion } = useGetDeviceInfo();

  const paddingX = 4.5;

  const [user, setUser] = useRecoilState(userAtom);

  const [paymentOrderState, setPaymentOrderState] =
    useRecoilState(paymentOrderAtom);

  const { refetch: getPaymentOrder } = useQuery(
    [PAYMENT_DOMAIN, PAYMENT_ORDER_DETAIL_ENDPOINT],
    () => paymentApi.getOrderDetail(),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data && data.result) {
          setPaymentOrderState(data.result);
        }
      },
    }
  );

  const pageKey = "payment_1";

  const { mutate: savePage } = useMutation(
    (pageId: string) => onboardingApi.savePage(pageId),
    {
      onSuccess: () => {},
    }
  );

  function useEffectOnce(callback: () => void) {
    const ref = useRef(false);

    useEffect(() => {
      if (ref.current) return;
      ref.current = true;

      if (typeof callback === "function") {
        callback();
      }
    }, []);
  }

  useEffectOnce(() => {
    savePage(pageKey);

    if (isInappWebview && compareVersions(appVersion, "1.1.17") >= 0) {
      sendMessage(
        JSON.stringify({
          event: "send_fb_event",
          params: {
            eventName: "page_view_payment",
          },
        })
      );
    }
  });

  useEffect(() => {
    getPaymentOrder();
  }, []);

  const { companyInfoUrl, privacyPolicyUrl, servicePolicyUrl } = useGetUrls();

  useEffect(() => {
    if (isInappWebview) {
      const topBackground = extractHexColor(
        theme.vars.palette.Background.Level3
      );
      const bottomBackground = extractHexColor(
        theme.vars.palette.Background.Level1
      );

      if (topBackground && bottomBackground) {
        sendMessage(
          JSON.stringify({
            event: "set_safearea_color",
            params: {
              topColor: `${topBackground}`,
              bottomColor: `${bottomBackground}`,
            },
          })
        );
      }
    }
  }, []);

  const Title = ({ title }: { title: string }) => {
    return (
      <Typography
        level={"h2"}
        sx={{
          fontWeight: 700,
        }}
      >
        {title}
      </Typography>
    );
  };

  const Container = ({
    children,
    title,
    customSx,
    backgroundColor = theme.vars.palette.Background.Level3,
  }: {
    children: React.ReactNode;
    title?: string;
    customSx?: SxProps;
    backgroundColor?: string;
  }) => {
    return (
      <Stack sx={{ pt: "50px", pb: "50px", backgroundColor: backgroundColor }}>
        <Stack
          spacing={"30px"}
          sx={{ width: "100%", maxWidth: "720px", mx: "auto" }}
        >
          {title && (
            <Stack sx={{ px: paddingX }}>
              <Title title={title} />
            </Stack>
          )}
          <Stack sx={{ px: paddingX, ...customSx }}>{children}</Stack>
        </Stack>
      </Stack>
    );
  };
  const { homeIndicatorHeight } = useGetDeviceInfo();
  const [containerHeight, setContainerHeight] = useState<number>(
    window.innerHeight - homeIndicatorHeight
  );
  const [floatingAreaHeight, setFloatingAreaHeight] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      setFloatingAreaHeight(floatingAreaRef.current?.offsetHeight || 0);
    }, 100);
  }, [floatingAreaRef.current]);

  return (
    <ContentTemplateV2 noPadding isWide setContainerHeight={setContainerHeight}>
      {containerHeight > 0 && (
        <Stack
          sx={{
            pb: "150px",
            backgroundColor: theme.vars.palette.Background.Level3,
          }}
        >
          {/*영역 1: ~님의 프로그램 */}
          <Stack
            spacing={"30px"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              maxWidth: "720px",
              mx: "auto",
              px: paddingX,
              pt: 6,
              pb: 8,
              // height: `calc(${containerHeight}px - ${floatingAreaHeight}px)`,
              // pt: "50px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                backgroundColor: theme.vars.palette.Background.Level3, // 상단 연한 파란색 배경
                textAlign: "center",
              }}
            >
              <CustomTypography
                variant={"H1"}
                sx={{
                  paddingTop: "54px",
                  color: theme.vars.palette.Content.Strong,
                  fontWeight: 800,
                }}
              >
                {`${user?.nickname}님의 프로그램`}
              </CustomTypography>
              <Typography
                variant={"Subtitle"}
                sx={{
                  paddingTop: 2,
                  color: theme.vars.palette.Content.Medium,
                  fontWeight: 500,
                }}
              >
                {"마음을 다루는 근거있고\n효과적인 방법을 제안합니다."}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "16px",
                py: 3,
                pl: 3,
                pr: 0.5,
              }}
            >
              <Typography
                variant={"Body"}
                sx={{
                  color: theme.vars.palette.Content.Highlight,
                  fontWeight: 700,
                }}
              >
                주요 훈련 계획
              </Typography>
              <Stack spacing={0.5} sx={{ mt: "12px" }}>
                {majorTrainingPlan.map((text, idx) => (
                  <Stack direction={"row"} spacing={0.25} alignItems="center">
                    <img
                      src={numberWithCicle[idx]}
                      alt={"number"}
                      style={{}}
                    ></img>
                    <CustomTypography
                      key={idx}
                      variant={"Body"}
                      sx={{
                        color: theme.vars.palette.Content.Strong,
                        fontWeight: 500,
                      }}
                    >
                      {text}
                    </CustomTypography>
                  </Stack>
                ))}
              </Stack>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "16px",
                p: 3,
              }}
            >
              <Typography
                variant={"Body"}
                sx={{
                  color: theme.vars.palette.Content.Highlight,
                  fontWeight: 700,
                }}
              >
                프로그램 구성
              </Typography>
              <Stack spacing={0.5} mt="12px">
                {programFeatures.map(({ main, postLabel }, idx) => (
                  <Stack
                    key={idx}
                    direction="row"
                    alignItems="center"
                    spacing={0.25}
                  >
                    <img src={checkIconImg} alt={"checkImg"}></img>

                    <CustomTypography sx={{ p: 0 }}>
                      <CustomTypography
                        variant={"Body"}
                        sx={{
                          color: theme.vars.palette.Content.Strong,
                          fontWeight: 500,
                        }}
                      >
                        {main}
                      </CustomTypography>
                      {postLabel && (
                        <CustomTypography
                          variant={"Caption"}
                          sx={{
                            color: theme.vars.palette.Content.Medium,
                            fontWeight: 500,
                            p: 0,
                          }}
                        >
                          {postLabel}
                        </CustomTypography>
                      )}
                    </CustomTypography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Stack>

          {/*영역2 3개월 이용권*/}
          <Stack
            spacing={"2px"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              // 전체 박스 스타일
              backgroundColor: theme.vars.palette.Surface.Highlight,
              width: "100%",
              maxWidth: "100%",
              mx: "auto",
              px: paddingX,
              // height: `calc(${containerHeight}px - ${floatingAreaHeight}px)`,
              // pt: "50px",
            }}
          >
            <Box
              sx={{
                maxWidth: "720px",
                paddingY: "60px", // 내부 여백
                width: "100%", // 임시로 폭 지정
                margin: "0 auto", // 가운데 정렬 (선택 사항)
              }}
            >
              {/* 첫 줄(좌우 배치) */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant={"H3"}
                  sx={{
                    color: theme.vars.palette.Content.Negative,
                    fontWeight: 700,
                  }}
                >
                  3개월 이용권
                </Typography>
                <Typography
                  variant={"H3"}
                  sx={{
                    color: theme.vars.palette.Content.Negative,
                    fontWeight: 700,
                  }}
                >
                  {paymentOrderState?.promotionType === "book"
                    ? "265,000원"
                    : "295,000원"}
                </Typography>
              </Stack>

              <Typography
                variant={"Body"}
                sx={{
                  pt: 1,
                  color: theme.vars.palette.Content.SoftHighlight,
                  fontWeight: 500,
                }}
              >
                {paymentOrderState?.promotionType === "book"
                  ? "책 구매 쿠폰 적용가"
                  : "3개월 후 불만족시 200% 페이백 보장"}
              </Typography>
            </Box>
          </Stack>

          {/*영역3: 200% 페이백 보장*/}
          <Stack
            py={10}
            spacing={"30px"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              // 전체 박스 스타일
              backgroundColor: theme.vars.palette.Background.Level1,
              width: "100%",
              maxWidth: "100%",
              mx: "auto",
              px: paddingX,
            }}
          >
            <img
              src={paybackMarkImg}
              alt={"empty"}
              style={{
                width: "100%",
                maxWidth: "121px",
              }}
            />
            <Typography
              variant={"H2"}
              sx={{
                color: theme.vars.palette.Content.Strong,
                fontWeight: 590,
              }}
            >
              {"200% 페이백 보장"}
            </Typography>
            <CustomTypographyHighlight
              variant={"Subtitle"}
              sx={{
                color: theme.vars.palette.Content.Medium,
                fontWeight: 500,
              }}
              highlightedText={"590,000원을 페이백"}
              highlightVariant={"Subtitle"}
              highlightSx={{
                color: theme.vars.palette.Content.Strong,
                fontWeight: 700,
              }}
            >
              {"디스턴싱 프로그램의 첫째 주 만족도는 92%. 그러나 3개월 뒤 이 만족도는 98%로 더욱 높아집니다.\n\n" +
                "3개월 후에도 만족하지 않으신다면, 비용의 200%인 590,000원을 페이백해드리겠습니다.\n\n" +
                "서비스 내 고객센터로 “페이백 요청” 다섯 글자만 보내주세요.\n\n시도해서 잃을 것은 없습니다. 시도하지 않으면 변화할 기회조차 얻지 못합니다."}
            </CustomTypographyHighlight>
          </Stack>

          {/*영역4: 리뷰*/}
          <Stack
            py={10}
            spacing={"30px"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.vars.palette.Content.Strong,
              width: "100%",
              mx: "auto",
              px: paddingX,
              // height: `calc(${containerHeight}px - ${floatingAreaHeight}px)`,
              // pt: "50px",
            }}
          >
            <Box
              sx={{
                px: 2,
                py: 2,
                maxWidth: "720px",
              }}
            >
              <Stack alignItems="center" spacing={4}>
                <Stack alignItems="center" spacing={1}>
                  <img
                    src={fivestarsImg}
                    alt={"empty"}
                    style={{
                      width: "100%",
                      maxWidth: "124px",
                      // objectFit: "cover",
                      // objectPosition: "50% 0%",
                    }}
                  />

                  <Typography
                    variant={"H2"}
                    sx={{
                      color: theme.vars.palette.Content.SoftHighlight,
                      fontWeight: 590,
                    }}
                  >
                    200+개의 5점 리뷰
                  </Typography>
                </Stack>

                {/* 후기 항목들 */}
                <Stack spacing={4} alignItems="center">
                  <ReviewItem
                    avatarIcon={reviewAvatar1Img}
                    text={"생각을 바라보는\n완전히 새로운 접근"}
                  />
                  <ReviewItem
                    avatarIcon={reviewAvatar2Img}
                    text={"신선하고 사려깊은\n과학적인 프로그램"}
                  />
                  <ReviewItem
                    avatarIcon={reviewAvatar3Img}
                    text={"지금껏 받아봤던\n인지행동치료 중 최고"}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>

          {/*영역: 책소개*/}
          {/*<Stack*/}
          {/*  py={10}*/}
          {/*  spacing={5}*/}
          {/*  justifyContent="center"*/}
          {/*  alignItems={"center"}*/}
          {/*  sx={{*/}
          {/*    // 전체 박스 스타일*/}
          {/*    backgroundColor: theme.vars.palette.Surface.Forward,*/}
          {/*    width: "100%",*/}
          {/*    maxWidth: "100%",*/}
          {/*    mx: "auto",*/}
          {/*    px: paddingX,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <img*/}
          {/*    src={bookImg}*/}
          {/*    alt={"empty"}*/}
          {/*    style={{*/}
          {/*      width: "100%",*/}
          {/*      maxWidth: "480px",*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <Stack*/}
          {/*    spacing={"28px"}*/}
          {/*    alignItems="center"*/}
          {/*    direction={isInappWebview ? "column" : "row"}*/}
          {/*  >*/}
          {/*    <img*/}
          {/*      src={bookRecommend1Img}*/}
          {/*      alt={"empty"}*/}
          {/*      style={{*/}
          {/*        width: "100%",*/}
          {/*        maxWidth: "220px",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <img*/}
          {/*      src={bookRecommend2Img}*/}
          {/*      alt={"empty"}*/}
          {/*      style={{*/}
          {/*        width: "100%",*/}
          {/*        maxWidth: "220px",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <img*/}
          {/*      src={bookRecommend3Img}*/}
          {/*      alt={"empty"}*/}
          {/*      style={{*/}
          {/*        width: "100%",*/}
          {/*        maxWidth: "220px",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Stack>*/}

          {/*  <Stack spacing={5} alignItems="center" sx={{ maxWidth: "720px", pt: 5}}>*/}
          {/*    <Typography*/}
          {/*      variant={"H2"}*/}
          {/*      sx={{*/}
          {/*        color: theme.vars.palette.Content.Strong,*/}
          {/*        fontWeight: 700,*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {"책으로도 출간된\n디스턴싱 훈련법"}*/}
          {/*    </Typography>*/}
          {/*    <CustomTypographyHighlight*/}
          {/*      variant={"Subtitle"}*/}
          {/*      sx={{*/}
          {/*        color: theme.vars.palette.Content.Medium,*/}
          {/*        fontWeight: 500,*/}
          {/*        textAlign: "center"*/}
          {/*      }}*/}
          {/*      highlightedText={"⌜나는 내 생각을 다 믿지 않기로 했다⌟"}*/}
          {/*      highlightVariant={"Subtitle"}*/}
          {/*      highlightSx={{*/}
          {/*        color: theme.vars.palette.Content.Strong,*/}
          {/*        fontWeight: 700,*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {*/}
          {/*        "서울대 의과대학을 졸업한 후 디스턴싱 프로그램을 만든 홍승주 대표가 직접 쓴 심리치료 가이드. ⌜나는 내 생각을 다 믿지 않기로 했다⌟"*/}
          {/*      }*/}
          {/*    </CustomTypographyHighlight>*/}

          {/*    <LinkV2*/}
          {/*      variant={"Body"}*/}
          {/*      text={"더 알아보기"}*/}
          {/*      customSx={{*/}
          {/*        fontWeight: 700,*/}
          {/*        color: theme.vars.palette.Content.Highlight,*/}
          {/*      }}*/}
          {/*      endDecorator={*/}
          {/*        <img*/}
          {/*          src={chevronImg}*/}
          {/*          alt={"empty"}*/}
          {/*          style={{*/}
          {/*            width: "100%",*/}
          {/*            maxWidth: "35px",*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      }*/}
          {/*      url="https://www.yes24.com/campaign/01_Book/yesFunding/yesFundingBook.aspx?EventNo=256569"*/}
          {/*    />*/}
          {/*  </Stack>*/}
          {/*</Stack>*/}

          {/*영역: 책 쿠폰 */}
          {/*<Stack*/}
          {/*  spacing={5}*/}
          {/*  justifyContent="center"*/}
          {/*  alignItems={"center"}*/}
          {/*  sx={{*/}
          {/*    width: "100%",*/}
          {/*    maxWidth: "720px",*/}
          {/*    mx: "auto",*/}
          {/*    px: paddingX,*/}
          {/*    pt: 6,*/}
          {/*    pb: 8,*/}
          {/*    // height: `calc(${containerHeight}px - ${floatingAreaHeight}px)`,*/}
          {/*    // pt: "50px",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Stack*/}
          {/*    spacing={5}*/}
          {/*    alignItems={"center"}*/}
          {/*    sx={{*/}
          {/*      width: "100%",*/}
          {/*      textAlign: "center",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <CustomTypography*/}
          {/*      variant={"H2"}*/}
          {/*      sx={{*/}
          {/*        color: theme.vars.palette.Content.Strong,*/}
          {/*        fontWeight: 700,*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {`책과 함께하면\n3만 원 페이백`}*/}
          {/*    </CustomTypography>*/}
          {/*    <CustomTypographyHighlight*/}
          {/*      variant={"Subtitle"}*/}
          {/*      sx={{*/}
          {/*        color: theme.vars.palette.Content.Medium,*/}
          {/*        fontWeight: 500,*/}
          {/*      }}*/}
          {/*      highlightedText={"3만 원 페이백 쿠폰"}*/}
          {/*      highlightVariant={"Subtitle"}*/}
          {/*      highlightSx={{*/}
          {/*        color: theme.vars.palette.Content.Strong,*/}
          {/*        fontWeight: 700,*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {*/}
          {/*        "책에는 디스턴싱 프로그램 3만 원 페이백 쿠폰이 포함되어 있습니다."*/}
          {/*      }*/}
          {/*    </CustomTypographyHighlight>*/}

          {/*    <Box*/}
          {/*      textAlign={"start"}*/}
          {/*      sx={{*/}
          {/*        width: "100%",*/}
          {/*        backgroundColor: "white",*/}
          {/*        borderRadius: "16px",*/}
          {/*        py: 3,*/}
          {/*        pl: 3,*/}
          {/*        pr: 0.5,*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <Typography*/}
          {/*        variant={"Body"}*/}
          {/*        sx={{*/}
          {/*          color: theme.vars.palette.Content.Highlight,*/}
          {/*          fontWeight: 700,*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        쿠폰 사용 방법*/}
          {/*      </Typography>*/}
          {/*      <Stack spacing={0.5} sx={{ mt: "12px" }}>*/}
          {/*        {bookCouponManual.map((text, idx) => (*/}
          {/*          <Stack direction={"row"} spacing={0.25} alignItems="center">*/}
          {/*            <img*/}
          {/*              src={numberWithCicle[idx]}*/}
          {/*              alt={"number"}*/}
          {/*              style={{}}*/}
          {/*            ></img>*/}
          {/*            <CustomTypography*/}
          {/*              key={idx}*/}
          {/*              variant={"Body"}*/}
          {/*              sx={{*/}
          {/*                color: theme.vars.palette.Content.Strong,*/}
          {/*                fontWeight: 500,*/}
          {/*              }}*/}
          {/*            >*/}
          {/*              {text}*/}
          {/*            </CustomTypography>*/}
          {/*          </Stack>*/}
          {/*        ))}*/}
          {/*      </Stack>*/}
          {/*    </Box>*/}
          {/*    <LinkV2*/}
          {/*      variant={"Body"}*/}
          {/*      text={"책 주문하고 쿠폰 받기"}*/}
          {/*      customSx={{*/}
          {/*        fontWeight: 700,*/}
          {/*        color: theme.vars.palette.Content.Highlight,*/}
          {/*      }}*/}
          {/*      endDecorator={*/}
          {/*        <img*/}
          {/*          src={chevronImg}*/}
          {/*          alt={"empty"}*/}
          {/*          style={{*/}
          {/*            width: "100%",*/}
          {/*            maxWidth: "35px",*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      }*/}
          {/*      url="https://www.yes24.com/campaign/01_Book/yesFunding/yesFundingBook.aspx?EventNo=256569"*/}
          {/*    />*/}
          {/*  </Stack>*/}
          {/*</Stack>*/}

          {/*영역5: 3달로 완성되는*/}
          <Stack
            py={10}
            spacing={"30px"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.vars.palette.Background.Level1,
              width: "100%",

              mx: "auto",
              px: paddingX,
            }}
          >
            <Stack
              direction="column"
              alignItems="start"
              spacing={2}
              sx={{ width: "100%", maxWidth: "720px" }}
            >
              <img
                src={calendarImg}
                alt={"empty"}
                style={{
                  width: "100%",
                  maxWidth: "35px",
                  // objectFit: "cover",
                  // objectPosition: "50% 0%",
                }}
              />
              <Typography
                variant={"H2"}
                sx={{
                  p: 0,
                  color: theme.vars.palette.Content.Strong,
                  fontWeight: 700,
                }}
              >
                {"3달로 완성되는\n지속가능한 변화"}
              </Typography>
            </Stack>

            <Stack
              spacing={5}
              justifyContent="center"
              alignItems={"center"}
              sx={{ width: "100%", maxWidth: "720px" }}
            >
              <Stack spacing={3}>
                <CustomTypographyHighlight
                  variant={"Subtitle"}
                  sx={{
                    color: theme.vars.palette.Content.Medium,
                    fontWeight: 500,
                  }}
                  highlightedText={"디스턴싱 기술이 체화"}
                  highlightVariant={"Subtitle"}
                  highlightSx={{
                    color: theme.vars.palette.Content.Strong,
                    fontWeight: 700,
                  }}
                >
                  {
                    "디스턴싱은 지속가능한 변화를 위해 설계되었습니다. 이 지속가능성은 디스턴싱 기술이 체화되어 일상에서 자연스럽게 실천될 때에 완성됩니다."
                  }
                </CustomTypographyHighlight>
                <CustomTypographyHighlight
                  variant={"Subtitle"}
                  sx={{
                    color: theme.vars.palette.Content.Medium,
                    fontWeight: 500,
                  }}
                  highlightedText={"필요한 기간은 3개월"}
                  highlightVariant={"Subtitle"}
                  highlightSx={{
                    color: theme.vars.palette.Content.Strong,
                    fontWeight: 700,
                  }}
                >
                  {
                    "디스턴싱에서 성공적인 효과를 보인 30,000명의 데이터를 분석한 결과 안정적인 정착을 위해 필요한 기간은 3개월이었습니다."
                  }
                </CustomTypographyHighlight>
                <CustomTypographyHighlight
                  variant={"Subtitle"}
                  sx={{
                    color: theme.vars.palette.Content.Medium,
                    fontWeight: 500,
                  }}
                  highlightedText={
                    "디스턴싱은 번쩍이는 요행을 바라지 않습니다."
                  }
                  highlightVariant={"Subtitle"}
                  highlightSx={{
                    color: theme.vars.palette.Content.Strong,
                    fontWeight: 700,
                  }}
                >
                  {
                    "디스턴싱은 번쩍이는 요행을 바라지 않습니다. 디스턴싱은 평생에 걸친 장기적인 변화를 만듭니다."
                  }
                </CustomTypographyHighlight>
              </Stack>
              {/* 하단 그래프 */}
              <img
                src={chartImg}
                alt={"empty"}
                style={{
                  width: "100%",
                  maxWidth: "321px",
                }}
              />
            </Stack>
          </Stack>

          <Stack
            py={10}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: theme.vars.palette.Background.Level3,
              width: "100%",
              maxWidth: "720px",
              mx: "auto",
              position: "relative",
              overflow: "visible",
              // 2) 이미지 높이만큼 아래 공간 확보 (예: 200px 값은 이미지 크기에 맞춰 조정)
              pb: "50px",

              // 아래 섹션과 겹치지 않도록 marginBottom 등 지정
            }}
          >
            <Stack spacing={"44px"} alignItems={"center"}>
              <CustomTypography
                variant={"Body"}
                sx={{
                  color: theme.vars.palette.Content.Medium,
                  fontWeight: 510,
                }}
              >
                {"Supported by"}
              </CustomTypography>
              <img
                src={sponsorImg}
                alt={"empty"}
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  paddingBottom: "60px",
                  // objectFit: "cover",
                  // objectPosition: "50% 0%",
                }}
              />
            </Stack>
            <CustomTypography
              pt={5}
              variant="Body"
              sx={{
                color: theme.vars.palette.Content.Medium,
                fontWeight: 500,
              }}
            >
              PC, 모바일 완벽 지원
            </CustomTypography>

            {/* 3) 절대 배치 박스 (이미지를 오른쪽 밖으로) */}
            <Box
              sx={{
                // 오른쪽 끝을 삐져나가게
                mr: "-60px",
                mt: 2, // 텍스트와 간격
                width: "90%",
                alignSelf: "flex-end", // 스택의 오른쪽에 배치
              }}
            >
              <img
                src={pcAndMobileImg}
                alt="pc_and_mobile"
                style={{
                  width: "100%", // 상황에 맞게 조절
                  height: "auto",
                }}
              />
            </Box>
          </Stack>

          <Stack
            py={10}
            spacing={"30px"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.vars.palette.Background.Level1,
              width: "100%",
              mx: "auto",
              px: paddingX,
            }}
          >
            <Stack spacing={10} sx={{ width: "100%", maxWidth: "720px" }}>
              {[
                {
                  title: "3배 더 많이",
                  text: "디스턴싱에서는 매일 30분 정도의 활동이 제공됩니다. 일주일에 약 150분에 달하는 시간이죠. 반면 일반적인 심리상담은 일주일에 50분만 진행합니다.",
                  icon: manyImg,
                },
                {
                  title: "4배 더 싸게",
                  text: "디스턴싱은 3개월에 295,000원에 이용할 수 있습니다. 일주일에 2만 원 초반대에 불과합니다. 반면 일반적인 심리상담은 일주일에 10만 원이 듭니다.",
                  icon: cheaperImg,
                },
              ].map(({ title, text, icon }) => (
                <Stack
                  spacing="24px"
                  sx={{
                    borderRadius: "12px",
                    // p: "24px",
                    // backgroundColor: "white",
                    // borderWidth: "1px",
                    // borderColor: "divider",
                  }}
                >
                  <Stack
                    spacing={2}
                    direction="column"
                    alignItems="start"
                    sx={{ width: "100%" }}
                  >
                    <img
                      src={icon}
                      alt={"empty"}
                      style={{
                        width: "100%",
                        maxWidth: "35px",
                      }}
                    />
                    <Typography
                      variant={"H2"}
                      sx={{
                        p: 0,
                        color: theme.vars.palette.Content.Strong,
                        fontWeight: 700,
                      }}
                    >
                      {title}
                    </Typography>
                  </Stack>
                  <Stack spacing="20px">
                    <CustomTypography
                      variant={"Subtitle"}
                      sx={{
                        color: theme.vars.palette.Content.Medium,
                        fontWeight: 500,
                      }}
                    >
                      {text}
                    </CustomTypography>
                    <CompareWithCounseling
                      type={title === "3배 더 많이" ? "quantity" : "cost"}
                    />
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>

          {/*영역: 유튜브*/}
          {/*<Stack*/}
          {/*  py={10}*/}
          {/*  spacing={5}*/}
          {/*  justifyContent="center"*/}
          {/*  alignItems={"center"}*/}
          {/*  sx={{*/}
          {/*    // 전체 박스 스타일*/}
          {/*    backgroundColor: theme.vars.palette.Surface.Forward,*/}
          {/*    width: "100%",*/}
          {/*    maxWidth: "100%",*/}
          {/*    mx: "auto",*/}
          {/*    px: paddingX,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Stack*/}
          {/*    spacing={4}*/}
          {/*    alignItems="center"*/}
          {/*    direction={"column"}*/}
          {/*  >*/}
          {/*    <img*/}
          {/*      src={ceoProfileImg}*/}
          {/*      alt={"empty"}*/}
          {/*      style={{*/}
          {/*        width: "100%",*/}
          {/*        maxWidth: "160px",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <img*/}
          {/*      src={youtubeLogoImg}*/}
          {/*      alt={"empty"}*/}
          {/*      style={{*/}
          {/*        width: "100%",*/}
          {/*        maxWidth: "100px",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Stack>*/}

          {/*  <Stack spacing={5} alignItems="center" justifyContent="center" sx={{ maxWidth: "720px"}}>*/}
          {/*    <Typography*/}
          {/*      variant={"H2"}*/}
          {/*      sx={{*/}
          {/*        color: theme.vars.palette.Content.Strong,*/}
          {/*        fontWeight: 700,*/}
          {/*        textAlign: "center"*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {"매주 만나는\n홍승주 대표의 직강"}*/}
          {/*    </Typography>*/}
          {/*    <CustomTypographyHighlight*/}
          {/*      variant={"Subtitle"}*/}
          {/*      sx={{*/}
          {/*        color: theme.vars.palette.Content.Medium,*/}
          {/*        fontWeight: 500,*/}
          {/*        textAlign: "center"*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {*/}
          {/*        "유튜브 채널 ‘홍승주의 디스턴싱’에서 개념을 더욱 자세히 익히며 직접 소통하세요."*/}
          {/*      }*/}
          {/*    </CustomTypographyHighlight>*/}

          {/*    <LinkV2*/}
          {/*      variant={"Body"}*/}
          {/*      text={"채널 바로가기"}*/}
          {/*      customSx={{*/}
          {/*        fontWeight: 700,*/}
          {/*        color: theme.vars.palette.Content.Highlight,*/}
          {/*      }}*/}
          {/*      endDecorator={*/}
          {/*        <img*/}
          {/*          src={chevronImg}*/}
          {/*          alt={"empty"}*/}
          {/*          style={{*/}
          {/*            width: "100%",*/}
          {/*            maxWidth: "35px",*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      }*/}
          {/*      url="https://www.youtube.com/@distancing"*/}
          {/*    />*/}
          {/*  </Stack>*/}
          {/*</Stack>*/}

          <Container title={"자주 묻는 질문"}>
            <QnAV2 />
          </Container>

          <Stack
            spacing={2}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.vars.palette.Background.Level3,
              width: "100%",
              maxWidth: "720px",
              mx: "auto",
              px: paddingX,
            }}
          >
            <Stack direction={"row"} spacing={3}>
              {user && <UserInfo user={user} />}
              {<CxButton type="link" />}
            </Stack>
            <Stack direction={"row"} spacing={3}>
              <LinkV2 text={"사업자 정보"} url={companyInfoUrl} />
              <LinkV2 text={"개인정보 처리방침"} url={privacyPolicyUrl} />
              <LinkV2 text={"서비스 이용약관"} url={servicePolicyUrl} />
            </Stack>
          </Stack>

          <Box>
            <FloatingArea fullWidth={fullWidth} ref={floatingAreaRef}>
              <Stack
                spacing="20px"
                sx={{ width: "100%" }}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Typography
                  variant={"Caption"}
                  sx={{
                    p: 0,
                    fontWeight: 500,
                    color: theme.vars.palette.Content.Medium,
                  }}
                >
                  {paymentOrderState?.promotionType === "book"
                    ? "3개월 265,000원\u00A0\u00A0|\u00A0\u00A0책 구매 쿠폰 적용가"
                    : "3개월 295,000원\u00A0\u00A0|\u00A0\u00A0불만족시 590,000원 페이백"}
                </Typography>
                {paymentOrderState && <PaymentButton />}
              </Stack>
            </FloatingArea>
          </Box>
        </Stack>
      )}
    </ContentTemplateV2>
  );
}
