import { Button } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { addAiComment } from "logic/logics";
import { aiApi } from "api/aiApi";
import { ProgramContentType } from "data/BlockComponent";

export default function AddCommentButton({
  taskKey,
  thoughtRecordKey,
  setContentData,
  setMoveToIndex,
  setIsAiCommentEmpty,
}: {
  taskKey: string;
  thoughtRecordKey?: string;
  setContentData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  setMoveToIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsAiCommentEmpty: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const patientState = useRecoilValue(patientAtom);

  const { mutate: fetchAiComments, isLoading } = useMutation(
    () =>
      aiApi.getCommentList(
        `${patientState?.patientId}`,
        taskKey,
        thoughtRecordKey
      ),
    {
      onSuccess: (data) => {
        const aiCommentList = data.result;
        if (aiCommentList && aiCommentList.length > 0) {
          const addedIndex = addAiComment(setContentData, aiCommentList);
          if (addedIndex !== undefined) {
            setMoveToIndex(addedIndex);
          }
        } else {
          setIsAiCommentEmpty(true);
        }
      },
      onError: (error) => {
        console.error("AI Comment fetch error:", error);
        setIsAiCommentEmpty(true);
      },
    }
  );

  return (
    <Button
      size="sm"
      variant="solid"
      color="primary"
      loading={isLoading}
      onClick={() => {
        fetchAiComments();
      }}
    >
      질문하기
    </Button>
  );
}