import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CONCEPT_DOMAIN, CONCEPT_NOTE_AUTOMATIC_THOUGHT_DETAIL_ENDPOINT,
  CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
  conceptApi,
} from 'api/conceptApi'

import ButtonWithModal from "components/common/ButtonWithModal";
import Link from "components/common/Link";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function DeleteAutomaticThoughtButton({
  automaticThoughtId,
  thoughtRecordKey,
  isForValue = false,
}: {
  automaticThoughtId: string;
  thoughtRecordKey?: string,
  isForValue?: boolean
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const deleteAutomaticThoughtQueryFn = useCallback(
    () =>
      conceptApi.deleteAutomaticThought(
        `${patientState?.patientId}`,
        `${automaticThoughtId}`,
        isForValue
      ),
    [patientState?.patientId, automaticThoughtId, isForValue]
  );

  const { mutate: deleteAutomaticThought } = useMutation(
    deleteAutomaticThoughtQueryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
          patientState?.patientId,
        ]);

        if (thoughtRecordKey) {
          queryClient.invalidateQueries([
            CONCEPT_DOMAIN,
            CONCEPT_NOTE_AUTOMATIC_THOUGHT_DETAIL_ENDPOINT,
            patientState?.patientId,
            thoughtRecordKey,
          ]);
        }
      },
    }
  );

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={"이 자동적 사고를 삭제할까요?"}
      render={<Link text="삭제" opacity={1} textColor="primary.solid" />}
      actionButtonColor={"danger"}
      actionText={"삭제하기"}
      action={deleteAutomaticThought}
    />
  );
}