import {
  Header1,
  Instruction,
  InstructionWithContent,
} from "../../BlockComponent";
import {
  ButtonGroup,
  GuideTypographyLine,
  LineWithSolidLabel,
  TypographyLine,
} from "../../CellComponent";
import { LastCard } from "../common";

export const Content_2_4_A = [
  Instruction("typography_0"),
  Instruction("typography_1"),
  InstructionWithContent(
    `typography_2`,
    LineWithSolidLabel("상황", TypographyLine("typography_2_1"))
  ),
  InstructionWithContent(`typography_3`, [
    ...LineWithSolidLabel("생각", TypographyLine("typography_3_1")),
    ...LineWithSolidLabel("영향 정도", TypographyLine("typography_3_2")),
  ]),
  InstructionWithContent(
    `typography_4`,
    LineWithSolidLabel("감정", TypographyLine("typography_4_1"))
  ),
  InstructionWithContent(
    `typography_5`,
    LineWithSolidLabel("신체 반응", TypographyLine("typography_5_1"))
  ),
  InstructionWithContent(
    `typography_6`,
    LineWithSolidLabel("행동", TypographyLine("typography_6_1"))
  ),

  Header1("typography_7"),
  InstructionWithContent(
    `typography_8`,
    LineWithSolidLabel("상황", TypographyLine("typography_8_1"))
  ),
  Instruction("typography_9"),
  InstructionWithContent(`typography_10`, [
    ...LineWithSolidLabel("상황", TypographyLine("typography_10_1")),
    [
      ButtonGroup(
        [
          {
            text: "잘 적혔어요",
          },
          {
            text: "아니요, 잘못 적혔어요",
          },
        ],
        { id: "quiz1" }
      ),
    ],

    GuideTypographyLine("typography_quiz1_incorrect", {
      answerList: [{ id: "quiz1", answerIndex: 1 }],
    }),
  ]),

  Instruction("typography_11"),
  Instruction("typography_12"),

  Header1("typography_13"),
  InstructionWithContent(`typography_14`, [
    ...LineWithSolidLabel("생각", TypographyLine("typography_14_1")),
    ...LineWithSolidLabel("영향 정도", TypographyLine("typography_14_2")),
  ]),
  Instruction("typography_15"),
  Instruction("typography_16"),
  InstructionWithContent(`typography_17`, [
    ...LineWithSolidLabel("생각", TypographyLine("typography_17_1")),
    ...LineWithSolidLabel("영향 정도", TypographyLine("typography_17_2")),
    [
      ButtonGroup(
        [
          {
            text: "잘 적혔어요",
          },
          {
            text: "아니요, 잘못 적혔어요",
          },
        ],
        { id: "quiz2" }
      ),
    ],

    GuideTypographyLine("typography_quiz2_incorrect", {
      answerList: [{ id: "quiz2", answerIndex: 1 }],
    }),
  ]),

  Instruction("typography_18"),
  Instruction("typography_19"),
  Instruction("typography_20"),
  Instruction("typography_21"),
  Instruction("typography_22"),

  Header1("typography_23"),
  InstructionWithContent(
    `typography_24`,
    LineWithSolidLabel("감정", TypographyLine("typography_24_1"))
  ),
  Instruction("typography_25"),

  Header1("typography_26"),
  InstructionWithContent(
    `typography_27`,
    LineWithSolidLabel("신체 반응", TypographyLine("typography_27_1"))
  ),
  Instruction("typography_28"),

  Header1("typography_29"),
  InstructionWithContent(
    `typography_30`,
    LineWithSolidLabel("행동", TypographyLine("typography_30_1"))
  ),
  Instruction("typography_31"),
  Instruction("typography_32"),
  Instruction("typography_33"),
  Instruction("typography_34"),

  ...LastCard,
];