import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const COACHING_DOMAIN = "coaching";

export const FAQ_LIST_ENDPOINT = "faq/list";
export const FAQ_ADD_ENDPOINT = "faq/add";
export const FAQ_UPDATE_ENDPOINT = "faq/update";
export const FAQ_DELETE_ENDPOINT = "faq/delete";

export interface CoachingFaq {
  faqId: number;
  question: string;
  answer: string;
}

export type FaqFieldMapType = "question" | "answer";

export type CoachingFaqField = {
  [key in FaqFieldMapType]?: string;
};

export const coachingFaqApi = {
  list: () =>
    axiosInstance.get<CustomResponseFormat<CoachingFaq[]>>(
      `${COACHING_DOMAIN}/${FAQ_LIST_ENDPOINT}`
    ),

  update: (faqId: number, body: CoachingFaqField) =>
    axiosInstance.post<void>(`${COACHING_DOMAIN}/${FAQ_UPDATE_ENDPOINT}`, {
      faqId: faqId,
      ...body,
    }),

  delete: (faqId: number) =>
    axiosInstance.post<void>(`${COACHING_DOMAIN}/${FAQ_DELETE_ENDPOINT}`, {
      faqId: faqId,
    }),

  add: (question: string, answer: string) =>
    axiosInstance.post<void>(`${COACHING_DOMAIN}/${FAQ_ADD_ENDPOINT}`, {
      question: question,
      answer: answer,
    }),
};