import React, { useMemo, useState } from "react";
import { Box, Button, Input, List, Stack, Typography } from "@mui/joy";
import { Collapse } from "@mui/material";
import Fuse from "fuse.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { chatInputAtom } from "recoil/chatInputAtom";
import { theme } from "styles/theme";
import { useQuery } from "@tanstack/react-query";
import {
  COACHING_DOMAIN,
  CoachingFaq,
  coachingFaqApi,
  FAQ_LIST_ENDPOINT,
} from "../../../api/coachingFaqApi";
import EditCoachingFaqButton from "./EditCoachingFaqButton";
import AddCoachingFaqButton from "./AddCoachingFaqButton";
import DeleteCoachingFaqButton from "./DeleteCoachingFaqButton";

const getChosung = (str: string) => {
  const CHO_HANGUL = [
    "ㄱ",
    "ㄲ",
    "ㄴ",
    "ㄷ",
    "ㄸ",
    "ㄹ",
    "ㅁ",
    "ㅂ",
    "ㅃ",
    "ㅅ",
    "ㅆ",
    "ㅇ",
    "ㅈ",
    "ㅉ",
    "ㅊ",
    "ㅋ",
    "ㅌ",
    "ㅍ",
    "ㅎ",
  ];

  let result = "";
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i) - 44032;
    if (code > -1 && code < 11172) {
      result += CHO_HANGUL[Math.floor(code / 588)];
    } else {
      result += str.charAt(i);
    }
  }
  return result;
};

export default function MessageSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [openSet, setOpenSet] = useState<Set<number>>(new Set());

  const navigate = useNavigate();
  const setChatInput = useSetRecoilState(chatInputAtom);

  const {
    data: faqList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [COACHING_DOMAIN, FAQ_LIST_ENDPOINT],
    queryFn: () => coachingFaqApi.list().then((res) => res.result),
  });

  const fuseOptions = {
    includeScore: true,
    threshold: 0.4, // threshold를 높이면 더 많은 결과가 나옴 (0.0 ~ 1.0)
    distance: 100, // 검색어와의 거리 허용 범위
    minMatchCharLength: 1, // 최소 일치 문자 수
    keys: ["question", "answer", "questionChosung", "answerChosung"],
  };

  const fuse = useMemo(() => {
    if (!faqList) return null;

    const faqItemsWithChosung = faqList.map((item) => ({
      ...item,
      questionChosung: getChosung(item.question),
      answerChosung: getChosung(item.answer),
    }));

    return new Fuse(faqItemsWithChosung, fuseOptions);
  }, [faqList]);

  const filteredFAQs: CoachingFaq[] = useMemo(() => {
    if (!faqList) return [];
    if (!searchTerm || !fuse) return faqList;

    const result = fuse.search(searchTerm);
    return result.map((r) => r.item);
  }, [searchTerm, fuse]);

  const toggleOpen = (id: number) => {
    setOpenSet((prev) => {
      const newSet = new Set(prev);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      return newSet;
    });
  };

  const handleSendToChat = (txt: string) => {
    setChatInput(txt); // Recoil 상태에 메시지 저장
    navigate("/chat"); // chat 페이지로 이동
  };

  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <Box
        sx={{
          mb: 2,
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "background.level2",
          pt: 1,
          pb: 1,
        }}
      >
        <Input
          placeholder="검색어 입력"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startDecorator={<SearchIcon />}
          sx={{
            px: "10px",
            py: "12px",
            width: "100%",
            borderWidth: "1px",
            borderColor: "divider",
            backgroundColor: "background.level1",
            boxShadow: "none",
            "--joy-focus-thickness": "0px",
            "--variant-borderWidth": "0px",
          }}
        />
        {/*<Stack*/}
        {/*  direction="row"*/}
        {/*  spacing={1}*/}
        {/*  sx={{ mt: 1, userSelect: "none" }}*/}
        {/*  justifyContent={"flex-end"}*/}
        {/*>*/}
        {/*  <AddCoachingFaqButton />*/}
        {/*</Stack>*/}
      </Box>

      <List sx={{ gap: 1 }}>
        {filteredFAQs.length === 0 && (
          <Typography level="body-sm" sx={{ color: "neutral.500" }}>
            검색 결과가 없습니다.
          </Typography>
        )}
        {filteredFAQs.map((faq) => {
          const isOpen = openSet.has(faq.faqId);
          return (
            <Box key={faq.faqId}>
              <Box
                onClick={() => toggleOpen(faq.faqId)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Typography variant="Caption" sx={{ mr: 1 }}>
                  Q
                </Typography>
                <Typography
                  variant="Caption"
                  sx={{
                    flex: 1,
                    fontWeight: 600,
                    color: theme.vars.palette.Content.Strong,
                  }}
                >
                  {faq.question}
                </Typography>
                <Typography sx={{ width: 20, textAlign: "right" }}>
                  {isOpen ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" />
                  )}
                </Typography>
              </Box>

              <Collapse in={isOpen}>
                <Box
                  sx={{
                    mt: 1,
                    px: 3,
                    borderLeft: "2px solid",
                    borderColor: "divider",
                  }}
                >
                  {/*<Stack*/}
                  {/*  direction="row"*/}
                  {/*  spacing={1}*/}
                  {/*  sx={{ mt: 1, mb: 1, userSelect: "none" }}*/}
                  {/*  justifyContent={"flex-end"}*/}
                  {/*>*/}
                  {/*  <DeleteCoachingFaqButton faq={faq} />*/}
                  {/*  <EditCoachingFaqButton faq={faq} />*/}
                  {/*</Stack>*/}

                  <Typography
                    variant={"Caption"}
                    sx={{
                      lineHeight: 1.5,
                      whiteSpace: "pre-line",
                      fontWeight: 500,
                      color: theme.vars.palette.Content.Medium,
                    }}
                  >
                    {faq.answer}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ mt: 1, mb: 1, userSelect: "none" }}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      size="sm"
                      variant="solid"
                      color="primary"
                      onClick={() => handleSendToChat(faq.answer)}
                    >
                      채팅창에 넣기
                    </Button>
                  </Stack>
                </Box>
              </Collapse>
            </Box>
          );
        })}
      </List>
    </Box>
  );
}