import React, { useCallback, useEffect, useState } from "react";
import { Box, Checkbox, Chip, Stack, Textarea, Typography } from "@mui/joy";
import ButtonWithModal from "../../common/ButtonWithModal";
import {
  PATIENT_DOMAIN,
  PATIENT_LIST_ENDPOINT,
  PatientType,
} from "api/patientApi";
import { chatApi, RemindHistoryType } from "api/chatApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getTimeDifference } from '../../../logic/logics_common'
import { theme } from '../../../styles/theme'

interface RemindGroup {
  key: string;
  label: string;
  filterFn: (patient: PatientType) => boolean;
}

interface RemindMessageSenderProps {
  patientList: PatientType[];
}

const remindGroups: RemindGroup[] = [
  {
    key: "less_than_1_week",
    label: "활동 마친지 1주 미만",
    filterFn: (patient: PatientType) => (patient.dayNumber || 0) < 7,
  },
  {
    key: "between_1_and_3_weeks",
    label: "활동 마친지 1주 이상 3주 미만",
    filterFn: (patient: PatientType) =>
      patient.dayNumber !== undefined &&
      patient.dayNumber >= 7 &&
      patient.dayNumber < 21,
  },
  {
    key: "more_than_3_weeks",
    label: "활동 마친지 3주 이상",
    filterFn: (patient: PatientType) =>
      patient.dayNumber !== undefined && patient.dayNumber >= 21,
  },
];

export default function RemindMessageSender({
  patientList,
}: RemindMessageSenderProps) {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [messages, setMessages] = useState<Record<string, string>>({});
  const [groupCounts, setGroupCounts] = useState<Record<string, number>>({});
  const [history, setHistory] = useState<RemindHistoryType | undefined>();

  const queryClient = useQueryClient();

  useEffect(() => {
    const counts: Record<string, number> = {};
    remindGroups.forEach((group) => {
      counts[group.key] = patientList.filter(group.filterFn).length;
    });
    setGroupCounts(counts);
  }, [patientList]);

  const handleMessageChange = (key: string, value: string) => {
    setMessages((prev) => ({ ...prev, [key]: value }));
  };

  const insertNickname = (key: string) => {
    setMessages((prev) => ({
      ...prev,
      [key]: (prev[key] || "") + "{{nickname}}",
    }));
  };

  useEffect(() => {
    chatApi.getRemindHistoryDetail().then((res) => {
      setHistory(res.result);
    });
  }, []);

  const sendBulkMessageQueryFn = useCallback(() => {
    const shortTermTargetPatientIdList = selectedGroups.includes(
      "less_than_1_week"
    )
      ? patientList
          .filter(remindGroups[0].filterFn)
          .map((patient) => Number(patient.patientId))
      : [];

    const midTermTargetPatientIdList = selectedGroups.includes(
      "between_1_and_3_weeks"
    )
      ? patientList
          .filter(remindGroups[1].filterFn)
          .map((patient) => Number(patient.patientId))
      : [];

    const longTermTargetPatientIdList = selectedGroups.includes(
      "more_than_3_weeks"
    )
      ? patientList
          .filter(remindGroups[2].filterFn)
          .map((patient) => Number(patient.patientId))
      : [];

    return chatApi.sendBulkRemind(
      shortTermTargetPatientIdList.length > 0 ? shortTermTargetPatientIdList : undefined,
      midTermTargetPatientIdList.length > 0 ? midTermTargetPatientIdList : undefined,
      longTermTargetPatientIdList.length > 0 ? longTermTargetPatientIdList : undefined,
      selectedGroups.includes("less_than_1_week") ? messages["less_than_1_week"] : undefined,
      selectedGroups.includes("between_1_and_3_weeks") ? messages["between_1_and_3_weeks"] : undefined,
      selectedGroups.includes("more_than_3_weeks") ? messages["more_than_3_weeks"] : undefined
    );
  }, [selectedGroups, messages, patientList]);

  const resetStates = () => {
    setSelectedGroups([]);
    setMessages({});
  };

  const { mutate: sendBulkMessage, isLoading } = useMutation(
    sendBulkMessageQueryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT]);
      },
      onError: () => {},
    }
  );

  return (
    <ButtonWithModal
      size={"md"}
      color={"secondary"}
      variant={"soft"}
      buttonTextSize={"sm"}
      buttonText="리마인드 한번에 하기"
      title="리마인드 메시지 보내기"
      subtitle="대상 그룹을 선택하고 메시지를 입력하세요."
      actionText="메시지 보내기"
      cancelText="취소"
      action={() => new Promise<void>((resolve, reject) => {
        sendBulkMessage(undefined, {
          onSuccess: () => {
            resolve(); // 성공 시 resolve 호출
          },
          onError: (error) => {
            reject(error); // 실패 시 reject 호출
          },
        });
      })}
      loadingActionButton={isLoading}
      actionButtonDisabled={
        selectedGroups.length === 0 ||
        selectedGroups.some((key) => !messages[key]?.trim())
      }
      onClose={() => resetStates()}
      content={
        <Box sx={{ maxHeight: "400px", overflowY: "auto", mt: 2 }}>
          <Stack spacing={2}>
            {remindGroups.map((group) => (
              <Box key={group.key}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedGroups((prev) =>
                      prev.includes(group.key)
                        ? prev.filter((k) => k !== group.key)
                        : [...prev, group.key]
                    );
                  }}
                >
                  <Checkbox
                    checked={selectedGroups.includes(group.key)}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSelectedGroups((prev) =>
                        e.target.checked
                          ? [...prev, group.key]
                          : prev.filter((k) => k !== group.key)
                      );
                    }}
                  />
                  <Box>
                    <Typography variant="Caption">
                      {group.label} ({groupCounts[group.key] || 0}명)
                    </Typography>
                    <Typography variant="Footnote" sx={{color: theme.vars.palette.Content.Weak}}>
                      {group.key === "less_than_1_week" &&
                        (history?.shortTermSentAt
                          ? `${getTimeDifference(history.shortTermSentAt)} 리마인드함`
                          : "리마인드 기록 없음")}
                      {group.key === "between_1_and_3_weeks" &&
                        (history?.midTermSentAt
                          ? `${getTimeDifference(history.midTermSentAt)}`
                          : "리마인드 기록 없음")}
                      {group.key === "more_than_3_weeks" &&
                        (history?.longTermSentAt
                          ? `${getTimeDifference(history.longTermSentAt)}`
                          : "리마인드 기록 없음")}
                    </Typography>
                  </Box>
                </Stack>
                {selectedGroups.includes(group.key) && (
                  <Box sx={{ mt: 1 }}>
                    <Textarea
                      placeholder="메시지를 입력하세요."
                      value={messages[group.key] || ""}
                      onChange={(e) =>
                        handleMessageChange(group.key, e.target.value)
                      }
                      minRows={3}
                      sx={{
                        borderColor: "background.level3",
                        borderWidth: "1px",
                        "--joy-focus-thickness": "0px",
                        boxShadow: "0px 0px 0px transparent",
                      }}
                    />
                    <Chip
                      variant="outlined"
                      size="sm"
                      sx={{ mt: 1, cursor: "pointer" }}
                      onClick={() => insertNickname(group.key)}
                    >
                      nickname
                    </Chip>
                  </Box>
                )}
              </Box>
            ))}
          </Stack>
        </Box>
      }
      isWide={true}
      bottomIfMobile
    />
  );
}
