import { Box, Stack } from "@mui/joy";
import { forwardRef } from "react";
import { useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

interface FloatingAreaProps {
  fullWidth: boolean;
  children: React.ReactNode;
}

const FloatingArea = forwardRef<HTMLDivElement, FloatingAreaProps>(
  ({ children, fullWidth }, ref) => {
    const { isLeftSideBarOpen, taskWidth } = useRecoilValue(layoutAtom);
    const backgroundColor = extractHexColor(
      theme.vars.palette.Background.Level1
    );

    return (
      <Box
        ref={ref}
        sx={{
          position: "fixed",
          zIndex: 10,
          bottom: 0,
          left: fullWidth ? 0 : "var(--Sidebar-width)",
          width: fullWidth ? "100%" : `calc(100% - var(--Sidebar-width, 0))`,
          pointerEvents: "none",
        }}
      >
        <Box
          sx={{
            backgroundColor: backgroundColor,
            boxShadow: "0px 1px 0px 0px #00000014 inset;",
            px: "20px",
            pt: "20px",
            pb: `${20}px`,
            pointerEvents: "auto",
          }}
        >
          <Stack
            className={` w-full ${
              taskWidth === "normal" && "max-w-[720px] mx-auto"
            }`}
            alignItems="center"
          >
            {children}
          </Stack>
        </Box>
      </Box>
    );
  }
);

export default FloatingArea;