import React from "react";
import { Box, Stack } from "@mui/joy";
import { useParams, useSearchParams } from "react-router-dom";
import distancingLogo from "static/image/common/distancing_logo.png";
import InstallDeepLinkButton from "components/task/payment/elements/InstallDeepLinkButton";
import { theme } from "styles/theme";
import CustomTypography from "../../components/common/CustomTypography";

export default function PromotionBook() {
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.vars.palette.Background.Level3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "auto",
        px: 2,
        pt: 3,
        pb: 6,
      }}
    >
      {/* 상단 로고 / 문구 영역 */}
      <Stack
        spacing={4}
        sx={{
          width: "100%",
          maxWidth: "345px",
          textAlign: "center",
        }}
        alignItems="center"
      >
        <img
          src={distancingLogo}
          alt="distancing logo"
          style={{ width: "124px", height: "auto" }}
        />
        <CustomTypography variant={"Subtitle"} sx={{ fontWeight: 500, mt: 4 }}>
          「나는 내 생각을 다 믿지 않기로 했다」
          <br />
          독자분들께 디스턴싱을 할인해드립니다.
        </CustomTypography>
      </Stack>

      {/* 구분선 */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "350px",
          height: "1px",
          minHeight: "1px",
          backgroundColor: theme.vars.palette.Border.Weak,
          my: 4,
        }}
      />

      {/* 1) 디스턴싱을 이용 중이신 경우 */}
      <Stack spacing={2.5} width={"100%"}>
        <CustomTypography
          variant={"Body"}
          sx={{
            color: theme.vars.palette.Content.Strong,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          디스턴싱을{" "}
          <strong
            style={{
              color: theme.vars.palette.Content.Highlight,
              fontWeight: 700,
            }}
          >
            이용 중이시라면
          </strong>
          ,
          <br />
          아래 버튼을 눌러{" "}
          <strong
            style={{
              color: theme.vars.palette.Content.Highlight,
              fontWeight: 700,
            }}
          >
            3만원 페이백
          </strong>
          을 신청하세요.
        </CustomTypography>
        <Stack spacing={1.5} alignItems={"center"}>
          <InstallDeepLinkButton
            variant={"White"}
            buttonText={"페이백 신청 링크 받기"}
            deeplink={`https://distancing.onelink.me/DeUR/book?deep_link_value=promotion_book&deep_link_sub1=${code}`}
            sx={{
              backgroundColor: theme.vars.palette.Surface.Forward,
              color: theme.vars.palette.Content.Strong,
              width: "100%",
              maxWidth: "345px",
            }}
          />

          <CustomTypography
            variant={"Caption"}
            sx={{
              textAlign: "center",
              color: theme.vars.palette.Content.Weak,
              fontWeight: 500,
            }}
          >
            앱의 코칭 탭으로 신청 링크가 발송됩니다.
          </CustomTypography>
        </Stack>
      </Stack>

      {/* 구분선 */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "350px",
          height: "1px",
          minHeight: "1px",
          backgroundColor: theme.vars.palette.Border.Weak,
          my: 4,
        }}
      />

      {/* 2) 디스턴싱을 이용 중이지 않은 경우 */}
      <Stack spacing={2.5} width={"100%"}>
        <CustomTypography
          variant={"Body"}
          sx={{
            color: theme.vars.palette.Content.Strong,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          디스턴싱을{" "}
          <strong
            style={{
              color: theme.vars.palette.Content.Highlight,
              fontWeight: 700,
            }}
          >
            이용 중이지 않으시다면
          </strong>
          ,
          <br />
          아래 버튼을 눌러{" "}
          <strong
            style={{
              color: theme.vars.palette.Content.Highlight,
              fontWeight: 700,
            }}
          >
            3만원 할인
          </strong>
          을 적용하세요.
        </CustomTypography>
        <Stack spacing={1.5} alignItems={"center"}>
          <InstallDeepLinkButton
            variant={"Action"}
            buttonText={"할인 쿠폰 적용하기"}
            deeplink={`https://distancing.onelink.me/DeUR/book?deep_link_value=promotion_book&deep_link_sub1=${code}`}
            sx={{
              width: "100%",
              maxWidth: "345px",
              height: "54px",
            }}
          />
          <CustomTypography
            variant={"Caption"}
            sx={{
              textAlign: "center",
              color: theme.vars.palette.Content.Weak,
              fontWeight: 500,
            }}
          >
            반드시 위의 버튼으로 앱을 실행하셔야 쿠폰이 적용됩니다.
          </CustomTypography>
        </Stack>
      </Stack>
    </Box>
  );
}