import Box from "@mui/joy/Box";
import List from "@mui/joy/List";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, ListDivider, Stack } from "@mui/joy";
import { useRecoilValue } from "recoil";
import {
  AutomaticThought,
  Beliefs,
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
  CONCEPT_NOTE_BELIEFS_DETAIL_ENDPOINT,
  CONCEPT_NOTE_CONCERN_AND_GOAL_DETAIL_ENDPOINT,
  conceptApi,
  ConcernAndGoal,
} from "api/conceptApi";
import { useQueries } from "@tanstack/react-query";
import Memo from "components/coachRightSidebar/conceptNote/Memo";
import { addAlpha, extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";
import AutomaticThoughtListItem from "./automaticThought/AutomaticThoughtListItem";
import ConcernAndGoalItem from "./concernAndGoal/ConcernAndGoalItem";
import CoreBeliefsItem from "./beliefs/CoreBeliefsItem";
import IntermediateBeliefsItem from "./beliefs/IntermediateBeliefsItem";
import CoreExperienceItem from "./beliefs/CoreExperienceItem";
import { selectedThoughtRecordKeyAtom } from "../../../recoil/selectedThoughtRecordAtom";
import AutomaticThoughtForValueListItem from "./automaticThought/AutomaticThoughtForValueListItem";

export default function ConceptNoteV2({
  patientId,
  isActive,
}: {
  patientId: string;
  isActive: boolean;
}) {
  const selectedThoughtRecordKey = useRecoilValue(selectedThoughtRecordKeyAtom);

  const [automaticThoughtList, setAutomaticThoughtList] = useState<
    AutomaticThought[]
  >([]);

  const [concernAndGoal, setConcernAndGoal] = useState<
    ConcernAndGoal | undefined
  >(undefined);

  type automaticThoughtType = "함정 찾기" | "가치 찾기";
  const [currentAutomaticThoughtType, setCurrentAutomaticThoughtType] =
    useState<automaticThoughtType>("함정 찾기");

  const containerRef = useRef<HTMLDivElement>(null);

  const [beliefs, setBeliefs] = useState<Beliefs | undefined>(undefined);

  const itemRefs = useRef<Record<string, HTMLDivElement | null>>({});

  // (1) selectedThoughtRecordKey가 바뀌면, 해당 항목이 함정인지 가치인지 판별해 탭 전환
  useEffect(() => {
    if (!selectedThoughtRecordKey) return;

    const found = automaticThoughtList.find(
      (item) => item.thoughtRecordKey === selectedThoughtRecordKey
    );
    if (found) {
      setCurrentAutomaticThoughtType(
        found.isForValue ? "가치 찾기" : "함정 찾기"
      );
    }
  }, [selectedThoughtRecordKey, automaticThoughtList]);

  useEffect(() => {
    if (!isActive || !selectedThoughtRecordKey) return;

    const timeoutId = setTimeout(() => {
      const targetEl = itemRefs.current[selectedThoughtRecordKey];
      if (targetEl && containerRef.current) {
        const headerHeight = 80; // 예: 스티키 헤더 높이
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const elementTop = targetEl.getBoundingClientRect().top;
        const offset =
          elementTop -
          containerTop +
          containerRef.current.scrollTop -
          headerHeight;

        containerRef.current.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }, 100); // 100ms 지연을 주어 DOM 렌더링을 기다림

    return () => clearTimeout(timeoutId);
  }, [
    isActive,
    selectedThoughtRecordKey,
    currentAutomaticThoughtType,
    automaticThoughtList,
  ]);

  // 초기화
  useEffect(() => {
    setCurrentAutomaticThoughtType("함정 찾기");
    setAutomaticThoughtList([]);
  }, [patientId]);

  const concernAndGoalQueryFn = useCallback(
    () => conceptApi.noteConcernAndGoalDetail(patientId),
    [patientId]
  );

  const beliefsQueryFn = useCallback(
    () => conceptApi.noteBeliefsDetail(patientId),
    [patientId]
  );

  const automaticThoughtQueryFn = useCallback(
    () => conceptApi.noteAutomaticThoughtList(patientId),
    [patientId]
  );

  // 여러 쿼리를 parallel 하게
  const results = useQueries({
    queries: [
      {
        queryKey: [
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_CONCERN_AND_GOAL_DETAIL_ENDPOINT,
          patientId,
        ],
        queryFn: concernAndGoalQueryFn,
      },
      {
        queryKey: [
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_BELIEFS_DETAIL_ENDPOINT,
          patientId,
        ],
        queryFn: beliefsQueryFn,
      },
      {
        queryKey: [
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
          patientId,
        ],
        queryFn: automaticThoughtQueryFn,
      },
    ],
  });

  const concernAndGoalResult = results[0].data?.result;
  const beliefsResult = results[1].data?.result;
  const automaticThoughtResult = results[2].data?.result;

  // 자동적 사고 목록 불러오기
  useEffect(() => {
    if (automaticThoughtResult) {
      setAutomaticThoughtList(automaticThoughtResult);
    }
  }, [patientId, automaticThoughtResult]);

  useEffect(() => {
    if (concernAndGoalResult) {
      setConcernAndGoal(concernAndGoalResult);
    }
  }, [patientId, concernAndGoalResult]);

  useEffect(() => {
    if (beliefsResult) {
      setBeliefs(beliefsResult);
    }
  }, [patientId, beliefsResult]);

  // 로딩 중이면 스피너
  const isLoading = results.some((r) => r.isLoading);

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", lg: "sticky" },
        transform: "translateX(0)",
        transition: "transform 0.4s, width 0.4s",
        width: "var(--InsideC-width)",
        height: {
          xs: "calc(100% - var(--Header-height, 0))",
          lg: "100%",
        },
        zIndex: { xs: 1000, lg: 0 },
        top: {
          xs: "var(--Header-height, 0)",
          lg: 0,
        },
        right: 0,
        pb: 0,
        flexShrink: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "background.level2",
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          width: "100%",
          minHeight: 0,
          overflow: "auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          px: "20px",
          pb: 5,
        }}
      >
        <List sx={{ pt: "20px" }}>
          {/* 메모 컴포넌트 */}
          <Memo />
          <ListDivider sx={{ my: "20px", backgroundColor: "divider" }} />

          {/* 고민 */}
          <Stack
            key={"concernAndGoal"}
            sx={{
              pb: "20px",
              borderBottomWidth: "1px",
              borderColor: "divider",
              mb: "20px",
            }}
          >
            {concernAndGoal !== undefined ? (
              <ConcernAndGoalItem
                concernAndGoal={concernAndGoal}
                setConcernAndGoal={setConcernAndGoal}
              />
            ) : (
              <CircularProgress color="neutral" />
            )}
          </Stack>

          {/*믿음*/}
          {beliefs !== undefined ? (
            <>
              <Stack
                key={"coreExperience"}
                sx={{
                  pb: "20px",
                  borderBottomWidth: "1px",
                  borderColor: "divider",
                  mb: "20px",
                }}
              >
                <CoreExperienceItem beliefs={beliefs} setBeliefs={setBeliefs} />
              </Stack>

              <Stack
                key={"coreBeliefs"}
                sx={{
                  pb: "20px",
                  borderBottomWidth: "1px",
                  borderColor: "divider",
                  mb: "20px",
                }}
              >
                <CoreBeliefsItem beliefs={beliefs} setBeliefs={setBeliefs} />
              </Stack>

              <Stack
                key={"intermediateBeliefs"}
                sx={{
                  pb: "20px",
                  borderBottomWidth: "1px",
                  borderColor: "divider",
                  mb: "20px",
                }}
              >
                <IntermediateBeliefsItem
                  beliefs={beliefs}
                  setBeliefs={setBeliefs}
                />
              </Stack>
            </>
          ) : (
            <CircularProgress color="neutral" />
          )}

          {/* 자동적 사고 탭 */}
          <Stack direction="row" sx={{ width: "100%", mb: "20px" }}>
            {["함정 찾기", "가치 찾기"].map((tabLabel) => (
              <Box
                key={tabLabel}
                sx={{
                  pb: "8px",
                  flex: 1,
                  cursor: "pointer",
                  borderBottomWidth: "2px",
                  borderBottomColor:
                    currentAutomaticThoughtType === tabLabel
                      ? "primary.solid"
                      : addAlpha(
                          extractHexColor(theme.vars.palette.primary.soft),
                          0.1
                        ),
                }}
                onClick={() =>
                  setCurrentAutomaticThoughtType(
                    tabLabel as automaticThoughtType
                  )
                }
              >
                <Typography level="body-xs" sx={{ textAlign: "center" }}>
                  {tabLabel}
                </Typography>
              </Box>
            ))}
          </Stack>

          {currentAutomaticThoughtType === "함정 찾기" && (
            <>
              {automaticThoughtList.filter((e) => !e.isForValue).length > 0 ? (
                automaticThoughtList
                  .filter((e) => !e.isForValue)
                  .map((thought, index) => (
                    <div
                      key={thought.automaticThoughtId}
                      ref={(el) => {
                        itemRefs.current[thought.thoughtRecordKey] = el;
                      }}
                    >
                      <AutomaticThoughtListItem
                        automaticThought={thought}
                        automaticThoughtList={automaticThoughtList}
                        onDelete={(id) =>
                          setAutomaticThoughtList((prev) =>
                            prev.filter((at) => at.automaticThoughtId !== id)
                          )
                        }
                        highlight={
                          thought.thoughtRecordKey === selectedThoughtRecordKey
                        }
                      />
                      {index <
                        automaticThoughtList.filter((e) => !e.isForValue)
                          .length -
                          1 && (
                        <ListDivider
                          sx={{ my: "20px", backgroundColor: "divider" }}
                        />
                      )}
                    </div>
                  ))
              ) : (
                <Typography variant={"Footnote"} sx={{ textAlign: "center" }}>
                  부정 기록에서 추가하세요
                </Typography>
              )}
            </>
          )}

          {currentAutomaticThoughtType === "가치 찾기" && (
            <>
              {automaticThoughtList.filter((e) => e.isForValue).length > 0 ? (
                automaticThoughtList
                  .filter((e) => e.isForValue)
                  .map((thought, index) => (
                    <div
                      key={thought.automaticThoughtId}
                      ref={(el) => {
                        itemRefs.current[thought.thoughtRecordKey] = el;
                      }}
                    >
                      <AutomaticThoughtForValueListItem
                        automaticThought={thought}
                        onDelete={(id) =>
                          setAutomaticThoughtList((prev) =>
                            prev.filter((at) => at.automaticThoughtId !== id)
                          )
                        }
                        highlight={
                          thought.thoughtRecordKey === selectedThoughtRecordKey
                        }
                      />
                      {index <
                        automaticThoughtList.filter((e) => e.isForValue)
                          .length -
                          1 && (
                        <ListDivider
                          sx={{ my: "20px", backgroundColor: "divider" }}
                        />
                      )}
                    </div>
                  ))
              ) : (
                <Typography variant={"Footnote"} sx={{ textAlign: "center" }}>
                  긍정 기록에서 추가하세요
                </Typography>
              )}
            </>
          )}

          <ListDivider sx={{ my: "20px", backgroundColor: "divider" }} />
        </List>
      </Box>
    </Sheet>
  );
}
