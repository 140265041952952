import React, { useState } from "react";
import { Box, Button, Input, Stack } from "@mui/joy";
import { useSearchParams } from "react-router-dom";
import distancingLogo from "static/image/common/distancing_logo.png";
import { theme } from "styles/theme";
import CustomTypography from "components/common/CustomTypography";
import { useMutation } from "@tanstack/react-query";
import { promotionApi } from "api/promotionApi";
import ButtonWithModal from '../../components/common/ButtonWithModal'

export default function PromotionBookPhoneNumberSubmit() {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState({ type: "", text: "" });

  const uid = searchParams.get("uid");

  const [phoneNumber, setPhoneNumber] = useState("");

  const mutation = useMutation({
    mutationFn: (phoneNumber: string) =>
      promotionApi.submitPhoneNumber(uid!!, "book", phoneNumber),
    onSuccess: (data) => {
      setMessage({ type: "success", text: "성공적으로 신청되었습니다." });
      setPhoneNumber("");
    },
    onError: (error) => {
      setMessage({
        type: "error",
        text: "신청 중 오류가 발생했습니다. 다시 시도해주세요.",
      });
    },
  });

  const formatPhoneNumber = (value: string) => {
    const numbers = value.replace(/\D/g, "").slice(0, 11);
    if (numbers.length < 4) return numbers;
    if (numbers.length < 8) return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7)}`;
  };

  const handleChange = (value: string) => {
    setPhoneNumber(formatPhoneNumber(value));
  };

  const isValidPhoneNumber = (number: string) => {
    const regex = /^\d{3}-\d{4}-\d{4}$/;
    return regex.test(number);
  };

  const handleSubmit = () => {
    if (isValidPhoneNumber(phoneNumber)) {
      mutation.mutate(phoneNumber);
    }
  };

  if (!uid) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: theme.vars.palette.Background.Level3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2,
        }}
      >
        <CustomTypography
          variant="Body"
          sx={{ fontWeight: 500, textAlign: "center" }}
        >
          잘못된 페이지입니다.
          <br />
          다시 시도해주세요.
        </CustomTypography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: theme.vars.palette.Background.Level3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
        pb: 6
      }}
    >
      <Stack
        spacing={4}
        sx={{ width: "100%", maxWidth: "345px", textAlign: "center" }}
      >
        <Stack spacing={3}>
          <img
            src={distancingLogo}
            alt="distancing logo"
            style={{ width: "124px", height: "auto", margin: "0 auto" }}
          />

          <CustomTypography variant="Subtitle" sx={{ fontWeight: 500 }}>
            네이버페이 포인트 3만원을 받으실
            <br />
            휴대전화 번호를 입력해주세요.
          </CustomTypography>
        </Stack>

        <Input
          placeholder="000-0000-0000"
          value={phoneNumber}
          inputMode="numeric"
          onChange={(e) => handleChange(e.target.value)}
          slotProps={{
            input: {
              style: { textAlign: "center" },
            },
          }}
          sx={{
            borderColor: "background.level3",
            borderWidth: "1px",
            "--joy-focus-thickness": "0px",
            boxShadow: "0px 0px 0px transparent",
            width: "100%",
            height: "54px",
            borderRadius: "12px",
            backgroundColor: theme.vars.palette.Surface.Forward,
            fontSize: "16px",
          }}
        />

        <Stack spacing={1.5}>
          <ButtonWithModal
            buttonText={"페이백 신청하기"}
            disabled={!isValidPhoneNumber(phoneNumber)}
            action={handleSubmit}
            actionText={"신청하기"}
            title={"전화번호가 정확한지 다시 한번 확인해 주세요"}
            subtitle={"해당 전화번호로 네이버페이 포인트 3만원을 발송해 드려요."}
            content={`${phoneNumber}`}
            cancelText={"취소"}
            loading={mutation.isLoading}
            loadingActionButton={mutation.isLoading}
            sx={{
              maxWidth: "345px",
              height: "54px",
              fontWeight: 700,
              borderRadius: "12px",
              fontSize: "16px",
              lineHeight: "132%",
              width: "100%",
            }}
          />

          <CustomTypography
            variant="Caption"
            sx={{ color: theme.vars.palette.Content.Weak, fontWeight: 500 }}
          >
            포인트는 문자 메시지로 발송됩니다.
            <br />
            최대 5 영업일이 소요될 수 있습니다.
          </CustomTypography>

          {message.text && message.type === "error" && (
            <CustomTypography
              variant="Caption"
              sx={{
                color: "danger.solidBg",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {message.text}
            </CustomTypography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}