import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import { CircularProgress, Stack } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import CoachMenu from "./CoachMenu";
import Overview from "./Overview";
import LearningActivityList from "./LearningActivityList";
import ThoughtRecordList from "./ThoughtRecordList";
import MeditationList from "./MeditationList";
import {
  HOME_DOMAIN,
  HOME_MEDITATION_LIST_ENDPOINT,
  HOME_TASK_LIST_ENDPOINT,
  HOME_THOUGHTRECORD_LIST_ENDPOINT,
  homeApi,
} from "api/homeApi";
import React, { useCallback, useEffect, useState } from "react";
import { useQueries } from "@tanstack/react-query";
import useIsInappWebview from "hooks/useIsInappWebview";
import { axiosInstance } from "api";

export default function HomeTaskListV2() {
  const patientState = useRecoilValue(patientAtom);
  const userState = useRecoilValue(userAtom);
  const isInappWebview = useIsInappWebview();

  const isCoach = userState?.accessToken && userState?.role !== "patient";
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const thoughtRecordListQueryFn = useCallback(
    () => homeApi.getThoughtRecordList(`${patientState?.patientId}`),
    [patientState]
  );

  const meditationListQueryFn = useCallback(
    () => homeApi.getMeditationList(`${patientState?.patientId}`),
    [patientState]
  );

  const taskListQueryFn = useCallback(
    () => homeApi.getList(`${patientState?.patientId}`),
    [patientState]
  );

  const results = useQueries({
    queries: [
      {
        enabled:
          !!axiosInstance.defaults.headers["X-Auth-Token"] &&
          !!patientState?.patientId,
        refetchInterval: 10000,
        queryKey: [
          HOME_DOMAIN,
          HOME_THOUGHTRECORD_LIST_ENDPOINT,
          patientState?.patientId,
        ],
        queryFn: thoughtRecordListQueryFn,
      },
      {
        enabled:
          !!axiosInstance.defaults.headers["X-Auth-Token"] &&
          !!patientState?.patientId,
        refetchInterval: 10000,
        queryKey: [
          HOME_DOMAIN,
          HOME_MEDITATION_LIST_ENDPOINT,
          patientState?.patientId,
        ],
        queryFn: meditationListQueryFn,
      },
      {
        enabled:
          (!!axiosInstance.defaults.headers["X-Auth-Token"] &&
            !!patientState?.patientId) ||
          (!isCoach && !isInappWebview),
        refetchInterval: 10000,
        queryKey: [
          HOME_DOMAIN,
          HOME_TASK_LIST_ENDPOINT,
          patientState?.patientId,
        ],
        queryFn: taskListQueryFn,
      },
    ],
  });
  useEffect(() => {
    if (results) {
      if (
        !patientState?.patientId
          ? !results[2].isLoading
          : results.every((element) => !element.isLoading)
      ) {
        setIsInitialLoading(false);
      } else {
        setIsInitialLoading(true);
      }
    }
  }, [results]);

  return (
    <Sheet
      sx={{
        backgroundColor: "background.level2",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        ...(isInappWebview && { px: "20px" }),
      }}
    >
      {isCoach && patientState && (
        <Box sx={{ flexShrink: 0, px: !isInappWebview ? "20px" : 0 }}>
          <CoachMenu />
        </Box>
      )}

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          minHeight: 0,
          "&::-webkit-scrollbar": {
            width: "6px",
            opacity: 0,
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: "3px",
          },
          "&:hover::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.3)",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgba(0,0,0,0.4)",
          },
        }}
      >
        <Overview />

        {isInitialLoading ? (
          <Stack
            justifyContent={"center"}
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <CircularProgress color="neutral" />
          </Stack>
        ) : (
          <>
            {results[0].data?.result && (
              <ThoughtRecordList data={results[0].data?.result} />
            )}
            {results[1].data?.result && (
              <MeditationList data={results[1].data?.result} />
            )}
            {results[2].data?.result && (
              <LearningActivityList
                data={results[2].data?.result}
                dataUpdatedAt={results[2].dataUpdatedAt}
              />
            )}
          </>
        )}
      </Box>
    </Sheet>
  );
}