import { useEffect, useState } from "react";
import TaskHeader from "components/task/header/TaskHeader";
import useGetTaskData from "hooks/useGetTaskData";
import TaskContent from "components/task/content/TaskContent";
import { Box, Stack } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import useInterval from "hooks/useInterval";
import { useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import { ProgramType } from "data/programData";
import { userAtom } from "recoil/userAtom";
import useSetConceptData from "hooks/useSetConceptData";
import useGetCertainEventFromApp from "hooks/useGetCertainEventFromApp";

export default function Activity({
  taskKey,
  noHeader,
}: {
  taskKey: string;
  noHeader?: boolean;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach =
    user?.accessToken && user?.role !== "patient" && !taskKey.includes("Tool");

  const taskListState = useRecoilValue(taskListAtom);
  const currentTaskState = taskListState.find(
    (element) => element.taskKey === taskKey
  );

  const [data, setData] = useState<ProgramType | undefined>();

  const [contentData, setContentData] = useState<
    ProgramContentType[] | undefined
  >();
  const [prevContentData, setPrevContentData] = useState<
    ProgramContentType[] | undefined
  >();

  const { data: fetchedData, refetch } = useGetTaskData({
    taskKey: `${taskKey}`,
  });

  // 개념화노트, 생각지도 데이터 세팅
  const { isConceptNoteFetching, isConceptFetching } = useSetConceptData({
    taskKey: taskKey,
  });

  useInterval(
    () => {
      setPrevContentData(contentData);
      //변화 없으면 다시 가져와
      if (
        contentData &&
        prevContentData &&
        _.isEqual(contentData, prevContentData)
      ) {
        refetch();
      }
    },
    10000,
    []
  );

  const refetchActivityEvent = useGetCertainEventFromApp({
    eventName: "refetch_activity",
  });

  useEffect(() => {
    if (refetchActivityEvent) {
      refetch();
    }
  }, [refetchActivityEvent]);

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
    }
  }, [fetchedData]);

  useEffect(() => {
    setContentData(data?.content);
  }, [data]);

  const [moveToIndex, setMoveToIndex] = useState<number | undefined>(undefined);

  return (
    <>
      <Box
        sx={{
          flex: "none",
          zIndex: 100,
          // px: { xs: "16px", sm: "24px" },
        }}
      >
        {!noHeader && (
          <TaskHeader
            taskKey={taskKey}
            contentData={contentData}
            setContentData={setContentData}
            setData={setData}
            setMoveToIndex={setMoveToIndex}
            isDone={currentTaskState?.isDone}
            isInitialized={!data}
          />
        )}
      </Box>

      <Stack sx={{ flex: 1, overflow: "hidden" }}>
        <TaskContent
          taskKey={taskKey}
          data={contentData}
          setData={setContentData}
          moveToIndex={moveToIndex}
          setMoveToIndex={setMoveToIndex}
          isDone={currentTaskState?.isDone}
          translationVersion={data?.translationVersion}
        />
      </Stack>
    </>
  );
}