import Typography from "@mui/joy/Typography";
import { Stack } from "@mui/joy";
import { Beliefs, conceptApi, ConceptFieldKey } from "api/conceptApi";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import ConceptNoteInput from "../ConceptNoteInput";
import ConceptNoteConfirmButton from "../ConceptNoteConfirmButton";

export default function CoreExperienceItem({
  beliefs,
  setBeliefs,
}: {
  beliefs: Beliefs;
  setBeliefs: React.Dispatch<React.SetStateAction<Beliefs | undefined>>;
}) {
  const patientState = useRecoilValue(patientAtom);
  const [canBeConfirmed, setCanBeConfirmed] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setCanBeConfirmed((beliefs.coreExperience?.trim() ?? "").length > 0);
  }, [beliefs]);

  const { mutate: updateNote } = useMutation<void, unknown, ConceptFieldKey>(
    async (payload) => {
      await conceptApi.noteUpdate(`${patientState?.patientId}`, payload);
    },
    {
      onSuccess: (_data, variables) => {},
      onError: (error) => {
        console.error("Note update failed:", error);
      },
    }
  );

  const handleSave = (newValue: string) => {
    setBeliefs((prev) => ({
      ...prev,
      coreExperience: newValue,
    }));
    updateNote({
      coreExperience: newValue,
    });
  };

  return (
    <Stack spacing={1}>
      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction={"row"} spacing={1}>
          <Typography level="body-xs">{"핵심 경험"}</Typography>

          {(beliefs.coreExperienceStatus === "note_confirmed" ||
            beliefs.coreExperienceStatus === "confirmed") && (
            <Stack
              justifyContent="center"
              sx={{ color: "primary.solid", width: "16px", height: "16px" }}
            >
              <span
                style={{ fontSize: "16px" }}
                className="material-symbols-rounded"
              >
                check
              </span>
            </Stack>
          )}
        </Stack>

        {/* 우측 액션(삭제, 확정) */}
        <Stack direction="row" spacing={1}>
          {beliefs.coreExperienceStatus === "preparing" && (
            <ConceptNoteConfirmButton
              conceptNoteKey={"coreExperience"}
              label="핵심 경험"
              disabled={!canBeConfirmed}
            />
          )}
        </Stack>
      </Stack>

      <ConceptNoteInput
        referenceByConceptFieldKey={"coreExperience"}
        initialValue={beliefs.coreExperience}
        onSave={handleSave}
      />
    </Stack>
  );
}