import { automaticThoughtIdType } from "api/conceptApi";
import {
  DimmedLabelLine,
  Percentage,
  SolidLabelLine,
  TextareaLine,
  TypographyFromDataLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Content,
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithPreset,
  InstructionWithTextarea,
} from "../../BlockComponent";
import { Content_4_5_A_end, Content_4_5_A_start } from "./4-5-A";

export const Content_4_5_2_A = (automaticThoughtId: automaticThoughtIdType) => [
  ...Content_4_5_A_start(automaticThoughtId),

  Header1("typography_0_0_0"),
  InstructionWithContent(`typography_1_0_0`, [
    TypographyFromDataLine({ A: { id: "상황" } }, "$A$"),
  ]),
  InstructionWithContent(`typography_2_0_0`, [
    TypographyFromDataLine({ A: { id: "생각" } }, "$A$"),
  ]),
  InstructionWithContent("typography_3_0_0", [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`근거`, { labelIndex: each }),
      TextareaLine(),
    ]),
  ]),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),

  Header1("typography_6_0_0"),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  InstructionWithContent(`typography_10_0_0`, [
    TypographyLine(`typography_10_2_0`),
  ]),
  InstructionWithContent("typography_11_0_0", [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`요소`, { labelIndex: each }),
      TypographyLine(
        each === 1
          ? "typography_11_3_0"
          : each === 2
            ? "typography_11_6_0"
            : each === 3
              ? "typography_11_9_0"
              : each === 4
                ? "typography_11_12_0"
                : "typography_11_15_0"
      ),
    ]),
  ]),
  Instruction(`typography_12_0_0`),

  Header1("typography_12_0_1"),
  InstructionWithPreset(
    `typography_13_0_0`,
    {
      id: "대상",
      coach: true,
      options: [
        {
          translationKey: "나",
          translationKey_additional: "나_additional",
        },
        {
          translationKey: "타인",
          translationKey_additional: "타인_additional",
        },
        {
          translationKey: "세상",
          translationKey_additional: "세상_additional",
        },
      ],
      presetKey: `statementSubject`,
    },
    { hideIfPatient: true }
  ),
  // InstructionWithCoachTextarea(`typography_14_0_0`, {
  //   id: "명제",
  //   placeholder: `내담자의 자동적 사고를 일반화하여 적어주세요.`,
  // }),
  InstructionWithPreset(`typography_14_0_0`, {
    id: "명제",
    placeholder: `내담자의 자동적 사고를 일반화하여 적어주세요.`,
    presetKey: `statement`,
  }),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  InstructionWithContent("typography_17_0_0", [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`요소`, { labelIndex: each }),
      TextareaLine({ id: `요소 ${each}` }),
    ]),
  ]),
  Instruction(`typography_18_0_0`),
  InstructionWithData(
    { A: { id: `대상`, additional: true } },
    `typographyFromData_17_0_0`
  ),
  InstructionWithDataWithContent(
    { A: { id: `대상`, additional: true } },
    `typographyFromData_18_0_0`,
    [
      ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`요소`, {
          requiredId: `요소 ${each}`,
          labelIndex: each,
        }),
        TypographyFromDataLine({ A: { id: `요소 ${each}` } }, "$A$", {
          requiredId: `요소 ${each}`,
        }),
        DimmedLabelLine("해당하는 근거", {
          requiredId: `요소 ${each}`,
        }),
        TextareaLine({
          id: `해당하는 근거 ${each}`,
          requiredId: `요소 ${each}`,
        }),
      ]),
    ]
  ),

  InstructionWithData(
    { A: { id: `대상`, additional: true } },
    `typographyFromData_20_0_0_0`
  ),
  ...Array.from({ length: 5 }, (v, i) => i + 1).map((each) =>
    Content([
      SolidLabelLine(`요소`, {
        requiredId: `요소 ${each}`,
        labelIndex: each,
      }),
      TypographyFromDataLine({ A: { id: `요소 ${each}` } }, "$A$", {
        requiredId: `요소 ${each}`,
      }),
      DimmedLabelLine("해당하는 근거", {
        requiredId: `요소 ${each}`,
      }),
      TypographyFromDataLine({ A: { id: `해당하는 근거 ${each}` } }, "$A$", {
        requiredId: `요소 ${each}`,
      }),
      DimmedLabelLine("해당하는 정도", {
        requiredId: `요소 ${each}`,
      }),
      [
        Percentage({
          id: `요소 ${each} 해당하는 정도`,
          width: 0.4,
          requiredId: `요소 ${each}`,
        }),
      ],
    ])
  ),

  Header1("typography_20_0_0"),

  InstructionWithPreset(`typography_21_0_0`, {
    id: "반대 명제",
    placeholder: `내담자의 자동적 사고를 반대로 뒤집어 일반화하여 적어주세요`,
    presetKey: `statementFlipped`,
  }),
  Instruction(`typography_21_0_1`),
  Instruction(`typography_21_0_2`),
  InstructionWithContent("typography_22_0_0", [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`요소`, { labelIndex: each }),
      TextareaLine({ id: `반대 요소 ${each}` }),
    ]),
  ]),

  Instruction(`typography_18_0_0`),
  InstructionWithData(
    { A: { id: `대상`, additional: true } },
    `typographyFromData_17_0_0`
  ),

  InstructionWithDataWithContent(
    { A: { id: `대상`, additional: true } },
    `typographyFromData_23_0_0`,
    [
      ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`요소`, {
          requiredId: `반대 요소 ${each}`,
          labelIndex: each,
        }),
        TypographyFromDataLine({ A: { id: `반대 요소 ${each}` } }, "$A$", {
          requiredId: `요소 ${each}`,
        }),
        DimmedLabelLine("해당하는 근거", {
          requiredId: `반대 요소 ${each}`,
        }),
        TextareaLine({
          id: `반대 해당하는 근거 ${each}`,
          requiredId: `반대 요소 ${each}`,
        }),
      ]),
    ]
  ),
  InstructionWithData(
    { A: { id: `대상`, additional: true } },
    `typographyFromData_25_0_0_0`
  ),
  ...Array.from({ length: 5 }, (v, i) => i + 1).map((each) =>
    Content([
      SolidLabelLine(`요소`, {
        requiredId: `반대 요소 ${each}`,
        labelIndex: each,
      }),
      TypographyFromDataLine({ A: { id: `반대 요소 ${each}` } }, "$A$", {
        requiredId: `반대 요소 ${each}`,
      }),
      DimmedLabelLine("해당하는 근거", {
        requiredId: `반대 요소 ${each}`,
      }),
      TypographyFromDataLine(
        { A: { id: `반대 해당하는 근거 ${each}` } },
        "$A$",
        {
          requiredId: `반대 요소 ${each}`,
        }
      ),
      DimmedLabelLine("해당하는 정도", {
        requiredId: `반대 요소 ${each}`,
      }),
      [
        Percentage({
          id: `반대 요소 ${each} 해당하는 정도`,
          width: 0.4,
          requiredId: `반대 요소 ${each}`,
        }),
      ],
    ])
  ),

  Header1(`typography_26_0_0`),
  Instruction(`typography_27_0_0`),
  InstructionWithDataWithContent(
    { A: { id: "명제" }, B: { id: "대상" } },
    `typographyFromData_28_0_0`,
    [
      ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`요소`, {
          requiredId: `요소 ${each}`,
          labelIndex: each,
        }),
        TypographyFromDataLine({ A: { id: `요소 ${each}` } }, "$A$", {
          requiredId: `요소 ${each}`,
        }),
        DimmedLabelLine("해당하는 정도", {
          requiredId: `요소 ${each}`,
        }),
        TypographyFromDataLine(
          { A: { id: `요소 ${each} 해당하는 정도` } },
          "$A$",
          {
            requiredId: `요소 ${each}`,
          }
        ),
      ]),
    ]
  ),
  InstructionWithDataWithContent(
    { A: { id: "반대 명제" }, B: { id: "대상" } },
    `typographyFromData_29_0_0`,
    [
      ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`요소`, {
          requiredId: `반대 요소 ${each}`,
          labelIndex: each,
        }),
        TypographyFromDataLine({ A: { id: `반대 요소 ${each}` } }, "$A$", {
          requiredId: `반대 요소 ${each}`,
        }),
        DimmedLabelLine("해당하는 정도", {
          requiredId: `반대 요소 ${each}`,
        }),
        TypographyFromDataLine(
          { A: { id: `반대 요소 ${each} 해당하는 정도` } },
          "$A$",
          {
            requiredId: `반대 요소 ${each}`,
          }
        ),
      ]),
    ]
  ),
  InstructionWithTextarea(`typography_30_0_0`),
  ...Content_4_5_A_end(automaticThoughtId, 2),
];