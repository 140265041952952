import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import {
  Box,
  ColorPaletteProp,
  ListItemButton,
  Typography,
  VariantProp,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { usePrevious } from "@uidotdev/usehooks";
import { useRecoilValue } from "recoil";
import { permanentDataAtom } from "recoil/permanentDataAtom";
import { addAlpha } from "styles/hexOpacity";
import { sendMessage } from "utils/webview";

export default function ButtonWithModalDoubleAction({
  variant,
  size,
  color,
  startDecorator,
  buttonText,
  buttonTextSize,
  title,
  subtitle,
  textAlign,
  content,
  disabled,
  onClick,
  disableModalOpen,
  cancelAction,
  cancelText,
  isListItemButton,
  onClose,
  buttonFullWidth,
  clickWithEnter,
  setClickWithEnter,
  render,
  loading,
  bottomIfMobile,
  isWide,
  actionPrimary,
  actionSecondary,
  actionPrimaryText,
  actionSecondaryText,
  actionPrimaryButtonColor,
  actionSecondaryButtonColor,
  loadingPrimaryButton,
  loadingSecondaryButton,
}: {
  variant?: VariantProp;
  size?: "sm" | "md" | "lg";
  color?: "primary" | "secondary" | "danger" | "white";
  startDecorator?: React.ReactNode;
  buttonText: string;
  buttonTextSize?: "sm" | "md" | "lg";
  title?: string;
  subtitle?: string;
  textAlign?: "center";
  content?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  disableModalOpen?: boolean;
  cancelAction?: () => void;
  cancelText?: string;
  isListItemButton?: boolean;
  onClose?: () => void;
  buttonFullWidth?: boolean;
  clickWithEnter?: boolean;
  setClickWithEnter?: React.Dispatch<React.SetStateAction<boolean>>;
  render?: React.ReactNode;
  loading?: boolean;
  bottomIfMobile?: boolean;
  isWide?: boolean;
  actionPrimary?: () => void;
  actionSecondary?: () => void;
  actionPrimaryText?: string;
  actionSecondaryText?: string;
  actionPrimaryButtonColor?: ColorPaletteProp;
  actionSecondaryButtonColor?: ColorPaletteProp;
  loadingPrimaryButton?: boolean;
  loadingSecondaryButton?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const previousOpen = usePrevious(open);
  const permanantDataState = useRecoilValue(permanentDataAtom);
  const [backdropOpacity, setBackdropOpacity] = useState(0);

  useEffect(() => {
    if (previousOpen && !open && onClose) {
      onClose();
    }
  }, [open]);

  function sendModalEventToApp(status: "open" | "close") {
    sendMessage(
      JSON.stringify({
        event: "modal",
        params: { status },
      })
    );
  }

  useEffect(() => {
    return () => {
      sendModalEventToApp("close");
    };
  }, []);

  const handleClick = () => {
    if (!disableModalOpen) {
      sendModalEventToApp("open");
    }

    setTimeout(() => {
      if (onClick) onClick();
      if (!disableModalOpen) setOpen(true);
      setTimeout(() => setBackdropOpacity(1), 10);
    }, 100);
  };

  useEffect(() => {
    if (clickWithEnter && !disabled) {
      handleClick();
      if (setClickWithEnter) setClickWithEnter(false);
    }
  }, [clickWithEnter]);

  return (
    <React.Fragment>
      {render ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        >
          {render}
        </div>
      ) : isListItemButton ? (
        <ListItemButton
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          className="flex justify-center"
        >
          <Typography>{buttonText}</Typography>
        </ListItemButton>
      ) : (
        <Button
          disabled={disabled}
          variant={variant}
          size={size}
          color={color}
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          startDecorator={startDecorator}
          loading={loading}
          sx={{
            "--joy-focus-thickness": "0px",
            "--variant-borderWidth": "0px",
            ...(buttonFullWidth && { width: "100%" }),
            ...(buttonTextSize === "sm" && { fontSize: "14px" }),
          }}
        >
          {buttonText}
        </Button>
      )}
      <Modal
        open={open}
        onClose={(e, r) => {
          if (r === "backdropClick") (e as MouseEvent).stopPropagation();
          sendModalEventToApp("close");
          setTimeout(() => {
            setBackdropOpacity(0);
            setOpen(false);
          }, 100);
        }}
        sx={{
          "&>.MuiModal-backdrop": {
            backdropFilter: "none",
            backgroundColor: addAlpha("#000000", 0.3),
            opacity: backdropOpacity,
            transition: "all 0.3s",
          },
        }}
      >
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            ...(isWide && {
              width: "100%",
              [theme.breakpoints.up("sm")]: {
                maxWidth: "600px",
              },
            }),
            [theme.breakpoints.only("xs")]: bottomIfMobile
              ? {
                  top: "unset",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  borderRadius: 0,
                  transform: "none",
                  maxWidth: "unset",
                  paddingBottom: `${permanantDataState?.homeIndicatorHeight}px`,
                }
              : {},
          })}
          onClick={(e) => e.stopPropagation()}
        >
          {title && (
            <Typography
              variant="Subtitle"
              sx={{ textAlign, mb: 1, fontWeight: 700 }}
            >
              {title}
            </Typography>
          )}
          {subtitle && <Typography variant="Body">{subtitle}</Typography>}
          {content}
          <Box sx={{ mt: 1, display: "flex", gap: 0.5, flexDirection: "column" }}>
            {actionPrimaryText && (
              <Button
                variant="Action"
                size="lg"
                sx={{
                  fontWeight: 700,
                }}
                color={actionPrimaryButtonColor || "primary"}
                onClick={(e) => {
                  e.stopPropagation();
                  if (actionPrimary) {
                    actionPrimary();
                    setOpen(false); // 성공 후 닫기
                    sendModalEventToApp("close");
                  }
                }}
                loading={loadingPrimaryButton}
              >
                {actionPrimaryText}
              </Button>
            )}
            {actionSecondaryText && (
              <Button
                variant="plain"
                size="sm"
                color={actionSecondaryButtonColor || "neutral"}
                onClick={(e) => {
                  e.stopPropagation();
                  actionSecondary && actionSecondary();
                }}
                loading={loadingSecondaryButton}
              >
                {actionSecondaryText}
              </Button>
            )}
            {cancelText && (
              <Button
                variant="plain"
                onClick={(e) => {
                  e.stopPropagation();
                  cancelAction && cancelAction();
                  setOpen(false);
                }}
              >
                {cancelText}
              </Button>
            )}
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}