import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Stack, Typography, useTheme } from '@mui/joy'
import playIcon from 'static/image/guide/Play.svg'
import pauseIcon from 'static/image/guide/Pause.svg'
import guide1Img from 'static/image/guide/guide_1.png'
import guide2Img from 'static/image/guide/guide_2.png'
import guide3Img from 'static/image/guide/guide_3.png'
import guide4Img from 'static/image/guide/guide_4.png'
import guide5Img from 'static/image/guide/guide_5.png'
import guide6Img from 'static/image/guide/guide_6.png'
import guide7Img from 'static/image/guide/guide_7.png'
import guide8Img from 'static/image/guide/guide_8.png'
import MarqueeLabel from './MarqueeLabel'
import { useNavigate, useParams } from 'react-router-dom'
import distancingLogo from '../../../static/image/common/distancing_logo.png'

// 예시 Step 정의
interface GuideStep {
  seq: number;
  title: string;
  description: string;
  backgroundColor: string;
  mainImg: string;
  audioSrc: string;
}

const steps: GuideStep[] = [
  {
    seq: 1,
    title: "주의력 되찾기",
    description: "‘나’의 주의를 한 곳에 집중시킵니다.",
    backgroundColor: "#222D6D",
    mainImg: guide1Img,
    audioSrc: "https://media.distancing.im/guide/QR1.mp3",
  },
  {
    seq: 2,
    title: "생각 알아차리기",
    description:
      "생각을 ‘나'와 동일시하지 않고 하나의 심리적 사건으로 바라봅니다.",
    backgroundColor: "#158893",
    mainImg: guide2Img,
    audioSrc: "https://media.distancing.im/guide/QR2.mp3",
  },
  {
    seq: 3,
    title: "반추 빠져나오기",
    description: "주의를 호흡에 집중하며 반추에서 빠져나옵니다.",
    backgroundColor: "#0F7630",
    mainImg: guide3Img,
    audioSrc: "https://media.distancing.im/guide/QR3.mp3",
  },
  {
    seq: 4,
    title: "바디스캔",
    description: "‘나'의 위치를 지키며 신체의 각 부분을 섬세하게 알아차립니다.",
    backgroundColor: "#272E6A",
    mainImg: guide4Img,
    audioSrc: "https://media.distancing.im/guide/QR4.mp3",
  },
  {
    seq: 5,
    title: "근육 이완",
    description:
      "‘‘나'의 위치를 지키며 전신의 근육을 수축하고 이완하며 긴장을 풉니다.",
    backgroundColor: "#559E87",
    mainImg: guide5Img,
    audioSrc: "https://media.distancing.im/guide/QR5.mp3",
  },
  {
    seq: 6,
    title: "그라운딩",
    description:
      "신체가 땅과 맞닿아 있는 느낌을 알아차리며 ‘나'의 관점에서 신체 감각과 새롭게 관계를 맺습니다.",
    backgroundColor: "#747026",
    mainImg: guide6Img,
    audioSrc: "https://media.distancing.im/guide/QR6.mp3",
  },
  {
    seq: 7,
    title: "기꺼이 경험하기",
    description:
      "마음속에 떠오르는 감정을 알아차리고 피하지 않으며 기꺼이 경험합니다.",
    backgroundColor: "#2F4344",
    mainImg: guide7Img,
    audioSrc: "https://media.distancing.im/guide/QR7.mp3",
  },
  {
    seq: 8,
    title: "가치 확언하기",
    description:
      "주의를 가치로 돌리며 마음속에 떠오르는 가치를 더욱 명확하게 합니다.",
    backgroundColor: "#4488C4",
    mainImg: guide8Img,
    audioSrc: "https://media.distancing.im/guide/QR8.mp3",
  },
];

// 시간(초)을 "분:초" 변환
function formatTime(seconds: number) {
  if (!seconds || seconds < 0) return "0:00";
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  return `${m}:${s < 10 ? "0" + s : s}`;
}

export default function AudioGuide() {
  const navigate = useNavigate();

  const { id } = useParams();

  const numId = parseInt(id || "1");

  const currentStepIndex = Math.min(Math.max(numId - 1, 0), steps.length - 1);

  const step = steps[currentStepIndex];
  const theme = useTheme();

  const [selectedStep, setSelectedStep] = useState<number | null>(null);

  // 오디오 재생 제어
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0); // 전체 길이(초)
  const [currentTime, setCurrentTime] = useState(0);

  // 오디오 로드
  useEffect(() => {
    audioRef.current = new Audio(step.audioSrc);

    const handleLoaded = () => {
      if (!audioRef.current) return;
      setDuration(audioRef.current.duration);
    };
    const handleTimeUpdate = () => {
      if (!audioRef.current) return;
      setCurrentTime(audioRef.current.currentTime);
    };
    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    audioRef.current.addEventListener("loadedmetadata", handleLoaded);
    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    audioRef.current.addEventListener("ended", handleEnded);

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener("loadedmetadata", handleLoaded);
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("ended", handleEnded);
        audioRef.current = null;
      }
    };
  }, [step.audioSrc]);

  // 재생/일시정지
  const handleTogglePlay = () => {
    if (!audioRef.current) return;
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const progressRatio = duration ? currentTime / duration : 0;
  const remainingTime = duration - currentTime;

  // numId가 1이고 아직 선택하지 않은 경우 선택 UI 표시
  if (numId === 1 && selectedStep === null) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: theme.vars.palette.Background.Level3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2,
          pb: 6
        }}
      >
        <Stack spacing={2} alignItems="center">
          <img
            src={distancingLogo}
            alt="distancing logo"
            style={{ width: "124px", height: "auto" }}
          />
          <Typography
            variant="Subtitle"
            sx={{ fontWeight: 500, textAlign: "center" }}
          >
            원하는 훈련을 선택하세요.
          </Typography>

          <Stack spacing={2} direction="column" sx={{ width: "100%" }}>
            <Button
              variant="outlined"
              sx={{ width: "100%", height: 54, borderRadius: "12px" }}
              onClick={() => {
                setSelectedStep(1);
                navigate("/guide/1");
              }}
            >
              주의력 되찾기
            </Button>

            <Button
              variant="outlined"
              sx={{ width: "100%", height: 54, borderRadius: "12px" }}
              onClick={() => {
                setSelectedStep(5);
                navigate("/guide/5");
              }}
            >
              근육 이완
            </Button>
          </Stack>
        </Stack>
        {/* (중요) 하단 플로팅 영역 */}
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            bgColor: "rgba(255,255,255,0.15)",
            backgroundColor: "rgba(34,45,109,0.85)", // 예시로 반투명 배경
            backdropFilter: "blur(8px)",
            borderRadius: 16,
            padding: "18px",
            width: "calc(100% - 40px)",
            maxWidth: 420,
          }}
        >
          <Typography
            sx={{
              color: theme.vars.palette.Content.Negative,
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            {"디스턴싱에서 더욱\n체계적으로 연습하세요"}
          </Typography>
          <Button
            sx={{
              color: theme.vars.palette.Content.Strong,
              backgroundColor: theme.vars.palette.Surface.Forward,
              fontWeight: 700,
              borderRadius: "8px",
              fontSize: 14,
              padding: "4px 12px", // 위·아래, 좌·우 여백
              minHeight: "auto", // 기본 버튼 높이 제한 해제
              "@media (min-width: 1024px)": {
                "&:hover:not(.Mui-disabled)": {
                  color: theme.vars.palette.Content.Strong,
                  backgroundColor: theme.vars.palette.Surface.Forward,
                  opacity: 0.6,
                },
              },
              "@media (max-width: 1024px)": {
                "&:hover": {
                  // 모바일에서 터치 끝나도 hover로 잡는 문제 때문에 hover은 1024 이상에서만 적용
                  // ios : 버튼 클릭 후 touchend 해도 hover
                  color: theme.vars.palette.Content.Strong,
                  backgroundColor: theme.vars.palette.Surface.Forward,
                },
              },
              //클릭 이후 포커스 상태
              "&:focus": {
                backgroundColor: theme.vars.palette.Surface.Forward,
              },
              //모바일 클릭
              "&:active": {
                color: theme.vars.palette.Content.Strong,
                backgroundColor: theme.vars.palette.Surface.Forward,
                opacity: 0.6,
              },
              "&.Mui-disabled": {
                backgroundColor: theme.vars.palette.Surface.Dead,
                color: "#AFB6BA",
                "&:active": {
                  // Mui-disabled 상태에서 active 스타일 재정의
                  backgroundColor: theme.vars.palette.Surface.Dead, // 비활성화된 배경색 유지
                  opacity: 1, // 투명도 변경 방지
                  pointerEvents: "none", // 클릭 이벤트 방지
                },
              },
              "&.MuiButton-loading": {
                backgroundColor: theme.vars.palette.Surface.Forward,
                color: "transparent",
                opacity: 0.6,
              },
            }}
            onClick={() => {
              // 누르면 앱 스키마로 이동 혹은 스토어 링크 등 처리
              window.open(
                "https://distancing.onelink.me/DeUR/BookAudioGuide",
                "_blank"
              );
            }}
          >
            앱에서 열기
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflow: "auto",
        backgroundColor: step.backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        pt: 8,
        pb: 18,
      }}
    >
      <Stack
        spacing={3}
        alignItems="center"
        sx={{
          width: "100%",
          maxWidth: 500,
          px: 2,
        }}
      >
        {/* 메인 이미지 */}
        <Box
          sx={{
            width: 254,
            height: 254,
            borderRadius: 2,
            border: "0px solid rgba(255,255,255,0.1)",
            boxShadow: "0px 24px 44px -20px #0000003D",
            overflow: "hidden",
          }}
        >
          <img
            src={step.mainImg}
            alt="guide"
            style={{
              width: "100%",
              height: "100%",
              display: "block",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* 제목 + 안내 문구 */}
        <Stack
          spacing={0.5}
          alignItems="start"
          sx={{ width: "100%", px: 3, pt: 2 }}
        >
          <Typography
            variant="Subtitle"
            sx={{
              fontWeight: 700,
              color: theme.vars.palette.Content.Negative,
              textAlign: "center",
            }}
          >
            {`${step.seq}. ${step.title}`}
          </Typography>
          <MarqueeLabel
            variant="Subtitle"
            containerWidth={"100%"}
            sx={{
              fontWeight: 500,
              color: theme.vars.palette.Content.Negative,
              opacity: 0.6,
            }}
            text={step.description}
          />
        </Stack>

        {/* 오디오 플레이어 UI */}
        <Stack
          spacing={0}
          alignItems="center"
          sx={{ width: "100%", px: 3, pt: 0.5 }}
        >
          {/* 진행바 */}
          <Box
            sx={{
              width: "100%",
              height: 6,
              backgroundColor: "rgba(255,255,255,0.3)",
              borderRadius: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: `${progressRatio * 100}%`,
                height: "100%",
                backgroundColor: "#fff",
                borderRadius: 3,
                transition: "width 0.2s linear",
              }}
            />
          </Box>

          {/* 시간 (현재 / 남은) */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", pt: 2 }}
          >
            <Typography
              variant="Footnote"
              sx={{
                fontWeight: 700,
                color: theme.vars.palette.Content.Negative,
              }}
            >
              {formatTime(currentTime)}
            </Typography>
            <Typography
              variant="Footnote"
              sx={{
                fontWeight: 700,
                color: theme.vars.palette.Content.Negative,
              }}
            >
              -{formatTime(remainingTime)}
            </Typography>
          </Stack>

          {/* 재생/일시정지 버튼 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleTogglePlay}
          >
            <img
              src={isPlaying ? pauseIcon : playIcon}
              alt="play_or_pause"
              style={{ width: 80, height: 80 }}
            />
          </Box>
        </Stack>
      </Stack>

      {/* (중요) 하단 플로팅 영역 */}
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgColor: "rgba(255,255,255,0.15)",
          backgroundColor: "rgba(0,0,0,0.3)", // 예시로 반투명 배경
          backdropFilter: "blur(8px)",
          borderRadius: 16,
          padding: "18px",
          width: "calc(100% - 40px)",
          maxWidth: 420,
        }}
      >
        <Typography
          sx={{
            color: theme.vars.palette.Content.Negative,
            fontSize: 14,
            fontWeight: 700,
          }}
        >
          {"디스턴싱에서 더욱\n체계적으로 연습하세요"}
        </Typography>
        <Button
          sx={{
            color: theme.vars.palette.Content.Strong,
            backgroundColor: theme.vars.palette.Surface.Forward,
            fontWeight: 700,
            borderRadius: "8px",
            fontSize: 14,
            padding: "4px 12px", // 위·아래, 좌·우 여백
            minHeight: "auto", // 기본 버튼 높이 제한 해제
            "@media (min-width: 1024px)": {
              "&:hover:not(.Mui-disabled)": {
                color: theme.vars.palette.Content.Strong,
                backgroundColor: theme.vars.palette.Surface.Forward,
                opacity: 0.6,
              },
            },
            "@media (max-width: 1024px)": {
              "&:hover": {
                // 모바일에서 터치 끝나도 hover로 잡는 문제 때문에 hover은 1024 이상에서만 적용
                // ios : 버튼 클릭 후 touchend 해도 hover
                color: theme.vars.palette.Content.Strong,
                backgroundColor: theme.vars.palette.Surface.Forward,
              },
            },
            //클릭 이후 포커스 상태
            "&:focus": {
              backgroundColor: theme.vars.palette.Surface.Forward,
            },
            //모바일 클릭
            "&:active": {
              color: theme.vars.palette.Content.Strong,
              backgroundColor: theme.vars.palette.Surface.Forward,
              opacity: 0.6,
            },
            "&.Mui-disabled": {
              backgroundColor: theme.vars.palette.Surface.Dead,
              color: "#AFB6BA",
              "&:active": {
                // Mui-disabled 상태에서 active 스타일 재정의
                backgroundColor: theme.vars.palette.Surface.Dead, // 비활성화된 배경색 유지
                opacity: 1, // 투명도 변경 방지
                pointerEvents: "none", // 클릭 이벤트 방지
              },
            },
            "&.MuiButton-loading": {
              backgroundColor: theme.vars.palette.Surface.Forward,
              color: "transparent",
              opacity: 0.6,
            },
          }}
          onClick={() => {
            // 누르면 앱 스키마로 이동 혹은 스토어 링크 등 처리
            window.open(
              "https://distancing.onelink.me/DeUR/BookAudioGuide",
              "_blank"
            );
          }}
        >
          앱에서 열기
        </Button>
      </Box>
    </Box>
  );
}
