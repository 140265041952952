import { Box, Sheet, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import HomeTaskListV2 from "../task-list/HomeTaskListV2";

export default function TaskSidebar() {
  const selectedPatient = useRecoilValue(patientAtom);

  return (
    <Sheet
      sx={{
        width: "280px",
        height: "100%",
        overflowY: "auto",
        backgroundColor: "background.level1",
        borderColor: "divider",
      }}
    >
      {selectedPatient ? (
        <HomeTaskListV2 />
      ) : (
        <Box sx={{ p: 2 }} alignItems={"center"}>
          <Typography level="body-sm" color="neutral">
            내담자를 선택해주세요.
          </Typography>
        </Box>
      )}
    </Sheet>
  );
}