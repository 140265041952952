import { useEffect, useState } from 'react'
import { Box, Sheet, Stack, Typography } from '@mui/joy'
import { useRecoilValue } from 'recoil'
import { layoutAtom } from 'recoil/layoutAtom'
import ConceptNoteV2 from 'components/coachRightSidebar/conceptNote/ConceptNoteV2'
import MessageSearch from '../coaching/MessageSearch'
import { selectedThoughtRecordKeyAtom } from 'recoil/selectedThoughtRecordAtom'

// 탭용 타입
type AssistantTab = "개념화" | "코칭";

type AssistantProps = {
  patientId: string;
};

export default function Assistant({ patientId }: AssistantProps) {
  // 사이드바 열림/닫힘 상태
  const { isRightSideBarOpen } = useRecoilValue(layoutAtom);
  const selectedThoughtRecordKey = useRecoilValue(selectedThoughtRecordKeyAtom);

  const [currentTab, setCurrentTab] = useState<AssistantTab>("개념화");

  useEffect(() => {
    if (!selectedThoughtRecordKey) return;

    setCurrentTab("개념화");
  }, [selectedThoughtRecordKey]);

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", lg: !isRightSideBarOpen ? "fixed" : "sticky" },
        transform: !isRightSideBarOpen ? "translateX(100%)" : "translateX(0)",
        transition: "transform 0.4s, width 0.4s",
        width: "var(--InsideC-width)",
        height: {
          xs: "calc(100% - var(--Header-height, 0))",
          lg: !isRightSideBarOpen
            ? "calc(100% - var(--Header-height, 0))"
            : "100%",
        },
        zIndex: { xs: 1000, lg: 0 },
        top: {
          xs: "var(--Header-height, 0)",
          lg: !isRightSideBarOpen ? "var(--Header-height, 0)" : 0,
        },
        right: 0,
        pb: 0,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.level2",
      }}
    >
      {/* 우측 사이드바 상단 헤더 (Sticky) */}
      <Box
        sx={{
          width: "100%",
          minHeight: "var(--StickyHeader-height)",
          height: "var(--StickyHeader-height)",
          position: "sticky",
          top: 0,
          backgroundColor: "background.level2",
          borderBottom: 1,
          borderColor: "divider",
          zIndex: 10,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: "100%", cursor: "pointer", px: 2 }}
        >
          <Typography level="title-md">어시스턴트</Typography>
        </Stack>
      </Box>

      <Stack direction="row" sx={{ width: "100%", mb: "20px", mt: 2 }}>
        {(["개념화", "코칭"] as AssistantTab[]).map((tabLabel) => (
          <Box
            key={tabLabel}
            onClick={() => setCurrentTab(tabLabel)}
            sx={{
              flex: 1,
              textAlign: "center",
              cursor: "pointer",
              pb: "8px",
              borderBottomWidth: "2px",
              borderBottomStyle: "solid",
              borderBottomColor:
                currentTab === tabLabel ? "primary.solid" : "background.level2",
            }}
          >
            <Typography variant="Caption" sx={{ fontWeight: 500 }}>
              {tabLabel}
            </Typography>
          </Box>
        ))}
      </Stack>

      <Box sx={{ flex: 1, overflow: "auto", position: "relative" }}>
        <Box
          sx={{
            display: currentTab === "개념화" ? "block" : "none",
            height: "100%",
          }}
        >
          <ConceptNoteV2
            patientId={patientId}
            isActive={currentTab === "개념화"}
          />
        </Box>
        <Box
          sx={{
            display: currentTab === "코칭" ? "block" : "none",
            height: "100%",
          }}
        >
          <MessageSearch />
        </Box>
      </Box>
    </Sheet>
  );
}
