import { Box, Option, Select, Sheet } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import { automaticThoughtIdType, ConceptKeyType } from "api/conceptApi";
import {
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
  thoughtRecordApi,
  ThoughtRecordType,
} from "api/thoughtRecordApi";
import { getIdValueFromData } from "components/ai/ConceptListItem";
import { DateHeader, ProgramContentType } from "data/BlockComponent";
import { TextareaType, TypographyType } from "data/CellComponent";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import {
  getReferenceBlock,
  getReferenceData,
  isBlockUserFieldFilled,
} from "logic/logics";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { conceptNoteAtom } from "recoil/conceptNoteAtom";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import axios from "utils/axios";

export default function ExtractFromThoughtRecordCell({
  automaticThoughtId,
  isPositive,
  defaultValue,
  blockIndex,
  data,
  setData,
  lineIndex,
  cellIndex,
}: {
  automaticThoughtId: automaticThoughtIdType;
  isPositive?: boolean;
  defaultValue?: string;
  blockIndex: number;
  data: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
}) {
  const patientState = useRecoilValue(patientAtom);
  const user = useRecoilValue(userAtom);

  const disabled = !!getReferenceBlock(data || [], "thoughtRecord");

  const queryFn = useCallback(
    () => thoughtRecordApi.getList(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery([THOUGHTRECORD_DOMAIN, THOUGHTRECORD_LIST_ENDPOINT], queryFn, {
    enabled: !!patientState?.patientId,
    onSuccess: (data) => {
      if (!data.result || data.result.length === 0) {
      } else {
        const list = data.result;
        list.sort((a, b) => {
          return +new Date(b.date || "") - +new Date(a.date || "");
        });

        setListData(list.filter((element) => element.situation));
      }
    },
  });

  const [listData, setListData] = useState<ThoughtRecordType[]>();
  const conceptNoteState = useRecoilValue(conceptNoteAtom);
  useEffect(() => {
    if (conceptNoteState) {
      const id1 = `automaticThought${
        isPositive ? "ForValue" : ""
      }${automaticThoughtId}` as ConceptKeyType;
      if (
        getIdValueFromData({
          data: conceptNoteState,
          id1: id1,
          id2: "thoughtRecordKey",
        }) &&
        !disabled
      ) {
        const thoughtRecordKey = getIdValueFromData({
          data: conceptNoteState,
          id1: id1,
          id2: "thoughtRecordKey",
        });

        setData((data) => {
          if (data) {
            const data_temp = _.cloneDeep(data);
            data_temp[blockIndex].isAlwaysHidden = true;
            return data_temp;
          }
          return data;
        });
        addThoughtRecordBlocks(thoughtRecordKey);
      } else {
      }
    }
  }, [conceptNoteState]);

  const addThoughtRecordBlocks = async (thoughtRecordKey: string) => {
    let selectedThoughtRecordData: ProgramContentType[] = [];
    let translationVersion: string;
    try {
      const response = await axios(user?.accessToken).get(
        "/thought-record/data/load",
        {
          params: { trk: thoughtRecordKey },
        }
      );

      if (response.data.code === 0) {
        const data = response.data;
        translationVersion =
          (data.result.data as ProgramType).translationVersion || "v1";

        const endIndex = data.result.data.content.findIndex(
          (element: ProgramContentType) =>
            element.lines[0][0].content.level === "h3" &&
            element.lines[0][0].content.translationKey !==
              "typography_2_0_0_1" &&
            element.lines[0][0].content.translationKey !==
              "ifABCFilled_typography_1"
        );
        if (endIndex > -1) {
          selectedThoughtRecordData = data.result.data.content
            .slice(0, endIndex)
            .filter((element: ProgramContentType) =>
              isBlockUserFieldFilled(element) &&
              !element.lines.flat().some((line) => line.content?.id === "고민")
            );
        } else {
          selectedThoughtRecordData = data.result.data.content
            .filter((element: ProgramContentType) =>
              isBlockUserFieldFilled(element)
            )
            .filter(
              (element: ProgramContentType) =>
                !element.lines
                  .flat()
                  .some((line) => line.content?.id === "고민")
            );
          console.log(data.result.data.content, selectedThoughtRecordData);
        }
      } else {
      }
    } catch (error: any) {}
    const thoughtRecordData = [
      DateHeader(
        `${getReferenceData(selectedThoughtRecordData, "date")?.value}`,
        { removable: true, blockId: "thoughtRecord" }
      ),
      ...selectedThoughtRecordData
        .filter((element) =>
          element.lines.flat().some((each) => each.type === "textarea")
        )
        .map(({ lines, indentation }) => ({
          lines: lines,
          removable: true,
          indentation: indentation,
          blockId: "thoughtRecord",
        })),
    ];
    thoughtRecordData
      .map((element) => element.lines)
      .flat(2)
      .forEach((element) => {
        if (
          element.type === "typography" &&
          !(element.content as TypographyType).isLabel
        ) {
          (element.content as TypographyType).translationKey =
            `2-2-T:${translationVersion}:${
              (element.content as TypographyType).translationKey
            }`;
        }
        (element.content as TextareaType).readonly = true;
      });
    console.log("thoughtRecordData: ", thoughtRecordData);
    setData((data) => {
      if (data) {
        const originalValue = defaultValue || "";

        if (originalValue !== thoughtRecordKey) {
          const data_temp = _.cloneDeep(data);
          if (
            data_temp[blockIndex].lines &&
            data_temp[blockIndex].lines[lineIndex]
          ) {
            const currentContent =
              data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};

            data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
              ...currentContent,
              value: thoughtRecordKey,
            };
          }

          if (!getReferenceBlock(data_temp || [], "thoughtRecord")) {
            thoughtRecordData.forEach((item, index) => {
              data_temp.splice(blockIndex + index, 0, item);
            });
            // return [
            //   ...data_temp.slice(0, blockIndex),
            //   ...thoughtRecordData,
            //   ...data_temp.slice(blockIndex),
            // ];
          }
          return data_temp;
        }
        return data;
      }
      return data;
    });
  };

  const handleChange = async (selectedIndex: number) => {
    if (selectedIndex !== null && listData) {
      const selectedThoughtRecordKey = listData[selectedIndex].thoughtRecordKey;
      addThoughtRecordBlocks(selectedThoughtRecordKey);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(500);

  useEffect(() => {
    if (containerRef.current && containerRef.current.offsetWidth > 0) {
      setWidth(containerRef.current?.offsetWidth);
    }
  }, []);

  return (
    <Box ref={containerRef} sx={{ position: "relative" }}>
      {(defaultValue || "").trim().length === 0 && (
        <Sheet
          className="w-[calc(100%+2px)] h-[calc(100%+2px)] top-[-1px] left-[-1px] pointer-events-none"
          sx={{
            backgroundColor: "transparent",
            position: "absolute",
            borderWidth: "var(--joy-focus-thickness, 2)",
            borderRadius: "4px",
            borderColor: "danger.outlinedColor",
            zIndex: 1,
          }}
        />
      )}

      <Select
        className="w-full flex"
        onChange={(e, index) => {
          handleChange(index as number);
        }}
        sx={{
          width: "100%",
          ...(disabled && {
            pointerEvents: "none",
            backgroundColor: "background.level2",
          }),
        }}
        // renderValue={(value) => (
        //   <Typography
        //     textAlign={"left"}
        //     className="w-full overflow-hidden whitespace-normal"
        //   >
        //     {"상황을 선택해주세요"}
        //   </Typography>
        // )}
      >
        {listData?.map((option, index) => (
          <Option
            key={`${option.thoughtRecordKey}`}
            value={index}
            sx={{
              width: width,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {`${option.date}/${option.situation}`}
          </Option>
        ))}
      </Select>
    </Box>
  );
}