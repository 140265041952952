import Typography from "@mui/joy/Typography";
import { Grid, Stack } from "@mui/joy";
import { Beliefs, conceptApi, ConceptFieldKey } from "api/conceptApi";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import ConceptNoteInput from "../ConceptNoteInput";
import ConceptNoteConfirmButton from "../ConceptNoteConfirmButton";

export default function IntermediateBeliefsItem({
  beliefs,
  setBeliefs,
}: {
  beliefs: Beliefs;
  setBeliefs: React.Dispatch<React.SetStateAction<Beliefs | undefined>>;
}) {
  const patientState = useRecoilValue(patientAtom);
  const [canBeConfirmed, setCanBeConfirmed] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setCanBeConfirmed(
      (beliefs.intermediateBeliefs?.rules?.trim() ?? "").length > 0 ||
        (beliefs.intermediateBeliefs?.attitudes?.trim() ?? "").length > 0 ||
        (beliefs.intermediateBeliefs?.assumptions?.trim() ?? "").length > 0
    );
  }, [beliefs]);

  const { mutate: updateNote } = useMutation<
    void, // 성공 응답 타입
    unknown, // 에러 타입
    ConceptFieldKey
  >(
    async (payload) => {
      await conceptApi.noteUpdate(`${patientState?.patientId}`, payload);
    },
    {
      onSuccess: (_data, variables) => {},
      onError: (error) => {
        console.error("Note update failed:", error);
      },
    }
  );

  const handleSaveIntermediateBeliefsRules = (newValue: string) => {
    setBeliefs((prev) => ({
      ...prev,
      intermediateBeliefs: {
        ...prev?.intermediateBeliefs,
        rules: newValue,
      },
    }));

    updateNote({
      intermediateBeliefsRules: newValue,
    });
  };

  const handleSaveIntermediateBeliefsAttitudes = (newValue: string) => {
    setBeliefs((prev) => ({
      ...prev,
      intermediateBeliefs: {
        ...prev?.intermediateBeliefs,
        attitudes: newValue,
      },
    }));
    updateNote({
      intermediateBeliefsAttitudes: newValue,
    });
  };

  const handleSaveIntermediateBeliefsAssumptions = (newValue: string) => {
    setBeliefs((prev) => ({
      ...prev,
      intermediateBeliefs: {
        ...prev?.intermediateBeliefs,
        assumptions: newValue,
      },
    }));

    updateNote({
      intermediateBeliefsAssumptions: newValue,
    });
  };

  return (
    <Stack spacing={1}>
      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction={"row"} spacing={1}>
          <Typography level="body-xs">{"중간 믿음"}</Typography>

          {(beliefs.intermediateBeliefsStatus === "note_confirmed" ||
            beliefs.intermediateBeliefsStatus === "confirmed") && (
            <Stack
              justifyContent="center"
              sx={{ color: "primary.solid", width: "16px", height: "16px" }}
            >
              <span
                style={{ fontSize: "16px" }}
                className="material-symbols-rounded"
              >
                check
              </span>
            </Stack>
          )}
        </Stack>

        {/* 우측 액션(삭제, 확정) */}
        <Stack direction="row" spacing={1}>
          {beliefs.intermediateBeliefsStatus === "preparing" && (
            <ConceptNoteConfirmButton
              conceptNoteKey={"intermediateBeliefs"}
              label="중간 믿음"
              disabled={!canBeConfirmed}
            />
          )}
        </Stack>
      </Stack>

      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">규칙</Typography>
        </Grid>
        <Grid xs={9.5}>
          <ConceptNoteInput
            referenceByConceptFieldKey={"intermediateBeliefsRules"}
            initialValue={beliefs.intermediateBeliefs?.rules}
            onSave={handleSaveIntermediateBeliefsRules}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">태도</Typography>
        </Grid>
        <Grid xs={9.5}>
          <ConceptNoteInput
            referenceByConceptFieldKey={"intermediateBeliefsAttitudes"}
            initialValue={beliefs.intermediateBeliefs?.attitudes}
            onSave={handleSaveIntermediateBeliefsAttitudes}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">가정</Typography>
        </Grid>
        <Grid xs={9.5}>
          <ConceptNoteInput
            referenceByConceptFieldKey={"intermediateBeliefsAssumptions"}
            initialValue={beliefs.intermediateBeliefs?.assumptions}
            onSave={handleSaveIntermediateBeliefsAssumptions}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}