import * as React from "react";
import ButtonWithModal from "../../common/ButtonWithModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  HOME_DOMAIN,
  HOME_MEDITATION_LIST_ENDPOINT,
} from "../../../api/homeApi";
import {
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
  meditationRecordApi,
} from "../../../api/meditationRecordApi";

export default function RemoveMeditationRecordButton({
  meditationRecordKey,
  meditationRecordSummary,
  onClose,
  onRemove,
}: {
  meditationRecordKey: string;
  meditationRecordSummary: string;
  onClose: () => void;
  onRemove: (removedKey: string) => void;
}) {
  const queryClient = useQueryClient();
  const patientId = meditationRecordKey.split(":")[1];

  const { mutate: meditationRecordRemove } = useMutation(
    () => meditationRecordApi.remove(`${meditationRecordKey}`),
    {
      onSuccess: () => {
        onRemove(meditationRecordKey);

        queryClient.invalidateQueries([
          MEDITATION_RECORD_DOMAIN,
          MEDITATION_RECORD_LIST_ENDPOINT,
        ]);

        queryClient.invalidateQueries([
          HOME_DOMAIN,
          HOME_MEDITATION_LIST_ENDPOINT,
          patientId,
        ]);
      },
    }
  );

  return (
    <ButtonWithModal
      startDecorator={undefined}
      buttonText="명상훈련지 삭제"
      title={"정말로 삭제하시겠습니까?"}
      subtitle={`*삭제하면 복구할 수 없습니다.`}
      content={`${meditationRecordSummary}`}
      cancelText="취소"
      actionText={"확인"}
      action={meditationRecordRemove}
      isListItemButton
      actionButtonColor={"danger"}
      onClose={onClose}
    />
  );
}