import { Stack } from "@mui/joy";
import {
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_REFERENCE_LIST_ENDPOINT,
  conceptApi,
  ConceptFieldKeyType,
  ReferenceLinkType,
} from "api/conceptApi";
import Link from "components/common/Link";
import ReferenceLink from "components/coachRightSidebar/conceptNote/ReferenceLink";
import { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import NewReferenceV2 from "./NewReferenceV2";
import { useRecoilValue } from "recoil";
import { patientAtom } from "../../../recoil/patientAtom";

export default function ReferenceListV2({
  conceptFieldKey,
}: {
  conceptFieldKey: ConceptFieldKeyType;
}) {
  const patientState = useRecoilValue(patientAtom);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [referenceList, setReferenceList] = useState<
    ReferenceLinkType[] | undefined
  >(undefined);

  const getReferenceListQueryFn = useCallback(
    () =>
      conceptApi.getReferenceList(
        `${patientState?.patientId}`,
        conceptFieldKey
      ),
    [patientState?.patientId, conceptFieldKey]
  );

  const {
    data: referenceListData,
    isFetching: isFetchingReferenceList,
    refetch,
  } = useQuery(
    [
      CONCEPT_DOMAIN,
      CONCEPT_NOTE_REFERENCE_LIST_ENDPOINT,
      patientState?.patientId,
      conceptFieldKey,
    ],
    getReferenceListQueryFn,
    {
      enabled: !!patientState?.patientId && isOpen, // 열렸을 때 + 유효한 pid 일 때만 load
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (referenceListData?.result) {
      setReferenceList(referenceListData.result);
    }
  }, [referenceListData]);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  return (
    <Stack spacing={0.5} sx={{ width: "100%" }}>
      <Stack sx={{ flex: 1 }} alignItems="start">
        <Link
          text={`근거 ${isOpen ? "닫기" : "보기"}`}
          opacity={1}
          onClick={() => {
            setIsOpen((isOpen) => !isOpen);
          }}
          endDecorator={
            <span
              style={{ fontSize: "inherit" }}
              className="material-symbols-rounded"
            >
              {isOpen ? "expand_less" : "expand_more"}
            </span>
          }
        />
      </Stack>

      <motion.div
        initial={false}
        animate={{
          height: isOpen ? "fit-content" : 0,
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? "auto" : "none",
        }}
      >
        <Stack sx={{ width: "100%" }} alignItems="start">
          {referenceList?.map(({ reference, thoughtRecordKey }, index) => (
            <ReferenceLink
              key={`${conceptFieldKey}_reference_${index}`}
              reference={reference}
              thoughtRecordKey={thoughtRecordKey}
            />
          ))}

          {!isAdded ? (
            <Link
              text={""}
              opacity={0.7}
              onClick={() => {
                setIsAdded(true);
              }}
              startDecorator={
                <span
                  style={{ fontSize: "16px", height: "24px" }}
                  className="material-symbols-rounded"
                >
                  add
                </span>
              }
            />
          ) : (
            <NewReferenceV2
              conceptFieldKey={conceptFieldKey}
              setIsAdded={setIsAdded}
            />
          )}
        </Stack>
      </motion.div>
    </Stack>
  );
}