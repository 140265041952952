import { Stack } from "@mui/joy";
import CustomTypography from "../../../common/CustomTypography";
import { theme } from "styles/theme";
import React from "react";

export default function ReviewItem({
  avatarIcon,
  text,
}: {
  avatarIcon: string;
  text: string;
}) {
  return (
    <Stack alignItems="center" spacing={1}>
      {/* 이모지 or 이미지 아이콘 */}
      <img
        src={avatarIcon}
        alt={"empty"}
        style={{
          width: "100%",
          maxWidth: "44px",
          // objectFit: "cover",
          // objectPosition: "50% 0%",
        }}
      />

      {/* 후기문구 */}
      <CustomTypography
        variant={"H3"}
        sx={{
          color: theme.vars.palette.Content.Negative,
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {text}
      </CustomTypography>
    </Stack>
  );
}