import { debounce } from "lodash";
import { Sheet } from "@mui/joy";
import { memo, useEffect, useState } from "react";
import { ProgramContentType } from "data/BlockComponent";
import Quill from "./textarea/Quill";
import PlainTextarea from "./textarea/PlainTextarea";
import { setProgramContentData } from "logic/logics";
import { theme } from "styles/theme";

function TextareaCell({
  placeholder,
  initialHtmlString,
  initialValue,
  isCoachField,
  isCoach,
  blockIndex,
  readOnly,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
  id,
}: {
  placeholder?: string;
  initialHtmlString?: string | null;
  initialValue?: string | null;
  isCoachField?: boolean;
  isCoach?: boolean;
  blockIndex: number;
  readOnly?: boolean;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
  id?: string;
}) {
  const readonly = readOnly || (isCoachField && !isCoach);
  const [currentContent, setCurrentContent] = useState<{
    htmlString?: string | null;
    value?: string | null;
  }>({ htmlString: initialHtmlString, value: initialValue });

  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    setCurrentContent({
      value: initialValue ?? null,
      htmlString: initialHtmlString ?? null,
    });
  }, [initialHtmlString, initialValue]);

  const handleChange = (newVal?: string | null, newHtml?: string | null) => {
    setProgramContentData({
      setData,
      blockIndex,
      lineIndex,
      cellIndex,
      newlyAddedData: {
        // htmlString: (newHtml || "").length > 0 ? newHtml : undefined,  // 기존 코드
        // → 아래처럼, newHtml이 null이면 그대로 null, 없는 경우만 undefined
        htmlString:
          newHtml === null
            ? null
            : newHtml && newHtml.trim().length > 0
              ? newHtml
              : undefined,
        value: isCoach && typeof newVal === "string"
          ? newVal.replace(/[\n\r]+$/, "") // 코치 필드라면 마지막 개행 제거
          : newVal, // 그 외는 null이든 문자열이든 그대로
      },
    });
  };

  // 사용자가 입력할 때마다 state에 반영 + 200ms 뒤에 저장
  const handleChangeTemp = (val?: string | null, html?: string | null) => {
    setCurrentContent({ value: val, htmlString: html });
    debouncedSaveText(val, html);
  };

  // lodash의 debounce 함수를 사용하여 디바운스 함수를 생성합니다.
  const debouncedSaveText = debounce(handleChange, 200);

  useEffect(() => {
    // text 상태가 변경될 때마다 debouncedSaveText 함수를 호출합니다.
    // if (currentContent.value) {
    debouncedSaveText(
      currentContent.value === undefined ? undefined : currentContent.value,
      currentContent.htmlString
    );
    // }

    // 컴포넌트가 언마운트될 때 디바운스 함수를 취소합니다.
    return () => debouncedSaveText.cancel();
  }, [currentContent, debouncedSaveText]);

  return (
    <Sheet
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor:
          !isCoach && isCoachField
            ? "transparent"
            : isCoach && isCoachField
              ? "background.level1"
              : "white",
        px: !isCoach && isCoachField ? 0 : "10px",
        py: !isCoach && isCoachField ? 0 : "12px",

        borderRadius: "6px",
        ...(!(!isCoach && isCoachField) && {
          boxShadow: isFocused
            ? `inset 0 0 0 1.5px ${theme.vars.palette.primary.solid}`
            : `inset 0 0 0 1px ${theme.vars.palette.divider}`,
        }),
      }}
    >
      {/* {editorKey} */}

      {isCoach ? (
        <Quill
          placeholder={placeholder}
          initialHtmlString={currentContent.htmlString || ""}
          initialValue={currentContent.value || ""}
          handleChange={(val, htmlString) => {
            // Quill은 일반적으로 빈 문자열을 반환하므로 필요시 null로 변환하기
            handleChangeTemp(val === "" ? null : val, htmlString === "" ? null : htmlString);
          }}
          setIsFocused={setIsFocused}
          editorKey={`${editorKey}`.substring(2)}
          disableInlineToolbar={!isCoach}
          readOnly={readonly}
        />
      ) : (
        <PlainTextarea
          placeholder={placeholder}
          initialValue={currentContent.value || ""}
          handleChange={(val, htmlString) => {
            handleChangeTemp(
              val === "" ? null : val,
              htmlString === "" ? null : htmlString
            );
          }}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          editorKey={`${editorKey}`.substring(2)}
          emojiDisabled={id === "nickname"}
          readOnly={readonly}
          handleChangeWithoutDebounce={(value, htmlString) => {
            handleChange(value, htmlString);
          }}
        />
      )}
    </Sheet>
  );
}

export default memo(TextareaCell);