import { automaticThoughtIdType } from "api/conceptApi";
import {
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../../BlockComponent";

import { AutomaticThought, LastCard, Value } from "../common";

export const Content_7_4_A = (automaticThoughtId: automaticThoughtIdType) => [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  Instruction(`typography_3_0_0`),
  InstructionWithContent(
    `typography_4_0_0`,
    [[{ type: "extractFromThoughtRecord", content: { coach: true } }]],
    { hideIfPatient: true }
  ),
  InstructionWithContent(
    `typography_5_0_0`,
    AutomaticThought({
      id: automaticThoughtId,
      isNotConfirmed: true,
      isPositive: true,
    })
  ),
  InstructionWithContent(
    `typography_6_0_0`,
    Value({ id: automaticThoughtId, isNotConfirmed: true })
  ),
  Instruction("typography_7_0_0"),
  Instruction(`typography_8_0_0`),

  InstructionWithButtonGroup(`typography_9_0_0`, [
    {
      text: "네",
      showFromId: "동의",
      hideFromId: "비동의",
    },
    {
      text: "아니요",
      showFromId: "비동의",
      hideFromId: "동의",
    },
  ]),

  InstructionWithTextarea("typography_10_0_0", undefined, {
    blockId: "비동의",
    isHidden: true,
  }),
  Instruction("typography_11_0_0", {
    noArrow: true,
    isEnd: true,
    isHidden: true,
  }),
  Instruction(
    `typography_12_0_0`,

    { blockId: "동의" }
  ),

  Instruction("typography_14_0_0"),
  ...LastCard,
];
