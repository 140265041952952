import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import { CircularProgress, Stack } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import { SidebarMenuType } from "components/sidebar/SideBar";
import BackToPatientListButton from "./BackToPatientListButton";
import CoachMenu from "./CoachMenu";
import Overview from "./Overview";
import LearningActivityList from "./LearningActivityList";
import ThoughtRecordList from "./ThoughtRecordList";
import MeditationList from "./MeditationList";
import {
  HOME_DOMAIN,
  HOME_MEDITATION_LIST_ENDPOINT,
  HOME_TASK_LIST_ENDPOINT,
  HOME_THOUGHTRECORD_LIST_ENDPOINT,
  homeApi,
} from "api/homeApi";
import React, { useCallback, useEffect, useState } from "react";
import { useQueries } from "@tanstack/react-query";
import useIsInappWebview from "hooks/useIsInappWebview";
import { axiosInstance } from "api";
import OverviewV2 from "./OverviewV2";

export default function HomeTaskList({
  setCurrentMenu,
  backgroundColor,
}: {
  setCurrentMenu: React.Dispatch<React.SetStateAction<SidebarMenuType>>;
  backgroundColor: string;
}) {
  const patientState = useRecoilValue(patientAtom);
  const userState = useRecoilValue(userAtom);
  const isInappWebview = useIsInappWebview();

  const isCoach = userState?.accessToken && userState?.role !== "patient";
  const isV2Program =
    userState?.programVersion === "v2" || patientState?.programVersion === "v2";
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const thoughtRecordListQueryFn = useCallback(
    () => homeApi.getThoughtRecordList(`${patientState?.patientId}`),
    [patientState]
  );

  const meditationListQueryFn = useCallback(
    () => homeApi.getMeditationList(`${patientState?.patientId}`),
    [patientState]
  );

  const taskListQueryFn = useCallback(
    () => homeApi.getList(`${patientState?.patientId}`),
    [patientState]
  );

  const results = useQueries({
    queries: [
      {
        enabled:
          !!axiosInstance.defaults.headers["X-Auth-Token"] &&
          !!patientState?.patientId,
        refetchInterval: 10000,
        queryKey: [
          HOME_DOMAIN,
          HOME_THOUGHTRECORD_LIST_ENDPOINT,
          patientState?.patientId,
        ],
        queryFn: thoughtRecordListQueryFn,
      },
      {
        enabled:
          !!axiosInstance.defaults.headers["X-Auth-Token"] &&
          !!patientState?.patientId,
        refetchInterval: 10000,
        queryKey: [
          HOME_DOMAIN,
          HOME_MEDITATION_LIST_ENDPOINT,
          patientState?.patientId,
        ],
        queryFn: meditationListQueryFn,
      },
      {
        enabled:
          (!!axiosInstance.defaults.headers["X-Auth-Token"] &&
            !!patientState?.patientId) ||
          (!isCoach && !isInappWebview),
        refetchInterval: 10000,
        queryKey: [
          HOME_DOMAIN,
          HOME_TASK_LIST_ENDPOINT,
          patientState?.patientId,
        ],
        queryFn: taskListQueryFn,
      },
    ],
  });
  useEffect(() => {
    if (results) {
      if (
        !patientState?.patientId
          ? !results[2].isLoading
          : results.every((element) => !element.isLoading)
      ) {
        setIsInitialLoading(false);
      } else {
        setIsInitialLoading(true);
      }
    }
  }, [results]);

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        ...(isInappWebview && { px: "20px" }),
      }}
    >
      {isCoach && patientState && (
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            zIndex: 10,
            top: 0,
            ...(!isInappWebview && {
              px: "20px",
            }),
          }}
        >
          <BackToPatientListButton
            setCurrentMenu={setCurrentMenu}
            backgroundColor={backgroundColor}
          />
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: 0,
          flexGrow: 1,
          display: "flex",
        }}
      >
        <Stack
          sx={{
            width: "100%",
          }}
        >
          {isCoach && patientState && (
            <Stack
              sx={{
                width: "100%",
                ...(!isInappWebview && { px: "20px" }),
              }}
            >
              <CoachMenu />
            </Stack>
          )}
          {isV2Program ? <OverviewV2 /> : <Overview />}

          {isInitialLoading ? (
            <Stack
              justifyContent={"center"}
              alignItems="center"
              sx={{
                flexGrow: 1,
              }}
            >
              <CircularProgress
                sx={{
                  mt: "14px",
                  mx: "auto",
                }}
                color="neutral"
              />
            </Stack>
          ) : (
            <>
              {results[0].data?.result && (
                <ThoughtRecordList data={results[0].data?.result} />
              )}
              {results[1].data?.result && (
                <MeditationList data={results[1].data?.result} />
              )}
              {results[2].data?.result && (
                <LearningActivityList
                  data={results[2].data?.result}
                  dataUpdatedAt={results[2].dataUpdatedAt}
                />
              )}
            </>
          )}
        </Stack>
      </Box>
    </Sheet>
  );
}