export const translation_8_9_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_1:
        "지난 시간 부정적 경험이 강렬할 때 소화기처럼 사용할 수 있는 명상 기법 두 가지를 배웠어요.",
      typography_0_0_2: "감정에 압도될 때에는 '심호흡 그라운딩'",
      typography_0_0_3:
        "신체 반응에 압도될 때에는 '근육이완법'을 사용하시면 된답니다.",
      typography_0_0_4:
        "오늘은 생각에 압도될 때 사용할 수 있는 명상 기법을 소개해드릴게요.",
      typography_0_0_0: "이제 막 자동적 사고와 거리를 두기 시작했는데요.", // deprecated
      typography_1_0_0:
        "이제부터는 조금 더 직접적으로 자동적 사고를 살펴보는 활동을 진행할 거예요.", // deprecated
      typography_2_0_0:
        "이 과정에서 또 많은 분들이 마주하는 문제가 하나 있는데요.", // deprecated
      typography_3_0_0: "그건 ‘반추’라는 문제예요.", // deprecated
      typography_4_0_0: `반추에 대해서는 이후에 조금 더 자세히 알아볼 기회가 있을 텐데요.`, // deprecated
      typography_5_0_0:
        "여기서는 “생각을 끊임없이 곱씹는 과정”이라고 생각해주시면 돼요.", // deprecated
      typography_6_0_0:
        "자동적 사고를 마주하다 보면 오히려 그 생각에 매몰될 때도 있답니다.",
      typography_7_0_0:
        "생각이 많아져서 끊임없이 자동적 사고를 곱씹는 것이지요.",
      typography_8_0_0:
        "하지만 여러 연구들에 의하면 반추는 문제 해결에 도움이 되지 않는다고 해요.",
      typography_9_0_0:
        "그렇기 때문에 곱씹는 생각에서 빠져나올 필요가 있답니다.",
      typography_10_0_0:
        "우리는 생각에서 빠져나오기 위해서 ‘호흡 명상'을 활용할 거예요.",
      typography_11_0_0: "반추 멈추기",
      typography_12_0_0:
        "호흡 명상은 이전에도 가볍게 진행해 보신 적이 있을 거예요.",
      typography_13_0_0:
        "‘생각의 강’ 연습을 하면서 호흡에 초점을 뒀던 것이지요.",
      typography_14_0_0:
        "반추를 빠져나올 때는 정확히 동일한 방법을 활용할 거예요.",
      typography_15_0_0: "호흡에 닻을 두고 반추로부터 빠져나오는 것이지요.",
      typography_16_0_0:
        "이 방법의 목적은 ‘생각을 하나의 심리적 사건으로 바라보는 것'이 아니랍니다.",
      typography_17_0_0: "여기서는 그런 연습을 하려고 하지 않아도 괜찮아요.",
      typography_18_0_0:
        "그저 초점을 호흡에 두고 주의력을 온전히 호흡에만 기울이는 거예요.",
      typography_19_0_0:
        "물론 호흡에 집중하다 보면 또 나도 모르게 생각에 빠져버리는 순간이 있을 텐데요.",
      typography_20_0_0:
        "그런 순간을 알아차리면 자책하지 말고, 다시 차분히 호흡으로 주의력을 돌리면 됩니다.",
      typography_21_0_0:
        "이 연습은 호흡에 집중하는 ‘주의력 연습’이라고 생각하고 진행해도 좋아요.",
      typography_22_0_0:
        "호흡 명상을 통해 반추에서 빠져나온 이후에는 다른 활동으로 주의를 전환하는 것도 좋답니다.",
      typography_23_0_0:
        "내가 즐거움을 느끼는 활동(예: 커피 마시기, 노래 듣기, 책 읽기 등)이나",
      typography_24_0_0:
        "가벼운 성취감을 느끼는 활동(예: 설거지하기, 옷장 정리하기, 운동 등)을 이어서 하면 좋아요.",
      typography_25_0_0:
        "그렇게 하면 생각을 끊임없이 곱씹는 상황에서 빠져나올 수 있을 거랍니다.",
      typography_26_0_0:
        "자, 이제부터 <명상훈련지>에서 '호흡 명상'을 사용할 수 있을 거예요.",
      typography_27_0_0:
        "좀처럼 떨쳐지지 않는 생각 때문에 힘들 땐 호흡 명상을 활용해보세요.",
      typography_28_0_0: `일상 속에서든, 디스턴싱을 연습할 때든, 언제든 내가 원할 때 사용하면 된답니다.`,
    },
  },
};
