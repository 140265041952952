import { Box, Stack } from "@mui/joy";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CHAT_DOMAIN, CHAT_READ_MESSAGE_ENDPOINT, chatApi } from "api/chatApi";
import { PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT } from "api/patientApi";
import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import CoachingContent from "./CoachingContent";
import TaskHeader from "components/task/header/TaskHeader";
import { userAtom } from "../../../recoil/userAtom";

export default function Coaching() {
  const queryClient = useQueryClient();
  const [patientState, setPatientState] = useRecoilState(patientAtom);
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role === "coach";

  const messageReadQueryFn = useCallback(
    () => chatApi.read(`${patientState?.patientId}`),
    [patientState]
  );

  const { refetch: readMessage } = useQuery(
    [CHAT_DOMAIN, CHAT_READ_MESSAGE_ENDPOINT],
    messageReadQueryFn,
    {
      enabled: !!patientState?.patientId && isCoach,
      onSuccess: () => {
        queryClient.invalidateQueries([PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT]);
      },
    }
  );

  useEffect(() => {
    if (patientState?.patientId && isCoach) {
      // admin 은 읽기 처리안함
      readMessage();
    }
  }, [patientState?.patientId]);

  return (
    <>
      <Box
        sx={{
          flex: "none",
          zIndex: 100,
        }}
      >
        <TaskHeader taskKey={"chat"} />
      </Box>

      <Stack sx={{ flex: 1, overflow: "hidden" }}>
        <CoachingContent />
      </Stack>
    </>
  );
}