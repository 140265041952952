import React, { useEffect, useState } from "react";
import { Chip, Grid, Stack, Typography } from "@mui/joy";
import {
  AutomaticThought,
  AutomaticThoughtFieldMap,
  conceptApi,
} from "api/conceptApi";

import DeleteAutomaticThoughtButton from "components/coachRightSidebar/conceptNote/DeleteAutomaticThoughtButton";

import AutomaticThoughtInput from "./AutomaticThoughtInput";
import { useMutation } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import AutomaticThoughtConfirmButton from "./AutomaticThoughtConfirmButton";
import ResetAutomaticThoughtButton from "../ResetAutomaticThoughtButton";

interface AutomaticThoughtForValueListItemProps {
  automaticThought: AutomaticThought;
  onDelete?: (automaticThoughtId: string) => void;
  highlight?: boolean;
}

export default function AutomaticThoughtForValueListItem({
  automaticThought,
  onDelete,
  highlight = false,
}: AutomaticThoughtForValueListItemProps) {
  const [localData, setLocalData] =
    useState<AutomaticThought>(automaticThought);
  const [canBeConfirmed, setCanBeConfirmed] = useState<boolean>(false);

  const patientState = useRecoilValue(patientAtom);

  useEffect(() => {
    setLocalData(automaticThought);
  }, [automaticThought]);

  useEffect(() => {
    const validSituation = !!localData.situation?.trim();
    const validThoughtPositive = !!localData.thoughtPositive?.trim();
    const validValueDomain = !!localData.valueDomain?.trim();
    const validValue = !!localData.value?.trim();
    const validEmotion = !!localData.emotion?.trim();
    const validPhysicalResponse = !!localData.physicalResponse?.trim();
    const validBehavior = !!localData.behavior?.trim();

    setCanBeConfirmed(
      validSituation &&
        validThoughtPositive &&
        validValueDomain &&
        validValue &&
        validEmotion &&
        validPhysicalResponse &&
        validBehavior
    );
  }, [localData]);

  const { mutate: updateAutomaticThought } = useMutation<
    void,
    unknown,
    AutomaticThoughtFieldMap
  >(
    async (payload) => {
      await conceptApi.updateAutomaticThought(
        `${patientState?.patientId}`,
        automaticThought.automaticThoughtId,
        payload,
        true
      );
    },
    {
      onSuccess: (_data, variables) => {},
      onError: (error) => {
        console.error("Note update failed:", error);
      },
    }
  );

  const handleSaveSituation = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      situation: newValue,
    }));

    updateAutomaticThought({
      situation: newValue,
    });
  };

  const handleSaveThoughtPositive = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      thoughtPositive: newValue,
    }));

    updateAutomaticThought({
      thoughtPositive: newValue,
    });
  };

  const handleSaveValueDomain = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      valueDomain: newValue,
    }));

    updateAutomaticThought({
      valueDomain: newValue,
    });
  };

  const handleSaveValue = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      value: newValue,
    }));

    updateAutomaticThought({
      value: newValue,
    });
  };

  const handleSaveEmotion = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      emotion: newValue,
    }));

    updateAutomaticThought({
      emotion: newValue,
    });
  };

  const handleSavePhysicalResponse = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      physicalResponse: newValue,
    }));

    updateAutomaticThought({
      physicalResponse: newValue,
    });
  };

  const handleSaveBehavior = (newValue: string) => {
    setLocalData((prev) => ({
      ...prev,
      behavior: newValue,
    }));

    updateAutomaticThought({
      behavior: newValue,
    });
  };

  return (
    <Stack
      spacing={1}
      p={0.3}
      sx={{
        ...(highlight && {
          borderRadius: "4px",
          boxShadow: `0 0 0 1px rgba(15,85,167,0.3)`,
        }),
        backgroundColor: "unset",
        transition: "background-color 0.3s ease",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography level="body-xs">가치 {localData.sequence}</Typography>

          {(localData.automaticThoughtStatus === "note_confirmed" ||
            localData.automaticThoughtStatus === "confirmed") && (
            <>
              {localData.taskProgressStatus === "in_progress" ? (
                <Chip size="sm" color="success" variant="outlined">
                  진행 중
                </Chip>
              ) : localData.taskProgressStatus === "completed" ? (
                <Chip size="sm" color="neutral" variant="outlined">
                  완료
                </Chip>
              ) : (
                <Chip size="sm" color="warning" variant="outlined">
                  대기 중
                </Chip>
              )}
            </>
          )}
        </Stack>

        <Stack direction="row" spacing={1}>
          {localData.automaticThoughtStatus !== "preparing" && (
            <ResetAutomaticThoughtButton
              automaticThoughtId={localData.automaticThoughtId}
              thoughtRecordKey={localData.thoughtRecordKey}
              isForValue={true}
            />
          )}
          {localData.automaticThoughtStatus === "preparing" && onDelete && (
            <DeleteAutomaticThoughtButton
              automaticThoughtId={localData.automaticThoughtId}
              thoughtRecordKey={localData.thoughtRecordKey}
              isForValue={true}
            />
          )}
          {localData.automaticThoughtStatus === "preparing" && (
            <AutomaticThoughtConfirmButton
              automaticThoughtId={localData.automaticThoughtId}
              disabled={!canBeConfirmed}
              isForValue={true}
            />
          )}
        </Stack>
      </Stack>

      {/* 상황 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">상황</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.situation ?? ""}
            onSave={handleSaveSituation}
          />
        </Grid>
      </Grid>

      {/* 생각 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">생각</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            isForValue={true}
            initialValue={localData.thoughtPositive ?? ""}
            onSave={handleSaveThoughtPositive}
          />
        </Grid>
      </Grid>

      {/* 가치 영역 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">가치 영역</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="select"
            isForValue={true}
            initialValue={localData.valueDomain ?? ""}
            onSave={handleSaveValueDomain}
          />
        </Grid>
      </Grid>

      {/* 가치 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">가치</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.value ?? ""}
            onSave={handleSaveValue}
          />
        </Grid>
      </Grid>

      {/* 감정 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">감정</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.emotion ?? ""}
            onSave={handleSaveEmotion}
          />
        </Grid>
      </Grid>

      {/* 신체 반응 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">신체 반응</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.physicalResponse ?? ""}
            onSave={handleSavePhysicalResponse}
          />
        </Grid>
      </Grid>

      {/* 행동 */}
      <Grid container>
        <Grid xs={2.5} sx={{ pt: "10px" }}>
          <Typography level="body-xs">행동</Typography>
        </Grid>
        <Grid xs={9.5}>
          <AutomaticThoughtInput
            type="textarea"
            initialValue={localData.behavior ?? ""}
            onSave={handleSaveBehavior}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}