import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FullPageWithHeaderAndProgress from "pages/container/FullPageWithHeaderAndProgress";
import { Acquisition_1 } from "./part/acquisition/Acquisition_1";
import Habit_2_QnA from "./part/habit/Habit_2_QnA";
import Goal_2_MultiSelect_1 from "./part/goal/Goal_2_MultiSelect_1";
import Goal_1 from "./part/goal/Goal_1";
import Goal_3_MultiSelect_2 from "./part/goal/Goal_3_MultiSelect_2";
import Goal_4 from "./part/goal/Goal_4";
import Goal_5_MultiSelect_3 from "./part/goal/Goal_5_MultiSelect_3";
import Goal_6 from "./part/goal/Goal_6";
import Goal_7 from "./part/goal/Goal_7";
import Goal_8 from "./part/goal/Goal_8";
import Goal_9 from "./part/goal/Goal_9";
import Goal_10 from "./part/goal/Goal_10";
import Goal_11 from "./part/goal/Goal_11";
import Goal_12 from "./part/goal/Goal_12";
import Convention_1 from "./part/convention/Convention_1";
import Convention_2_QnA from "./part/convention/Convention_2_QnA";
import Convention_3 from "./part/convention/Convention_3";
import NicknameSubmit from "./part/NicknameSubmit";
import Habit_1 from "./part/habit/Habit_1";
import Habit_3 from "./part/habit/Habit_3";
import Habit_4 from "./part/habit/Habit_4";
import Habit_5 from "./part/habit/Habit_5";
import Motivation_1 from "./part/motivation/Motivation_1";
import Motivation_3 from "./part/motivation/Motivation_3";
import Motivation_2 from "./part/motivation/Motivation_2";
import Motivation_4 from "./part/motivation/Motivation_4";
import Motivation_6 from "./part/motivation/Motivation_6";
import Motivation_5 from "./part/motivation/Motivation_5";
import Motivation_7_MultiSelect from "./part/motivation/Motivation_7_MultiSelect";
import Motivation_8 from "./part/motivation/Motivation_8";
import Result_4 from "./part/result/Result_4";
import Result_1 from "./part/result/Result_1";
import FullPageWithCenterContentV2 from "../container/FullPageWithCenterContentV2";
import { theme } from "../../styles/theme";
import Result_2 from "./part/result/Result_2";
import Result_3 from "./part/result/Result_3";
import Acquisition_3 from "./part/acquisition/Acquisition_3";
import { useOnboardingProgress } from "../../hooks/useOnboardingProgress";
import PaymentContent3Month from "components/task/payment/PaymentContent3Month";
import useMixpanelWeb from "../../hooks/useMixpanelWeb";
import { OnboardingLoad } from "./part/load/OnboardingLoad";
import { userAtom } from "../../recoil/userAtom";
import { useRecoilValue } from "recoil";
import PaymentContentCompanyMember from "../../components/task/payment/PaymentContentCompanyMember";

interface OnboardingStep {
  type: string;
  seqByType: number;
  title?: string;
  hideHeader?: boolean;
  hideProgress?: boolean;
  progressDelta?: number;
  centerFullScreen?: boolean;
  backgroundColor?: string;
  highlightBackground?: boolean;
  content: React.ReactNode;
}

const steps: OnboardingStep[] = [
  {
    type: "load",
    seqByType: 1,
    hideHeader: true,
    hideProgress: true,
    content: <OnboardingLoad />,
  },
  {
    type: "acquisition",
    seqByType: 1,
    hideHeader: true,
    hideProgress: true,
    content: <Acquisition_1 />,
  },
  {
    type: "acquisition",
    seqByType: 3,
    hideHeader: true,
    hideProgress: true,
    content: <Acquisition_3 />,
  },
  {
    type: "goal",
    seqByType: 1,
    title: "고민과 목표",
    progressDelta: 1 / 7,
    content: <Goal_1 />,
  },
  {
    type: "goal",
    seqByType: 2,
    title: "고민과 목표",
    progressDelta: 2 / 7,
    content: <Goal_2_MultiSelect_1 />,
  },
  {
    type: "goal",
    seqByType: 3,
    title: "고민과 목표",
    progressDelta: 3 / 7,
    content: <Goal_3_MultiSelect_2 />,
  },
  {
    type: "goal",
    seqByType: 4,
    hideProgress: true,
    highlightBackground: true,
    content: <Goal_4 />,
  },
  {
    type: "goal",
    seqByType: 5,
    title: "고민과 목표",
    progressDelta: 4 / 7,
    content: <Goal_5_MultiSelect_3 />,
  },
  {
    type: "goal",
    seqByType: 6,
    title: "고민과 목표",
    progressDelta: 5 / 7,
    content: <Goal_6 />,
  },
  {
    type: "goal",
    seqByType: 7,
    hideProgress: true,
    highlightBackground: true,
    content: <Goal_7 />,
  },
  {
    type: "goal",
    seqByType: 8,
    hideProgress: true,
    highlightBackground: true,
    content: <Goal_8 />,
  },
  {
    type: "goal",
    seqByType: 9,
    title: "고민과 목표",
    progressDelta: 6 / 7,
    content: <Goal_9 />,
  },
  {
    type: "goal",
    seqByType: 10,
    hideProgress: true,
    content: <Goal_10 />,
  },
  {
    type: "goal",
    seqByType: 11,
    hideHeader: true,
    hideProgress: true,
    content: <Goal_11 />,
  },
  {
    type: "goal",
    seqByType: 12,
    hideHeader: true,
    hideProgress: true,
    highlightBackground: true,
    content: <Goal_12 />,
  },
  {
    type: "convention",
    seqByType: 1,
    title: "인식과 기대",
    progressDelta: 1 / 12,
    content: <Convention_1 />,
  },
  {
    type: "convention",
    seqByType: 2,
    title: "인식과 기대",
    progressDelta: 1 / 12,
    content: <Convention_2_QnA />,
  },
  {
    type: "convention",
    seqByType: 3,
    hideProgress: true,
    highlightBackground: true,
    content: <Convention_3 />,
  },
  {
    type: "nickname",
    seqByType: 1,
    hideHeader: true,
    hideProgress: true,
    content: <NicknameSubmit />,
  },
  {
    type: "habit",
    seqByType: 1,
    title: "습관과 노력",
    progressDelta: 1 / 7,
    content: <Habit_1 />,
  },
  {
    type: "habit",
    seqByType: 2,
    title: "습관과 노력",
    progressDelta: 1 / 7,
    content: <Habit_2_QnA />,
  },
  {
    type: "habit",
    seqByType: 3,
    hideProgress: true,
    content: <Habit_3 />,
  },
  {
    type: "habit",
    seqByType: 4,
    hideHeader: true,
    hideProgress: true,
    content: <Habit_4 />,
  },
  {
    type: "habit",
    seqByType: 5,
    hideHeader: true,
    hideProgress: true,
    highlightBackground: true,
    content: <Habit_5 />,
  },
  {
    type: "motivation",
    seqByType: 1,
    title: "의지와 동기부여",
    progressDelta: 1 / 6,
    content: <Motivation_1 />,
  },
  {
    type: "motivation",
    seqByType: 2,
    title: "의지와 동기부여",
    progressDelta: 2 / 6,
    content: <Motivation_2 />,
  },
  {
    type: "motivation",
    seqByType: 3,
    title: "의지와 동기부여",
    progressDelta: 3 / 6,
    content: <Motivation_3 />,
  },
  {
    type: "motivation",
    seqByType: 4,
    hideProgress: true,
    highlightBackground: true,
    content: <Motivation_4 />,
  },
  {
    type: "motivation",
    seqByType: 5,
    title: "의지와 동기부여",
    progressDelta: 4 / 6,
    content: <Motivation_5 />,
  },
  {
    type: "motivation",
    seqByType: 6,
    hideProgress: true,
    highlightBackground: true,
    content: <Motivation_6 />,
  },
  {
    type: "motivation",
    seqByType: 7,
    progressDelta: 5 / 6,
    content: <Motivation_7_MultiSelect hideNextButton={false} />,
  },
  {
    type: "motivation",
    seqByType: 8,
    hideHeader: true,
    hideProgress: true,
    highlightBackground: true,
    content: <Motivation_8 />,
  },
  {
    type: "result",
    seqByType: 1,
    hideProgress: true,
    content: <Result_1 />,
  },
  {
    type: "result",
    seqByType: 2,
    centerFullScreen: true,
    backgroundColor: theme.vars.palette.Surface.Highlight,
    content: <Result_2 />,
  },
  {
    type: "result",
    seqByType: 3,
    centerFullScreen: true,
    backgroundColor: theme.vars.palette.Background.SoftHighlight,
    content: <Result_3 />,
  },
  {
    type: "result",
    seqByType: 4,
    hideHeader: true,
    hideProgress: true,
    content: <Result_4 />,
  },
  {
    type: "payment",
    seqByType: 1,
    hideHeader: true,
    hideProgress: true,
    content: <></>,
  },
];

function findIndexByStepInfo(type: string, seqByType: number): number {
  return steps.findIndex(
    (step) => step.type === type && step.seqByType === seqByType
  );
}

export function findStep(type: string, seqByType: number): OnboardingStep {
  return steps[findIndexByStepInfo(type, seqByType)];
}

export default function OnboardingScreen() {
  const { type, id } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const numId = parseInt(id || "0");
  const questionNumber = searchParams.get("q");

  const currentStepIndex = Math.min(
    Math.max(findIndexByStepInfo(type!!, numId), 0),
    steps.length - 1
  ); // 유효한 범위 내로 제한

  const user = useRecoilValue(userAtom);

  const currentStep = steps[currentStepIndex];

  const handleBack = () => {
    if (currentStepIndex <= 0) return;

    if (
      (currentStep.type === "convention" || currentStep.type === "habit") &&
      currentStep.seqByType === 2
    ) {
      if (questionNumber !== undefined && parseInt(questionNumber!!) > 1) {
        const prevQuestion = parseInt(questionNumber!!) - 1;
        navigate(
          `/onboarding_new/${currentStep.type}/${currentStep.seqByType}?q=${prevQuestion}`
        );
      } else {
        const prevStep = steps[currentStepIndex - 1];
        navigate(`/onboarding_new/${prevStep.type}/${prevStep.seqByType}`);
      }
      return;
    }

    if (currentStep.type === "convention" && currentStep.seqByType === 3) {
      navigate(
        `/onboarding_new/${currentStep.type}/${currentStep.seqByType - 1}?q=10`
      );
      return;
    }

    if (currentStep.type === "habit" && currentStep.seqByType === 3) {
      navigate(
        `/onboarding_new/${currentStep.type}/${currentStep.seqByType - 1}?q=5`
      );
      return;
    }

    const prevStep = steps[currentStepIndex - 1];
    navigate(`/onboarding_new/${prevStep.type}/${prevStep.seqByType}`);
  };

  const { progress, setProgress } = useOnboardingProgress();
  useEffect(() => {
    if (currentStep.progressDelta) {
      let ratio = 1;
      if (questionNumber !== undefined && parseInt(questionNumber!!) > 0) {
        ratio = parseInt(questionNumber!!);
      }

      if (currentStep.type === "goal") {
        setProgress(25 * currentStep.progressDelta * ratio);
      } else if (currentStep.type === "convention") {
        setProgress(25 + 25 * currentStep.progressDelta * ratio);
      } else if (currentStep.type === "habit") {
        setProgress(50 + 25 * currentStep.progressDelta * ratio);
      } else if (currentStep.type === "motivation") {
        let progress = 75 + 25 * currentStep.progressDelta * ratio;
        if (progress > 95) {
          progress = 100;
        }
        setProgress(progress);
      }
    }
  }, [currentStep, questionNumber]);

  const setMixPanel = useMixpanelWeb({
    eventName: "온보딩",
    params: {
      type: `${currentStep.type}`,
      pageId: `${currentStep.seqByType}`,
    },
  });

  useEffect(() => {
    setMixPanel();
  }, [id]);

  if (currentStep.type === "payment") {
    return user?.benefit === "기업 제휴" ? (
      <div className="w-full h-full overflow-x-hidden">
        <PaymentContentCompanyMember />
      </div>
    ) : (
      <div className="w-full h-full overflow-x-hidden">
        <PaymentContent3Month fullWidth={true} />
      </div>
    );
  }

  return currentStep.centerFullScreen === true ? (
    <FullPageWithCenterContentV2
      content={currentStep.content}
      backgroundColor={currentStep.backgroundColor}
    />
  ) : (
    <FullPageWithHeaderAndProgress
      headerTitle={currentStep.title}
      hideHeader={currentStep.hideHeader}
      hideProgress={currentStep.hideProgress}
      body={currentStep.content}
      highlightBackground={currentStep.highlightBackground}
      onBack={currentStepIndex > 0 ? handleBack : undefined} // 첫 번째 단계에서는 뒤로가기 버튼 숨김
    />
  );
}