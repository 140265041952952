import {
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../../BlockComponent";
import { MyGoalLine } from "../common";

export const Content_0_3_A = (isNotPraisedYet: boolean, hasGoal: boolean) => [
  Instruction("typography_0_0_0"),

  ...(hasGoal
    ? [
        InstructionWithContent("typography_goal_0", MyGoalLine(), {
          blockId: "현재 목표",
        }),
        InstructionWithButtonGroup(
          `typography_goal_1`,
          [
            {
              translationKey: "네",
              showFromId: "동의",
              hideFromId: "비동의",
            },
            {
              translationKey: "아니요",
              showFromId: "비동의",
              hideFromId: "동의",
            },
          ],
          undefined,
          { blockId: "수정 불필요" }
        ),
        InstructionWithTextarea(`typography_goal_8`, undefined, {
          blockId: "비동의",
        }),
        Instruction("typography_goal_9", {
          blockId: "비동의",
          nextBlockId: "설문"
        }),
        InstructionWithButtonGroup(
          `typography_goal_2`,
          [
            {
              translationKey: "아직 많이 멀어요",
              showFromId: "멂",
              hideFromId: "가까움",
            },
            {
              translationKey: "조금 멀게 느껴져요",
              showFromId: "멂",
              hideFromId: "가까움",
            },
            {
              translationKey: "조금 가깝게 느껴져요",
              showFromId: "가까움",
              hideFromId: "멂",
            },
            {
              translationKey: "이제 꽤 가까워요",
              showFromId: "가까움",
              hideFromId: "멂",
            },
          ],
          undefined,
          { blockId: "동의" }
        ),
        InstructionWithTextarea(`typography_goal_3`, undefined, {
          blockId: "멂",
          nextBlockId: "설문",
        }),
        InstructionWithTextarea(`typography_goal_4`, undefined, {
          blockId: "가까움",
        }),
        InstructionWithButtonGroup(`typography_goal_5`, [
          {
            translationKey: "아직 아니에요",
            showFromId: "도움 필요",
            hideFromId: "도움 불필요",
          },
          {
            translationKey: "할 수 있을 것 같아요",
            showFromId: "도움 불필요",
            hideFromId: "도움 필요",
          },
        ]),
        InstructionWithTextarea(`typography_goal_6`, undefined, {
          blockId: "도움 필요",
          nextBlockId: "설문",
        }),
        InstructionWithTextarea(`typography_goal_7`, undefined, {
          blockId: "도움 불필요",
        }),
      ]
    : []),

  InstructionWithTextarea(`typography_1_0_0`, { id: "satisfiedPart" }, {blockId: "설문"}),
  InstructionWithTextarea(`typography_2_0_0`, { id: "unsatisfiedPart" }),
  InstructionWithTextarea(`typography_3_0_0`, { id: "suggestion" }),

  InstructionWithButtonGroup(
    "typography_4_0_0",
    [
      {
        translationKey: "매우 아쉬울 것 같아요",
        ...(isNotPraisedYet && { showFromId: "리뷰 요청" }),
      },
      {
        translationKey: "조금 아쉬울 것 같아요",
        ...(isNotPraisedYet && {
          hideFromId: "리뷰 요청",
          showFromId: "마무리",
        }),
      },
      {
        translationKey: "전혀 아쉽지 않을 것 같아요",
        ...(isNotPraisedYet && {
          hideFromId: "리뷰 요청",
          showFromId: "마무리",
        }),
      },
      {
        translationKey: "지금은 사용하고 있지 않아 해당 사항이 없어요",
        ...(isNotPraisedYet && {
          hideFromId: "리뷰 요청",
          showFromId: "마무리",
        }),
      },
    ],
    { id: "feelingWhenTermination" }
  ),
  ...(isNotPraisedYet
    ? [
        Instruction("typography_4_0_0_1", { blockId: "리뷰 요청" }),
        Instruction("typography_4_0_0_2"),
        InstructionWithContent("typography_4_0_0_3", [
          [
            {
              type: "buttonLink",
              content: {
                text: "더 알아보기",
                url: "https://orwell.distancing.im/review-event",
              },
            },
          ],
        ]),
        InstructionWithContent("typography_4_0_0_4", [
          [{ type: "storeReview", content: {} }],
        ]),
        InstructionWithContent("typography_4_0_0_5", [
          [
            {
              type: "buttonLink",
              content: {
                text: "카카오톡 채널 바로가기",
                url: "http://pf.kakao.com/_xjxfLVG",
              },
            },
          ],
        ]),
      ]
    : []),
  Instruction("typography_5_0_0", { blockId: "마무리" }),
];
