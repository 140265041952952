import { thinkingTrapResource } from "translation/thinkingTrap";
import { thoughtmapResource } from "translation/thoughtmap";

export const translation_2_2_T = {
  en: {
    v1: {
      ...thinkingTrapResource.en,
      "책임 과다 - 나의 기여를 과장함":
        "Extra Blame - Overestimating my contribution",
      "근거 부족 - 부족한 근거로 추론함":
        "Little Evidence - Guessing without sufficient reasons",
      "부정 편향 - 나쁜 전개만을 예상함":
        "Negative Bias - Expecting only the bad and worst",
      "평가 - 가치 판단을 내림": "Judgment - Deciding what's good or bad",
      "개념화 - 이유를 대며 정당화함":
        "Conceptualization - Finding reasons to justify",
      "반추 - 과거나 미래를 곱씹음":
        "Rumination - Stuck on past or future thoughts",
      "잘 모르겠어요. 코치 선생님의 도움이 필요해요.":
        "I'm not sure. Please help.",
      "5분": "5 min",
      "15분": "15 min",
      없음: "None",
      initial_typography_0_0_0:
        "Let's take a closer look at a specific moment when the concern below troubled you.",
      initial_typography_1_0_0:
        "Think about a recent time when this concern caused unpleasant emotions or physical sensations, or led to actions you didn't want.",
      typography_0_0_0: "When did it happen?",
      typography_1_0_0:
        "[Situation] Please write down the objective situation, excluding emotions and judgments.",
      typography_2_0_0:
        "[Thought] Write down your raw thoughts at that moment, like a monologue.",
      typography_3_0_0:
        "[Reaction] Did you feel any unpleasant emotions or physical sensations, or did it lead to unwanted actions?",
      initial_typography_2_0_0:
        "Great. Whenever you have a negative experience in the future, please write it down just like this. It's okay if you repeat similar content. It can actually help in identifying thought patterns.",
      initial_typography_3_0_0:
        "And remember, it doesn't always have to be about the concern you initially told me about. Feel free to write about any new issues that come to mind.",
      initial_typography_4_0_0:
        "We'll be focusing on the <Thought Log> as our main tool for distancing practice.",
      initial_typography_5_0_0:
        "By filling out the log, you'll discover patterns in your thoughts and practice distancing yourself from them.",
      initial_typography_6_0_0:
        "The <Thought Log> will also get upgraded as you learn new things.",
      initial_typography_7_0_0:
        "By consistently using the evolving <Thought Log>, you'll gradually get used to distancing yourself from your thoughts.",
      initial_typography_8_0_0:
        "So, whenever negative thoughts or emotions arise, pull out the <Thought Log> and record them.",
      initial_typography_9_0_0:
        "And after you've finished recording, always tap [Finish].",
      upgrade1_typography_0_0_0: `Stepping back from thoughts`,
      upgrade1_typography_1_0_0: `Let's practice stepping back from our thoughts.`,
      upgrade1_typography_2_0_0: `Think of someone close to you - a friend or family member. What kind of thoughts might they have in the same situation?`,
      upgrade1_typography_3_0_0: `Now, think about your own thoughts. Do they bring any benefits or drawbacks when you react to them?`,
      upgrade1_typography_4_0_0: `Do you see any traps in your thinking?`,
      upgrade1_typography_5_0_0: `Alright, I'll review this and let you know.`,

      upgrade1_1_typography_0_0_0: `Write down what else might be causing your situation.`,
      upgrade1_1_typography_1_0_0: `How can you see your contribution in things differently when you think about these other factors?`,

      upgrade1_2_typography_0_0_0: `What shows your thoughts are true?`,
      upgrade1_2_typography_1_0_0: `What shows your thoughts are not true?`,
      upgrade1_2_typography_2_0_0: `When you look at both sides, how could you rethink your conclusion?`,

      upgrade1_3_typography_0_0_0: `What else could happen?`,
      upgrade1_3_typography_1_0_0: `How would you handle the worst?`,
      upgrade1_3_typography_2_0_0: `Thinking about other possibilities and ways to deal with them, how could you rethink your worries?`,

      upgrade1_4_typography_0_0_0: `In your experience, how do you tell apart descriptions and judgments of it?`,
      upgrade1_4_typography_1_0_0: `Are the judgments you wrote about really part of who you are?`,
      upgrade1_4_typography_2_0_0: `If you accept these judgments, how might they limit your thoughts or actions?`,
      upgrade1_4_typography_3_0_0: `Thinking about their effects, how should you view your judgments differently?`,

      upgrade1_5_typography_0_0_0: `Why do you think your experience happened?`,
      upgrade1_5_typography_1_0_0: `If you keep thinking this way, how might it limit your future thoughts and actions?`,
      upgrade1_5_typography_2_0_0: `Considering their effects, how should you see yourself differently?`,

      upgrade1_6_typography_0_0_0: `Let's look at whether thinking over the past or future is useful.`,
      upgrade1_6_typography_1_0_0: `Are these thoughts goal-oriented, or just wandering?`,
      upgrade1_6_typography_2_0_0: `Did they help solve a real problem or make you feel a bit better?`,
      upgrade1_6_typography_3_0_0: `Considering how much they help, how should you view these thoughts about the past or future?`,

      upgrade2_typography_0_0_0: `This time, let's try embracing the experience with the Stream of Thoughts training. How much time do you have right now?`,
      upgrade2_typography_1_0_0: `Once ready, start the training following the audio guide.`,

      wrapup_typographyFromData_0_0_0: `Okay. Now that you've stepped back from your thoughts, how much are they affecting you? Before, you said it was $A$.`,
      last_typography_0_0_0: "You've completed the entry. Please tap [Finish].",
    },
    v2: {
      ...thinkingTrapResource.en,
      ...thoughtmapResource.en,
      "책임 과다 - 나의 기여를 과장함":
        "Extra Blame - Overestimating my contribution",
      "근거 부족 - 부족한 근거로 추론함":
        "Little Evidence - Guessing without sufficient reasons",
      "부정 편향 - 나쁜 전개만을 예상함":
        "Negative Bias - Expecting only the bad and worst",
      "평가 - 가치 판단을 내림": "Judgment - Deciding what's good or bad",
      "개념화 - 이유를 대며 정당화함":
        "Conceptualization - Finding reasons to justify",
      "반추 - 과거나 미래를 곱씹음":
        "Rumination - Stuck on past or future thoughts",
      "잘 모르겠어요. 코치 선생님의 도움이 필요해요.":
        "I'm not sure. Please help.",
      "새로운 함정인 것 같아요. 도와주세요.":
        "It seems like a new trap. Please help.",
      "5분": "5 min",
      "15분": "15 min",
      없음: "None",
      initial_typography_0_0_0:
        "Let's take a closer look at a specific moment when the concern below troubled you.",
      initial_typography_1_0_0:
        "Think about a recent time when this concern caused unpleasant emotions or physical sensations, or led to actions you didn't want.",
      typography_0_0_0: "When did it happen?",
      typography_1_0_0:
        "[Situation] Please write down the objective situation, excluding emotions and judgments.",
      typography_2_0_0:
        "[Thought] Write down your raw thoughts at that moment, like a monologue.",
      typography_3_0_0:
        "[Reaction] Did you feel any unpleasant emotions or physical sensations, or did it lead to unwanted actions?",
      initial_typography_2_0_0:
        "Great. Whenever you have a negative experience in the future, please write it down just like this. It's okay if you repeat similar content. It can actually help in identifying thought patterns.",
      initial_typography_3_0_0:
        "And remember, it doesn't always have to be about the concern you initially told me about. Feel free to write about any new issues that come to mind.",
      initial_typography_4_0_0:
        "We'll be focusing on the <Thought Log> as our main tool for distancing practice.",
      initial_typography_5_0_0:
        "By filling out the log, you'll discover patterns in your thoughts and practice distancing yourself from them.",
      initial_typography_6_0_0:
        "The <Thought Log> will also get upgraded as you learn new things.",
      initial_typography_7_0_0:
        "By consistently using the evolving <Thought Log>, you'll gradually get used to distancing yourself from your thoughts.",
      initial_typography_8_0_0:
        "So, whenever negative thoughts or emotions arise, pull out the <Thought Log> and record them.",
      initial_typography_9_0_0:
        "And after you've finished recording, always tap [Finish].",
      upgrade1_typography_0_0_0: `Stepping back from thoughts`,
      upgrade1_typography_1_0_0: `Let's practice stepping back from our thoughts.`,
      upgrade1_typography_2_0_0: `Think of someone close to you - a friend or family member. What kind of thoughts might they have in the same situation?`,
      upgrade1_typography_3_0_0: `Now, think about your own thoughts. Do they bring any benefits or drawbacks when you react to them?`,
      upgrade1_typography_4_0_0: `Do you see any traps in your thinking? Are there any we have discussed before?`,
      upgrade1_typography_5_0_0: `Alright, I'll review this and let you know.`,

      upgrade1_1_typography_0_0_0: `Write down what else might be causing your situation.`,
      upgrade1_1_typography_1_0_0: `How can you see your contribution in things differently when you think about these other factors?`,

      upgrade1_2_typography_0_0_0: `What shows your thoughts are true or not true?`,
      upgrade1_2_typography_1_0_0: `When you look at both sides, how could you rethink your conclusion?`,

      upgrade1_3_typography_0_0_0: `What else could happen?`,
      upgrade1_3_typography_1_0_0: `How would you handle the worst?`,
      upgrade1_3_typography_2_0_0: `Thinking about other possibilities and ways to deal with them, how could you rethink your worries?`,

      upgrade1_4_typography_0_0_0: `In your experience, how do you tell apart descriptions and judgments of it?`,
      upgrade1_4_typography_1_0_0: `Are the judgments you wrote about really part of who you are?`,
      upgrade1_4_typography_2_0_0: `If you accept these judgments, how might they limit your thoughts or actions?`,
      upgrade1_4_typography_3_0_0: `Thinking about their effects, how should you view your judgments differently?`,

      upgrade1_5_typography_0_0_0: `Why do you think your experience happened?`,
      upgrade1_5_typography_1_0_0: `If you keep thinking this way, how might it limit your future thoughts and actions?`,
      upgrade1_5_typography_2_0_0: `Considering their effects, how should you see yourself differently?`,

      upgrade1_6_typography_0_0_0: `Let's look at whether thinking over the past or future is useful.`,
      upgrade1_6_typography_1_0_0: `Are these thoughts goal-oriented, or just wandering?`,
      upgrade1_6_typography_2_0_0: `Did they help solve a real problem or make you feel a bit better?`,
      upgrade1_6_typography_3_0_0: `Considering how much they help, how should you view these thoughts about the past or future?`,

      upgrade2_typography_0_0_0: `This time, let's try embracing the experience with the Stream of Thoughts training. How much time do you have right now?`,
      upgrade2_typography_1_0_0: `Once ready, start the training following the audio guide.`,

      upgrade3_typography_0_0_0: `Understanding myself`,
      upgrade3_typography_1_0_0: `Okay, let's pause and look at my core belief.`,
      upgrade3_typography_2_0_0: `Does the thought trap "$A$" make me ignore stuff that doesn't fit with my core belief and only pick up on things that do? How does it actually do that?`,
      upgrade3_typography_3_0_0: `Now, let's briefly revisit my log.`,
      upgrade3_typography_4_0_0: `What intermediate belief have I used to deal with my core belief?`,
      upgrade3_typography_5_0_0: `Does the intermediate belief "$A$" make my core belief stronger? If yes, how does it make the core belief even stronger?`,
      upgrade3_typography_6_0_0: `So, thinking about it all, do the intermediate belief and thought trap help me step back from my core belief, or do they keep me stuck in it?`,

      wrapup_typographyFromData_0_0_0: `Okay. Now that you've stepped back from your thoughts, how much are they affecting you? Before, you said it was $A$.`,
      last_typography_0_0_0: "You've completed the entry. Please tap [Finish].",
    },
  },
  ko: {
    v1: {
      ...thinkingTrapResource.ko,
      "책임 과다 - 나의 기여를 과장함": "책임 과다 - 나의 기여를 과장함",
      "근거 부족 - 부족한 근거로 추론함": "근거 부족 - 부족한 근거로 추론함",
      "부정 편향 - 나쁜 전개만을 예상함": "부정 편향 - 나쁜 전개만을 예상함",
      "평가 - 가치 판단을 내림": "평가 - 가치 판단을 내림",
      "개념화 - 이유를 대며 정당화함": "개념화 - 이유를 대며 정당화함",
      "반추 - 과거나 미래를 곱씹음": "반추 - 과거나 미래를 곱씹음",
      "잘 모르겠어요. 코치 선생님의 도움이 필요해요.":
        "잘 모르겠어요. 코치 선생님의 도움이 필요해요.",
      "5분": "5분",
      "15분": "15분",
      없음: "없음",

      initial_typography_0_0_0:
        "아래 고민이 나를 괴롭혔던 순간을 구체적으로 살펴볼 거예요.",
      initial_typography_1_0_0:
        "최근에 위의 고민으로 불쾌한 감정 또는 신체 감각이 느껴지거나, 원치 않는 행동으로 이어진 경험을 떠올려 보세요.",
      typography_0_0_0: "언제 있었던 일인가요?",
      typography_1_0_0:
        "[상황] 감정과 판단을 배제한 객관적인 상황을 적어주세요.",
      typography_2_0_0:
        "[생각] 그 순간에 들었던 날것의 생각을 독백 형태로 적어주세요.",
      typography_3_0_0:
        "[반응] 불쾌한 감정 또는 신체 감각이 느껴지거나, 원치 않는 행동으로 이어졌나요?",
      initial_typography_2_0_0:
        "좋아요. 앞으로 언제든 부정적인 경험을 한다면 지금과 같이 적어주세요. 비슷한 내용이 반복해서 적혀도 괜찮답니다. 오히려 생각의 패턴을 발견하는 데에 도움이 될 거예요.",
      initial_typography_3_0_0:
        "그리고 꼭 처음에 제게 말씀주신 고민에 해당되는 내용일 필요도 없어요. 새롭게 떠오르는 문제일지라도 언제든 적어주세요.",
      initial_typography_4_0_0:
        "우리는 앞으로 <생각기록지>를 중심으로 거리두기 연습을 진행할 거랍니다.",
      initial_typography_5_0_0:
        "기록지를 작성하며 발견되는 생각의 패턴들을 찾고, 그 생각들로부터 거리두는 연습을 할 거예요.",
      initial_typography_6_0_0:
        "새로운 내용들을 배울 때면 <생각기록지>가 업그레이드되기도 할 거랍니다.",
      initial_typography_7_0_0:
        "조금씩 발전하는 <생각기록지>를 꾸준히 활용하며 점차 생각과 거리를 두는 일이 익숙해질 거예요.",
      initial_typography_8_0_0:
        "그러니 앞으로 부정적인 생각이나 감정이 들 때 <생각기록지>를 꺼내어 기록해보세요.",
      initial_typography_9_0_0:
        "그리고 기록 후에는 항상 [마치기]를 눌러주시고요.",

      upgrade1_typography_0_0_0: `생각과 거리두기`,
      upgrade1_typography_1_0_0: `이제 생각과 거리를 두어볼게요.`,
      upgrade1_typography_2_0_0: `내가 아끼는 친구나 가족을 떠올려보세요. 그 사람은 같은 상황에서 어떠한 생각이 마음속에 떠오를 것 같나요?`,
      upgrade1_typography_3_0_0: `좋아요. 아래 내 마음속에 떠오른 생각에 반응하면 무엇이 좋고, 무엇이 나쁠까요?`,
      upgrade1_typography_4_0_0: `내 생각에 어떤 생각함정이 있는 것 같나요?`,
      upgrade1_typography_5_0_0: `좋아요. 제가 살펴보고 다시 말씀드릴게요.`,

      upgrade1_1_typography_0_0_0: `아래 상황을 만드는 데에 기여한 다른 요인을 적어보세요.`,
      upgrade1_1_typography_1_0_0: `다른 요인을 함께 고려해보면, 내가 과장했던 나의 기여분을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_2_typography_0_0_0: `아래 생각이 사실이라는 것을 말해주는 근거에는 무엇이 있을까요?`,
      upgrade1_2_typography_1_0_0: `반대로 사실이 아님을 말해주는 증거에는 무엇이 있을까요?`,
      upgrade1_2_typography_2_0_0: `양쪽의 증거를 고려해보면, 내가 내렸던 결론을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_3_typography_0_0_0: `발생할 수 있는 다른 가능성들에는 무엇이 있을까요?`,
      upgrade1_3_typography_1_0_0: `만약 최악의 일이 발생하면 어떻게 대처할 수 있을까요?`,
      upgrade1_3_typography_2_0_0: `다른 가능성과 대처 전략을 고려해보면, 내가 했던 부정적인 예측을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_4_typography_0_0_0: `아래 나의 경험에서 기술과 평가를 구분하면 어떻게 될까요?`,
      upgrade1_4_typography_1_0_0: `위에 적은 평가는 나와 동일하다고, 또는 나와 분리할 수 없는 절대적인 특성이라고 이야기할 수 있나요?`,
      upgrade1_4_typography_2_0_0: `위 평가를 그대로 받아들이면, 나의 생각이나 행동에는 어떤 제약이 발생하나요?`,
      upgrade1_4_typography_3_0_0: `평가와 그것이 미칠 영향을 고려해보면, 내가 했던 가치 판단을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade1_5_typography_0_0_0: `아래 나의 경험을 정당화하는 이유를 찾아보면 어떻게 될까요?`,
      upgrade1_5_typography_1_0_0: `앞으로 위에 적은 개념화의 틀 안에서 살아간다면 이후 나의 생각과 행동은 어떠한 제한을 받게 될까요?`,
      upgrade1_5_typography_2_0_0: `개념화와 그것이 미칠 영향을 고려해보면, 자신에 대한 시각을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade1_6_typography_0_0_0: `아래 과거나 미래를 곱씹는 생각이 내게 도움이 되는지 살펴볼게요.`,
      upgrade1_6_typography_1_0_0: `위의 생각은 명확한 목표를 가지고 과거나 미래를 탐색하고 있나요? 아니면 그저 뚜렷한 목표 없이 과거나 미래를 헤매고 있나요?`,
      upgrade1_6_typography_2_0_0: `위의 생각을 통해 현실의 문제를 해결할 수 있는 좋은 방법이 떠올랐나요? 혹은 위의 생각을 통해 감정이 조금이라도 나아졌나요?`,
      upgrade1_6_typography_3_0_0: `반추와 그것이 미친 영향을 고려해보면, 과거나 미래를 곱씹는 생각을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade2_typography_0_0_0: `이번엔 생각의 강 훈련으로 기꺼이 느껴보려 해요. 지금 어느 정도 시간이 있을까요?`,
      upgrade2_typography_1_0_0: `준비됐다면 음성 안내에 따라 훈련을 시작하세요.`,

      wrapup_typographyFromData_0_0_0: `좋아요. 생각과 거리를 둔 지금, 떠오른 생각에 얼마나 영향받고 있나요? 거리두기 전에는 $A$점이라고 하셨어요.`,
      last_typography_0_0_0: "기록을 마쳤어요. [마치기]를 눌러주세요.",
    },
    v2: {
      ...thinkingTrapResource.ko,
      ...thoughtmapResource.ko,
      "책임 과다 - 나의 기여를 과장함": "책임 과다 - 나의 기여를 과장함",
      "근거 부족 - 부족한 근거로 추론함": "근거 부족 - 부족한 근거로 추론함",
      "부정 편향 - 나쁜 전개만을 예상함": "부정 편향 - 나쁜 전개만을 예상함",
      "평가 - 가치 판단을 내림": "평가 - 가치 판단을 내림",
      "개념화 - 이유를 대며 정당화함": "개념화 - 이유를 대며 정당화함",
      "반추 - 과거나 미래를 곱씹음": "반추 - 과거나 미래를 곱씹음",
      "잘 모르겠어요. 코치 선생님의 도움이 필요해요.":
        "잘 모르겠어요. 코치 선생님의 도움이 필요해요.",
      "새로운 함정인 것 같아요. 도와주세요.":
        "새로운 함정인 것 같아요. 도와주세요.",
      "5분": "5분",
      "10분": "10분",
      "15분": "15분",
      없음: "없음",

      initial_typography_0_0_0:
        "아래 고민이 나를 괴롭혔던 순간을 구체적으로 살펴볼 거예요.",
      initial_typography_1_0_0:
        "최근에 위의 고민으로 불쾌한 감정 또는 신체 감각이 느껴지거나, 원치 않는 행동으로 이어진 경험을 떠올려 보세요.",
      initial_typography_0_0_0_1:
        "지금까지 말씀주신 고민이 나를 괴롭혔던 순간을 구체적으로 살펴볼 거예요.",
      initial_typography_1_0_0_1:
        "최근에 <나의 고민과 목표>에서 말씀주신 고민으로 불쾌한 감정 또는 신체 감각이 느껴지거나, 원치 않는 행동으로 이어진 경험을 떠올려 보세요.",
      typography_0_0_0: "언제 있었던 일인가요?",
      typography_1_0_0:
        "[상황] 감정과 판단을 배제한 객관적인 상황을 적어주세요.",
      typography_2_0_0:
        "[생각] 그 순간에 들었던 날것의 생각을 독백 형태로 적어주세요.",
      typography_3_0_0:
        "[반응] 불쾌한 감정 또는 신체 감각이 느껴지거나, 원치 않는 행동으로 이어졌나요?",
      initial_typography_2_0_0:
        "좋아요. 앞으로 언제든 부정적인 경험을 한다면 지금과 같이 적어주세요. 비슷한 내용이 반복해서 적혀도 괜찮답니다. 오히려 생각의 패턴을 발견하는 데에 도움이 될 거예요.",
      initial_typography_3_0_0:
        "그리고 꼭 처음에 제게 말씀주신 고민에 해당되는 내용일 필요도 없어요. 새롭게 떠오르는 문제일지라도 언제든 적어주세요.",
      initial_typography_4_0_0:
        "우리는 앞으로 <생각기록지>를 중심으로 거리두기 연습을 진행할 거랍니다.",
      initial_typography_5_0_0:
        "기록지를 작성하며 발견되는 생각의 패턴들을 찾고, 그 생각들로부터 거리두는 연습을 할 거예요.",
      initial_typography_6_0_0:
        "새로운 내용들을 배울 때면 <생각기록지>가 업그레이드되기도 할 거랍니다.",
      initial_typography_7_0_0:
        "조금씩 발전하는 <생각기록지>를 꾸준히 활용하며 점차 생각과 거리를 두는 일이 익숙해질 거예요.",
      initial_typography_8_0_0:
        "그러니 앞으로 부정적인 생각이나 감정이 들 때 <생각기록지>를 꺼내어 기록해보세요.",
      initial_typography_9_0_0:
        "그리고 기록 후에는 항상 [마치기]를 눌러주시고요.",

      upgrade1_typography_0_0_0: `생각과 거리두기`,
      upgrade1_typography_1_0_0: `이제 생각과 거리를 두어볼게요.`,
      upgrade1_typography_2_0_0: `내가 아끼는 친구나 가족을 떠올려보세요. 그 사람은 같은 상황에서 어떠한 생각이 마음속에 떠오를 것 같나요?`,
      upgrade1_typography_3_0_0: `좋아요. 아래 내 마음속에 떠오른 생각에 반응하면 무엇이 좋고, 무엇이 나쁠까요?`,
      upgrade1_typography_4_0_0: `내 생각에는 어떤 생각함정이 있는 것 같나요? 여태 다뤄본 생각함정 중에 있나요?`,
      upgrade1_typography_5_0_0: `좋아요. 제가 살펴보고 다시 말씀드릴게요.`,

      upgrade1_1_typography_0_0_0: `아래 상황을 만드는 데에 기여한 다른 요인을 적어보세요.`,
      upgrade1_1_typography_1_0_0: `다른 요인을 함께 고려해보면, 내가 과장했던 나의 기여분을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_2_typography_0_0_0: `아래 생각이 사실이라는 것과 반대로 사실이 아니라는 것을 말해주는 근거에는 무엇이 있을까요?`,
      upgrade1_2_typography_1_0_0: `양쪽의 증거를 고려해보면, 내가 내렸던 결론을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_3_typography_0_0_0: `발생할 수 있는 다른 가능성들에는 무엇이 있을까요?`,
      upgrade1_3_typography_1_0_0: `만약 최악의 일이 발생하면 어떻게 대처할 수 있을까요?`,
      upgrade1_3_typography_2_0_0: `다른 가능성과 대처 전략을 고려해보면, 내가 했던 부정적인 예측을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_4_typography_0_0_0: `아래 나의 경험에서 기술과 평가를 구분하면 어떻게 될까요?`,
      upgrade1_4_typography_1_0_0: `위에 적은 평가는 나와 동일하다고, 또는 나와 분리할 수 없는 절대적인 특성이라고 이야기할 수 있나요?`,
      upgrade1_4_typography_2_0_0: `위 평가를 그대로 받아들이면, 나의 생각이나 행동에는 어떤 제약이 발생하나요?`,
      upgrade1_4_typography_3_0_0: `평가와 그것이 미칠 영향을 고려해보면, 내가 했던 가치 판단을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade1_5_typography_0_0_0: `아래 나의 경험을 정당화하는 이유를 찾아보면 어떻게 될까요?`,
      upgrade1_5_typography_1_0_0: `앞으로 위에 적은 개념화의 틀 안에서 살아간다면 이후 나의 생각과 행동은 어떠한 제한을 받게 될까요?`,
      upgrade1_5_typography_2_0_0: `개념화와 그것이 미칠 영향을 고려해보면, 자신에 대한 시각을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade1_6_typography_0_0_0: `아래 과거나 미래를 곱씹는 생각이 내게 도움이 되는지 살펴볼게요.`,
      upgrade1_6_typography_1_0_0: `위의 생각은 명확한 목표를 가지고 과거나 미래를 탐색하고 있나요? 아니면 그저 뚜렷한 목표 없이 과거나 미래를 헤매고 있나요?`,
      upgrade1_6_typography_2_0_0: `위의 생각을 통해 현실의 문제를 해결할 수 있는 좋은 방법이 떠올랐나요? 혹은 위의 생각을 통해 감정이 조금이라도 나아졌나요?`,
      upgrade1_6_typography_3_0_0: `반추와 그것이 미친 영향을 고려해보면, 과거나 미래를 곱씹는 생각을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade2_typography_0_0_0: `이번엔 생각의 강 훈련으로 기꺼이 느껴보려 해요. 지금 어느 정도 시간이 있을까요?`,
      upgrade2_typography_1_0_0: `준비됐다면 음성 안내에 따라 훈련을 시작하세요.`,

      upgrade3_typography_0_0_0: `나를 이해하기`,
      upgrade3_typography_1_0_0: `좋아요. 잠깐 나의 핵심 믿음을 살펴보죠.`,
      upgrade3_typography_2_0_0: `"$A$" 생각함정은 핵심 믿음에 반하는 정보를 최소화하고, 핵심 믿음에 일치하는 정보만 받아들이도록 작용하고 있나요? 만약 그렇다면 어떻게 작용하고 있는 걸까요?`,
      upgrade3_typography_3_0_0: `이번엔 잠깐 나의 기록을 되짚어볼게요.`,
      upgrade3_typography_4_0_0: `나의 어떤 중간 믿음이 작동하여 핵심 믿음에 대처했던 걸까요?`,
      upgrade3_typography_5_0_0: `"$A$" 중간 믿음은 나의 핵심 믿음을 강화하나요? 만약 그렇다면 어떤 방식으로 핵심 믿음을 더 강화하고 있나요?`,
      upgrade3_typography_6_0_0: `종합해 보면 중간 믿음과 생각함정은 내가 핵심 믿음과 거리를 둘 수 있게 도와주나요? 아니면 핵심 믿음에서 빠져나올 수 없게 하나요?`,

      wrapup_typographyFromData_0_0_0: `좋아요. 생각과 거리를 둔 지금, 떠오른 생각에 얼마나 영향받고 있나요? 거리두기 전에는 $A$점이라고 하셨어요.`,
      last_typography_0_0_0: "기록을 마쳤어요. [마치기]를 눌러주세요.",
    },
    v3: {
      ...thinkingTrapResource.ko,
      ...thoughtmapResource.ko,
      "책임 과다 - 나의 기여를 과장함": "책임 과다 - 나의 기여를 과장함",
      "근거 부족 - 부족한 근거로 추론함": "근거 부족 - 부족한 근거로 추론함",
      "부정 편향 - 나쁜 전개만을 예상함": "부정 편향 - 나쁜 전개만을 예상함",
      "평가 - 가치 판단을 내림": "평가 - 가치 판단을 내림",
      "개념화 - 이유를 대며 정당화함": "개념화 - 이유를 대며 정당화함",
      "반추 - 과거나 미래를 곱씹음": "반추 - 과거나 미래를 곱씹음",
      "잘 모르겠어요. 코치 선생님의 도움이 필요해요.":
        "잘 모르겠어요. 코치 선생님의 도움이 필요해요.",
      "새로운 함정인 것 같아요. 도와주세요.":
        "새로운 함정인 것 같아요. 도와주세요.",
      "5분": "5분",
      "10분": "10분",
      "15분": "15분",
      없음: "없음",

      initial_typography_0_0_0:
        "아래 고민이 나를 괴롭혔던 순간을 구체적으로 살펴볼 거예요.",
      initial_typography_0_0_0_1:
        "요즈음 어떤 고민이 스스로를 가장 괴롭히나요? 이어지는 질문에서 더 자세히 살펴볼 거니 우선 간단하게만 적어주세요.",
      initial_typography_1_0_0:
        "최근에 위의 고민이 가장 심하게 나를 괴롭혔던 순간을 떠올려보세요.",
      typography_0_0_0: "언제 있었던 일인가요?",
      template_sentiment: "어떤 일이 있었나요?",
      template_0: "언제 있었던 일인가요?",
      template_1: "[상황] 감정과 판단을 배제한 객관적인 상황을 적어주세요.",
      template_2:
        "[생각] 그 순간에 들었던 날것의 생각을 독백 형태로 적어주세요.",
      template_3: "[감정] 당시 느꼈던 감정을 적어주세요.",
      template_4: "[신체 반응] 당시 몸에서 일어났던 감각을 적어주세요.",
      template_5: "[행동] 그 사건 이후에 이어진 행동을 적어주세요.",
      typography_1_0_0:
        "어떤 일이 있었나요? 그때를 떠올리며 마치 슬로우비디오를 보듯 얘기해주세요.",
      typography_2_0_0: "좋아요. 우선 여기서부터 시작해볼까요?",
      typography_2_0_0_1: "분리하기",
      typography_3_0_0:
        "빠진 항목을 채우며 조금 더 분리해볼까요? 3~4개의 질문을 연속해서 드릴 것 같아요.",
      negative_sentiment_typography_0:
        "부정적인 경험이었던 것 같은데요. 떠오른 생각이 어떻게 괴로운 반응을 만들어냈는지 조금 더 살펴볼게요.",
      positive_sentiment_typography_0:
        "긍정적인 경험이었던 것 같은데요. 떠오른 생각이 어떻게 행복한 반응을 만들어냈는지 조금 더 살펴볼게요.",
      ifABCFilled_typography_0:
        "지금까지의 대화를 정리하면 이렇게 분리해볼 수 있을 것 같아요.",
      ifABCFilled_typography_1: "구체화하기",
      ifABCFilled_typography_2:
        "생각을 더 구체화하기 위해 2~3개의 질문을 더 드릴 것 같아요.",
      typography_4_0_0: "이쯤 할까요? 나의 경험이 이렇게 분리되었네요",
      typography_5_0_0: "당시 떠오른 생각에 얼마나 영향 받았었을까요?",
      initial_typography_2_0_0:
        "좋아요. 앞으로 언제든 부정적인 경험을 한다면 지금과 같이 적어주세요. 비슷한 내용이 반복해서 적혀도 괜찮답니다. 오히려 생각의 패턴을 발견하는 데에 도움이 될 거예요.",
      initial_typography_3_0_0:
        "그리고 꼭 처음에 제게 말씀주신 고민에 해당되는 내용일 필요도 없어요. 새롭게 떠오르는 문제일지라도 언제든 적어주세요.",
      initial_typography_4_0_0:
        "우리는 앞으로 <생각기록지>를 중심으로 거리두기 연습을 진행할 거랍니다.",
      initial_typography_5_0_0:
        "기록지를 작성하며 발견되는 생각의 패턴들을 찾고, 그 생각들로부터 거리두는 연습을 할 거예요.",
      initial_typography_6_0_0:
        "새로운 내용들을 배울 때면 <생각기록지>가 업그레이드되기도 할 거랍니다.",
      initial_typography_7_0_0:
        "조금씩 발전하는 <생각기록지>를 꾸준히 활용하며 점차 생각과 거리를 두는 일이 익숙해질 거예요.",
      initial_typography_8_0_0:
        "그러니 앞으로 부정적인 생각이나 감정이 들 때 <생각기록지>를 꺼내어 기록해보세요.",
      initial_typography_9_0_0:
        "그리고 기록 후에는 항상 [마치기]를 눌러주시고요.",
      initial_typography_10_0_0: "디스턴싱의 시작, 분리하기",
      initial_typography_11_0_0: `지금 우리는 생각과 거리두기의 첫 단계인 '분리'를 해냈어요.`,
      initial_typography_12_0_0:
        "트리거가 되는 상황과, 그때 내 머리속에 떠오른 생각, 그리고 그것이 만들어낸 반응으로 분리하는 것이죠.",
      initial_typography_13_0_0:
        "스스로와 직면하는 과정이 쉽지만은 않았을 테지만",
      initial_typography_14_0_0:
        "복잡했던 마음이 정리되며 한층 거리둔 상태로 내 생각을 살펴볼 수 있었을 거예요.",
      initial_typography_15_0_0: `지금부터 <생각기록지>를 쓰며 '분리하기' 과정을 연습해 나갈 거예요.`,
      initial_typography_16_0_0:
        "앞으로 언제든 부정적인 경험을 한다면 지금과 같이 적어주세요. 비슷한 내용이 반복해서 적혀도 괜찮답니다.",
      initial_typography_16: `기록이 쌓여감에 따라 ‘분리하기’ 연습도 한 단계 더 발전된 방식으로 진행할 거랍니다.`,
      initial_typography_17_0_0: `그리고 꼭 처음에 말씀주신 고민에 해당되는 내용일 필요도 없어요. 새롭게 떠오르는 문제일지라도 언제든 적어주세요.`,
      initial_typography_18_0_0: `하지만 정신없이 바쁜 우리의 일상을 생각해보면, 부정적인 일을 겪을 때마다 <생각기록지>를 열고 기록하긴 쉽진 않을텐데요.`,
      initial_typography_19_0_0: `그럴 때는 하루의 끝에서 <생각기록지>를 열고 오늘 하루 부정적인 순간은 없었는지 떠올려 적어보는 게 도움이 된답니다.`,
      initial_typography_20_0_0: `하루를 마무리하며 잊지 않고 작성할 수 있도록 푸시 알림으로 알려드릴게요.`,
      notification_1: "알림을 허용해주세요.",
      notification_2:
        "언제 알려드리면 좋을까요? 원하는 시간을 모두 선택해주세요.",

      upgrade1_typography_0_0_0: `생각과 거리두기`,
      upgrade1_typography_1_0_0: `이제 생각과 거리를 두어볼게요.`,
      upgrade1_typography_2_0_0: `내가 아끼는 친구나 가족을 떠올려보세요. 그 사람은 같은 상황에서 어떠한 생각이 마음속에 떠오를 것 같나요?`,
      upgrade1_typography_3_0_0: `좋아요. 아래 내 마음속에 떠오른 생각에 반응하면 무엇이 좋고, 무엇이 나쁠까요?`,
      upgrade1_typography_4_0_0: `내 생각에는 어떤 생각함정이 있는 것 같나요? 여태 다뤄본 생각함정 중에 있나요?`,
      upgrade1_typography_5_0_0: `좋아요. 제가 살펴보고 다시 말씀드릴게요.`,

      upgrade1_1_typography_0_0_0: `아래 상황을 만드는 데에 기여한 다른 요인을 적어보세요.`,
      upgrade1_1_typography_1_0_0: `다른 요인을 함께 고려해보면, 내가 과장했던 나의 기여분을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_2_typography_0_0_0: `아래 생각이 사실이라는 것과 반대로 사실이 아니라는 것을 말해주는 근거에는 무엇이 있을까요?`,
      upgrade1_2_typography_1_0_0: `양쪽의 증거를 고려해보면, 내가 내렸던 결론을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_3_typography_0_0_0: `발생할 수 있는 다른 가능성들에는 무엇이 있을까요?`,
      upgrade1_3_typography_1_0_0: `만약 최악의 일이 발생하면 어떻게 대처할 수 있을까요?`,
      upgrade1_3_typography_2_0_0: `다른 가능성과 대처 전략을 고려해보면, 내가 했던 부정적인 예측을 어떻게 다시 설명해볼 수 있을까요?`,

      upgrade1_4_typography_0_0_0: `아래 나의 경험에서 기술과 평가를 구분하면 어떻게 될까요?`,
      upgrade1_4_typography_1_0_0: `위에 적은 평가는 나와 동일하다고, 또는 나와 분리할 수 없는 절대적인 특성이라고 이야기할 수 있나요?`,
      upgrade1_4_typography_2_0_0: `위 평가를 그대로 받아들이면, 나의 생각이나 행동에는 어떤 제약이 발생하나요?`,
      upgrade1_4_typography_3_0_0: `평가와 그것이 미칠 영향을 고려해보면, 내가 했던 가치 판단을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade1_5_typography_0_0_0: `아래 나의 경험을 정당화하는 이유를 찾아보면 어떻게 될까요?`,
      upgrade1_5_typography_1_0_0: `앞으로 위에 적은 개념화의 틀 안에서 살아간다면 이후 나의 생각과 행동은 어떠한 제한을 받게 될까요?`,
      upgrade1_5_typography_2_0_0: `개념화와 그것이 미칠 영향을 고려해보면, 자신에 대한 시각을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade1_6_typography_0_0_0: `아래 과거나 미래를 곱씹는 생각이 내게 도움이 되는지 살펴볼게요.`,
      upgrade1_6_typography_1_0_0: `위의 생각은 명확한 목표를 가지고 과거나 미래를 탐색하고 있나요? 아니면 그저 뚜렷한 목표 없이 과거나 미래를 헤매고 있나요?`,
      upgrade1_6_typography_2_0_0: `위의 생각을 통해 현실의 문제를 해결할 수 있는 좋은 방법이 떠올랐나요? 혹은 위의 생각을 통해 감정이 조금이라도 나아졌나요?`,
      upgrade1_6_typography_3_0_0: `반추와 그것이 미친 영향을 고려해보면, 과거나 미래를 곱씹는 생각을 어떻게 다시 바라보는 게 좋을까요?`,

      upgrade2_typography_0_0_0: `이번엔 생각의 강 훈련으로 기꺼이 느껴보려 해요. 지금 어느 정도 시간이 있을까요?`,
      upgrade2_typography_1_0_0: `준비됐다면 음성 안내에 따라 훈련을 시작하세요.`,

      upgrade3_typography_0_0_0: `나를 이해하기`,
      upgrade3_typography_1_0_0: `좋아요. 잠깐 나의 핵심 믿음을 살펴보죠.`,
      upgrade3_typography_2_0_0: `"$A$" 생각함정은 핵심 믿음에 반하는 정보를 최소화하고, 핵심 믿음에 일치하는 정보만 받아들이도록 작용하고 있나요? 만약 그렇다면 어떻게 작용하고 있는 걸까요?`,
      upgrade3_typography_3_0_0: `이번엔 잠깐 나의 기록을 되짚어볼게요.`,
      upgrade3_typography_4_0_0: `나의 어떤 중간 믿음이 작동하여 핵심 믿음에 대처했던 걸까요?`,
      upgrade3_typography_5_0_0: `"$A$" 중간 믿음은 나의 핵심 믿음을 강화하나요? 만약 그렇다면 어떤 방식으로 핵심 믿음을 더 강화하고 있나요?`,
      upgrade3_typography_6_0_0: `종합해 보면 중간 믿음과 생각함정은 내가 핵심 믿음과 거리를 둘 수 있게 도와주나요? 아니면 핵심 믿음에서 빠져나올 수 없게 하나요?`,

      wrapup_typographyFromData_0_0_0: `좋아요. 생각과 거리를 둔 지금, 떠오른 생각에 얼마나 영향받고 있나요? 거리두기 전에는 $A$점이라고 하셨어요.`,
      last_typography_0_0_0: "기록을 마쳤어요. [마치기]를 눌러주세요.",
    },
    v4: {
      ...thoughtmapResource.ko,
      initial_typography_0:
        "요즈음 어떤 고민이 스스로를 가장 괴롭히나요? 이어지는 질문에서 더 자세히 살펴볼 거니 우선 간단하게만 적어주세요.", // deprecated
      initial_typography_0_1: `<생각기록지>는 항상 최근을 시점으로 적을 겁니다.`,
      initial_typography_0_2:
        "오래 전의 기억이 다시금 떠올라 나를 괴롭히고 있다면, 그 기억이 떠오른 '근래의 순간'을 기록해볼 거에요.",
      initial_typography_0_3:
        "예를 들어, 3개월 전의 어떠한 사건 때문에 오늘날 내가 괴로움을 겪고 있다면",
      initial_typography_0_4:
        "3개월 전의 그 사건을 적는 것이 아니라, 그 과거의 기억이 머리속에 떠올라 나를 괴롭힌 최근의 이야기를 적는 것이랍니다.",
      initial_typography_0_5:
        "한편 특별히 과거의 어떤 기억으로 괴로운 것이 아니라면, 요 근래 겪었던 부정적인 일을 기록해볼 거예요.",
      initial_typography_0_6:
        "요즈음 어떤 고민이 스스로를 가장 괴롭히나요? 이어지는 질문에서 더 자세히 살펴볼 거니 우선 간단하게 한 문장으로 적어주세요.",
      initial_typography_1:
        "최근에 위의 고민이 가장 심하게 나를 괴롭혔던 순간을 떠올려보세요.",

      template_sentiment: "어떤 일이 있었나요?",
      template_impact: "나는 그 일에 얼마만큼 영향 받았나요?",

      typography_willingness_1:
        "지금부터 나의 부정적 경험이 하나의 심리적 사건에 불과하다는 사실을 기억하며 기꺼이 경험해볼게요. 호기심을 갖고 모두 알아차리며 작성해보세요.",

      template_when: "언제 있었던 일인가요?",
      template_situation:
        "[상황] 감정과 판단을 배제한 객관적인 상황을 적어주세요.",
      template_thought:
        "[생각] 그 순간에 들었던 날것의 생각을 독백 형태로 적어주세요.",
      template_thoughtWithThoughtTrap: "[생각] 그 순간에 들었던 날것의 생각을 해당하는 생각함정에 적어주세요.",
      template_emotion: "[감정] 당시 느꼈던 감정을 적어주세요.",
      template_emotion_detail_0: "[감정] 당시 느꼈던 감정을 모두 선택해주세요.",
      template_emotion_detail_1:
        "$A$을 조금 더 자세히 관찰해볼게요. 무엇이 느껴지나요?",
      template_physicalResponse:
        "[신체 반응] 당시 느꼈던 신체 반응을 적어주세요.",
      template_behavior: "[행동] 그 사건 이후에 이어진 행동을 적어주세요.",

      initial_typography_2: "디스턴싱의 시작, 분리하기",
      initial_typography_3: `지금 우리는 생각과 거리두기의 첫 단계인 '분리'를 해냈어요.`,
      initial_typography_4:
        "트리거가 되는 상황과, 그때 내 머리속에 떠오른 생각, 그리고 그것이 만들어낸 반응으로 분리하는 것이죠.",
      initial_typography_5: "스스로와 직면하는 과정이 쉽지만은 않았을 테지만",
      initial_typography_6:
        "복잡했던 마음이 정리되며 한층 거리둔 상태로 내 생각을 살펴볼 수 있었을 거예요.",
      initial_typography_7: `지금부터 <생각기록지>를 쓰며 '분리하기' 과정을 연습해 나갈 거예요.`,
      initial_typography_8:
        "앞으로 언제든 부정적인 경험을 한다면 지금과 같이 적어주세요. 비슷한 내용이 반복해서 적혀도 괜찮답니다.",
      initial_typography_9: `기록이 쌓여감에 따라 ‘분리하기’ 연습도 한 단계 더 발전된 방식으로 진행할 거랍니다.`,
      initial_typography_10: `그리고 꼭 처음에 말씀주신 고민에 해당되는 내용일 필요도 없어요. 새롭게 떠오르는 문제일지라도 언제든 적어주세요.`,
      initial_typography_11: `하지만 정신없이 바쁜 우리의 일상을 생각해보면, 부정적인 일을 겪을 때마다 <생각기록지>를 열고 기록하긴 쉽진 않을텐데요.`,
      initial_typography_12: `그럴 때는 하루의 끝에서 <생각기록지>를 열고 오늘 하루 부정적인 순간은 없었는지 떠올려 적어보는 게 도움이 된답니다.`,
      initial_typography_13: `하루를 마무리하며 잊지 않고 작성할 수 있도록 푸시 알림으로 알려드릴게요.`,
      notification_1: "알림을 허용해주세요.",
      notification_2:
        "언제 알려드리면 좋을까요? 원하는 시간을 모두 선택해주세요.",

      initial_typography_14: `그리고 기록 후에는 항상 [마치기]를 눌러주시고요.`,
      template_impact_after: `좋아요. 피하거나 거부하지 않고 있는 그대로 관찰해본 지금, 떠오른 생각에 얼마나 영향받고 있나요? 전에는 $A$점이라고 하셨어요.`,
      template_meditation_0: `명상 훈련으로 영향받는 정도를 조금 더 낮춰볼까요?`,
      template_meditation_1: `지금 어떤 점이 가장 힘든가요?`,
      template_meditation_2: `좋아요. <명상훈련지>에 옮겨두었으니 그곳에서 마저 진행해주세요.`,
      last_typography_0: "기록을 마쳤어요. [마치기]를 눌러주세요.",
    },
  },
};