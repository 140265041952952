import { ProgramContentType } from "./BlockComponent";

export interface ProgramType {
  taskId: string;
  title?: string;
  content: ProgramContentType[];
  translationVersion?: string;
  thoughtRecordKey?: string;
}

export interface ProgramProgressType {
  moduleNumber: number;
  title: string;
  subtitle: string;
  taskIdPrefixList: string[];
}

export const ProgramProgressData = [
  {
    moduleNumber: 1,
    title: "패턴 찾아내기",
    subtitle: "일상에서 어떻게 불편함을 겪는지 알아봐요.",
    taskIdPrefixList: ["0-0", "0-7", "2-2", "1-1", "1-2", "0-1"],
  },
  {
    moduleNumber: 2,
    title: "생각 살펴보기",
    subtitle: "정확하지 않거나 도움이 되지 않는 생각을 다뤄요.",
    previoustaskIdPrefixList: ["0-0", "0-7", "2-2", "1-1", "1-2", "0-1"],
    taskIdPrefixList: [
      "1-3",
      "3-2",
      "3-3",
      "4-1",
      "4-2",
      // "4-3",
      "4-4",
      "4-5",
      "5-1",
      "5-2",
      "5-6",
      "5-7",
    ],
  },
  {
    moduleNumber: 3,
    title: "가치대로 살기",
    subtitle: "나의 가치에 맞게 행동하며 풍성한 삶을 만들어요.",
    previoustaskIdPrefixList: [
      "0-0",
      "0-7",
      "2-2",
      "1-1",
      "1-2",
      "0-1",
      "1-3",
      "3-2",
      "3-3",
      "4-1",
      "4-2",
    ],
    taskIdPrefixList: [
      "7-1",
      "7-2",
      "7-3",
      "7-4",
      "7-5",
      "7-6",
      "7-7",
      "7-8",
      "7-9",
    ],
  },
  {
    moduleNumber: 4,
    title: "뿌리 이해하기",
    subtitle: "나의 과거가 어떻게 오늘날을 만드는지 이해해요.",
    taskIdPrefixList: [
      "6-1",
      "6-2",
      "6-3",
      "6-4",
      "6-5",
      "6-6",
      "6-7",
      "6-8",
      "6-9",
    ],
  },
  {
    moduleNumber: 5,
    title: "꾸준히 실천하기",
    subtitle: "삶의 매순간 생각과 거리두고 가치로 나아가요.",
    taskIdPrefixList: ["6-12", "6-13"],
  },
];
export const ProgressNodeData = [
  {
    nodeId: "finding_pattern",
    title: "패턴 찾아내기",
    subtitle: "일상에서 어떻게 불편함을 겪는지 알아봐요.",
  },
  {
    nodeId: "creating_space",
    title: "공간 만들기",
    subtitle: "생각과 거리를 두며 마음 속 공간을 만들어요.",
  },
  {
    nodeId: "observing_thoughts",
    title: "생각 살펴보기",
    subtitle: "정확하지 않거나 도움이 되지 않는 생각을 다뤄요.",
  },
  {
    nodeId: "willingly_experience",
    title: "기꺼이 경험하기",
    subtitle: "마주하고 느끼며 괴로움으로부터 자유로워져요.",
  },
  {
    nodeId: "understanding_roots",
    title: "뿌리 이해하기",
    subtitle: "나의 과거가 어떻게 오늘날을 만드는지 이해해요.",
  },
  {
    nodeId: "living_by_values",
    title: "가치대로 살기",
    subtitle: "나의 가치에 맞게 행동하며 풍성한 삶을 만들어요.",
  },

  {
    nodeId: "consistent_practice",
    title: "꾸준히 실천하기",
    subtitle: "삶의 매순간 생각과 거리두고 가치로 나아가요.",
  },
];
export const ProgramProgressData2 = [
  //좌1
  {
    progressNumber: 1,
    startNodeId: "finding_pattern",
    endNodeId: "creating_space",
    taskIdPrefixList: ["0-0", "2-3", "2-4", "0-7", "2-2", "1-1", "1-2", "1-3"],
    nextProgressNumber: 2,
  },
  //좌2
  {
    progressNumber: 2,
    startNodeId: "creating_space",
    endNodeId: 5,
    taskIdPrefixList: [
      "8-1",
      "8-2",
      "8-3",
      "8-4",
      "8-5",
      "8-6",
      "8-7",
      "8-8",
      "8-9",
    ],
    nextProgressNumber: 8,
  },
  //중1
  {
    progressNumber: 3,
    startNodeId: "finding_pattern",
    endNodeId: "observing_thoughts",
    taskIdPrefixList: ["0-0", "2-3", "2-4", "0-7", "2-2", "1-1", "1-2", "1-3"],
    nextProgressNumber: 4,
  },
  //중2
  {
    progressNumber: 4,
    startNodeId: "observing_thoughts",
    endNodeId: "willingly_experience",
    taskIdPrefixList: [
      "3-2",
      "3-3",
      "4-1",
      "4-2",
      // "4-3",
      "4-4",
      "4-5",
    ],
    nextProgressNumber: 5,
  },
  //중3
  {
    progressNumber: 5,
    startNodeId: "willingly_experience",
    endNodeId: "understanding_roots",
    taskIdPrefixList: [
      "9-1",
      "9-2",
      "9-3",
      "9-4",
      "9-5",
      "9-6",
      "9-7",
      "9-8",
      "9-9",
      "9-10",
      "9-11",
      "9-12",
      "9-13",
    ],
    nextProgressNumber: 8,
  },
  //우1
  {
    progressNumber: 6,
    startNodeId: "finding_pattern",
    endNodeId: "living_by_values",
    taskIdPrefixList: [
      "0-0",
      "2-3",
      "2-4",
      "0-7",
      "2-2",
      "1-1",
      "1-2",
      "1-3",
      "8-1",
      "8-2",
      "8-3",
      "8-4",
      "8-5",
      "8-6",
      "8-7",
      "8-8",
      "8-9",
    ],
    nextProgressNumber: 7,
  },
  //우2
  {
    progressNumber: 7,
    startNodeId: "living_by_values",
    endNodeId: "understanding_roots",
    taskIdPrefixList: [
      "7-1",
      "7-2",
      "7-3",
      "7-4",
      "7-5",
      "7-6",
      "7-7",
      "7-8",
      "7-9",
    ],
    nextProgressNumber: 8,
  },
  //중4
  {
    progressNumber: 8,
    startNodeId: "understanding_roots",
    endNodeId: "consistent_practice",
    taskIdPrefixList: ["6-12", "6-13"],
  },
];
export const BehavioralActivationProgramProgressData = [
  {
    moduleNumber: 1,
    title: "행동 관찰하기",
    subtitle: "무기력 극복의 힌트가 되는 나만의 활동을 찾아요.",
    taskIdPrefixList: [
      "100-0",
      "100-1",
      "101-1-1",
      "101-1-2",
      "101-1-3",
      "101-2-1",
      "101-2-2",
      "101-2-3",
      "101-2-4",
      "101-2-5",
    ],
  },
  {
    moduleNumber: 2,
    title: "행동 에너지 높이기",
    subtitle: "무기력에서 빠져나올 수 있도록 기초 에너지를 높여요.",
    taskIdPrefixList: ["103-1", "104-0", "104-1", "104-2"],
  },
  {
    moduleNumber: 3,
    title: "가치대로 행동하기",
    subtitle: "나의 가치에 맞는 활동을 단계적으로 성취해나가요",
    taskIdPrefixList: [],
  },
  {
    moduleNumber: 4,
    title: "본질 이해하기",
    subtitle: "나의 내면에서 문제가 어떻게 만들어지는지 이해해요.",
    taskIdPrefixList: [],
  },
];
export const TaskMetaData: { taskId: string; title: string }[] = [
  //0-0-A
  {
    taskId: "0-0-A",
    title: "디스턴싱 시작하기",
  },
  //0-4-A
  {
    taskId: "0-4-A",
    title: "생각과 거리두기",
  },
  //0-7-A
  {
    taskId: "0-7-A",
    title: "나의 첫 번째 생각기록지",
  },
  //0-1-A
  {
    taskId: "0-1-A",
    title: "부정적 반응 측정하기",
  },
  //0-2-A
  {
    taskId: "0-2-A",
    title: "부정적 반응 측정하기",
  },
  //0-3-A
  {
    taskId: "0-3-A",
    title: "이번 주 돌아보기",
  },
  //0-5-A
  {
    taskId: "0-5-A",
    title: "목표 돌아보기",
  },
  //0-6-A
  {
    taskId: "0-6-A",
    title: "목표 돌아보기",
  },
  //1-1-A
  {
    taskId: "1-1-A",
    title: "나의 고민과 목표",
  },
  //1-2-A
  {
    taskId: "1-2-A",
    title: "프로그램 구성하기",
  },
  //1-3-A
  {
    taskId: "1-3-A",
    title: "생각과 팝콘과 나",
  },
  //1-4-A
  {
    taskId: "1-4-A",
    title: "효과적이지 않은 대처",
  },
  //2-1-A
  {
    taskId: "2-1-A",
    title: "생각기록지 작성 연습하기",
  },
  //2-3-A
  {
    taskId: "2-3-A",
    title: "꾸러미 분리하기",
  },
  //2-4-A
  {
    taskId: "2-4-A",
    title: "분리 연습하기",
  },
  //2-2-T
  {
    taskId: "2-2-T",
    title: "생각기록지",
  },
  //3-1-A
  {
    taskId: "3-1-A",
    title: "생각 지도",
  },
  //3-2-A
  {
    taskId: "3-2-A",
    title: "자동적인 생각",
  },
  //3-3-A
  {
    taskId: `3-3-A`,
    title: `자동적 사고 살펴보기`,
  },
  //4-1-A
  {
    taskId: "4-1-A",
    title: "생각함정",
  },
  //4-2-A
  {
    taskId: `4-2-A`,
    title: `다양한 관점에서 생각해보기`,
  },
  //4-3-1-A
  {
    taskId: `4-3-1-A`,
    title: `정확하지 않은 생각들`,
  },
  //4-3-2-A
  {
    taskId: `4-3-2-A`,
    title: `도움이 되지 않는 생각들`,
  },
  //4-4-1-A
  {
    taskId: `4-4-1-A`,
    title: `생각함정 1. 책임 과다`,
  },
  //4-4-2-A
  {
    taskId: `4-4-2-A`,
    title: `생각함정 2. 근거 부족`,
  },
  //4-4-3-A
  {
    taskId: `4-4-3-A`,
    title: `생각함정 3. 부정 편향`,
  },
  //4-4-4-A
  {
    taskId: `4-4-4-A`,
    title: `생각함정 4. 평가`,
  },
  //4-4-5-A
  {
    taskId: `4-4-5-A`,
    title: `생각함정 5. 개념화`,
  },
  //4-4-6-A
  {
    taskId: `4-4-6-A`,
    title: `생각함정 6. 반추`,
  },
  //4-5-1-A
  {
    taskId: `4-5-1-A`,
    title: `책임 과다 다루기`,
  },
  //4-5-2-A
  {
    taskId: `4-5-2-A`,
    title: `근거 부족 다루기`,
  },
  //4-5-3-A
  {
    taskId: `4-5-3-A`,
    title: `부정 편향 다루기`,
  },
  //4-5-4-A
  {
    taskId: `4-5-4-A`,
    title: `평가 다루기`,
  },
  //4-5-5-A
  {
    taskId: `4-5-5-A`,
    title: `개념화 다루기`,
  },
  //4-5-6-A
  {
    taskId: `4-5-6-A`,
    title: `반추 다루기`,
  },
  //4-6-A
  {
    taskId: `4-6-A`,
    title: `생각과 행동`,
  },
  //4-7-A
  {
    taskId: `4-7-A`,
    title: `안전행동`,
  },
  //4-8-A
  {
    taskId: `4-8-A`,
    title: `나만의 행동 실험실`,
  },
  //4-9-A
  {
    taskId: `4-9-A`,
    title: `행동 실험 n단계 계획하기`,
  },
  //4-10-A
  {
    taskId: `4-10-A`,
    title: `행동 실험 n단계 실행하기`,
  },
  //4-11-A
  {
    taskId: `4-11-A`,
    title: `행동 실험실 마치기`,
  },
  //5-1-A
  {
    taskId: `5-1-A`,
    title: `뇌의 재활훈련`,
  },
  //5-2-A
  {
    taskId: `5-2-A`,
    title: `기꺼이 하기`,
  },
  //5-3-A
  {
    taskId: `5-3-A`,
    title: `생각 바라보기`,
  },
  //5-4-A
  {
    taskId: `5-4-A`,
    title: `감정 바라보기`,
  },
  //5-5-A
  {
    taskId: `5-5-A`,
    title: `신체 감각 바라보기`,
  },
  //5-6-A
  {
    taskId: `5-6-A`,
    title: `마음 바라보기`,
  },
  //5-7-T
  {
    taskId: `5-7-T`,
    title: `명상활동지`,
  },
  //6-1-A
  {
    taskId: `6-1-A`,
    title: `생각 그물`,
  },
  //6-2-A
  {
    taskId: `6-2-A`,
    title: `중간 믿음`,
  },
  //6-3-A
  {
    taskId: `6-3-A`,
    title: `중간 믿음 살펴보기`,
  },

  //6-4-A
  {
    taskId: `6-4-A`,
    title: `핵심 믿음`,
  },
  //6-5-A 영구결번
  {
    taskId: `6-5-A`,
    title: `핵심 믿음 찾아보기`,
  },
  //6-6-A
  {
    taskId: `6-6-A`,
    title: `핵심 믿음 살펴보기`,
  },
  //6-7-A
  {
    taskId: `6-7-A`,
    title: `핵심 경험`,
  },
  //6-8-A
  {
    taskId: `6-8-A`,
    title: `핵심 경험 살펴보기`,
  },
  //6-10-A
  {
    taskId: `6-10-A`,
    title: `대처 전략과 중간 믿음`,
  },
  //6-9-A
  {
    taskId: `6-9-A`,
    title: `생각 그물 이해하기`,
  },
  //6-11-A
  {
    taskId: `6-11-A`,
    title: `생각 그물과 가치`,
  },
  //6-12-A
  {
    taskId: `6-12-A`,
    title: `생각 그물과 거리두기`,
  },
  //6-13-A
  {
    taskId: `6-13-A`,
    title: `생각과 거리두고 가치로 나아가기`,
  },

  //6-7-A => 6-97-A
  {
    taskId: `6-97-A`,
    title: `핵심 경험 찾아보기`,
  },
  //6-2-A => 6-98-A
  {
    taskId: `6-98-A`,
    title: `대처 전략 살펴보기`,
  },
  //6-3-A => 6-99-A
  {
    taskId: `6-99-A`,
    title: `대처 전략과 자동적 사고`,
  },

  //7-1-A
  {
    taskId: `7-1-A`,
    title: `긍정 기록 시작하기`,
  },
  //7-2-A
  {
    taskId: `7-2-A`,
    title: `나의 첫 번째 긍정 기록`,
  },
  //7-3-A
  {
    taskId: `7-3-A`,
    title: `삶의 방향 선택하기`,
  },
  //7-4-A
  {
    taskId: `7-4-A`,
    title: `가치 살펴보기`,
  },
  //7-5-A
  {
    taskId: `7-5-A`,
    title: `가치 행동`,
  },
  //7-6-A
  {
    taskId: `7-6-A`,
    title: `가치 행동 설계하기`,
  },
  //7-7-A
  {
    taskId: `7-7-A`,
    title: `가치 행동 n단계 계획하기`,
  },
  //7-8-A
  {
    taskId: `7-8-A`,
    title: `가치 행동 n단계 실행하기`,
  },
  //7-9-A
  {
    taskId: `7-9-A`,
    title: `가치 정리하기`,
  },
  //8-1-A
  {
    taskId: `8-1-A`,
    title: `생각과 다시 관계 맺기`,
  },
  //8-2-A
  {
    taskId: `8-2-A`,
    title: `첫 번째 명상 훈련`,
  },
  //8-10-A
  {
    taskId: `8-10-A`,
    title: `레몬 맛 팝콘`,
  },
  //8-3-A
  {
    taskId: `8-3-A`,
    title: `두 번째 명상 훈련`,
  },
  //8-4-A
  {
    taskId: `8-4-A`,
    title: `세 번째 명상 훈련`,
  },
  //8-5-A
  {
    taskId: `8-5-A`,
    title: `네 번째 명상 훈련`,
  },
  //8-6-A
  {
    taskId: `8-6-A`,
    title: `훈련 루틴 만들기`,
  },
  //8-8-A
  {
    taskId: `8-8-A`,
    title: `감정과 신체 다스리기`,
  },
  //8-9-A
  {
    taskId: `8-9-A`,
    title: `생각에서 빠져나오기`,
  },
  //9-1-A
  {
    taskId: `9-1-A`,
    title: `마음과 문제 해결`,
  },
  //9-2-A
  {
    taskId: `9-2-A`,
    title: `나의 지난 노력들`,
  },
  //9-3-A
  {
    taskId: `9-3-A`,
    title: `기꺼이 경험하기`,
  },
  //9-4-A
  {
    taskId: `9-4-A`,
    title: `적극적 관찰자 되기 - 상황`,
  },
  //9-5-A
  {
    taskId: `9-5-A`,
    title: `기꺼이 경험하며 기록하기 - 상황`,
  },
  //9-6-A
  {
    taskId: `9-6-A`,
    title: `적극적 관찰자 되기 - 감정`,
  },
  //9-7-A
  {
    taskId: `9-7-A`,
    title: `기꺼이 경험하며 기록하기 - 감정`,
  },
  //9-8-A
  {
    taskId: `9-8-A`,
    title: `적극적 관찰자 되기 - 신체 반응`,
  },
  //9-9-A
  {
    taskId: `9-9-A`,
    title: `기꺼이 경험하며 기록하기 - 신체 반응`,
  },
  //9-10-A
  {
    taskId: `9-10-A`,
    title: `적극적 관찰자 되기 - 행동`,
  },
  //9-11-A
  {
    taskId: `9-11-A`,
    title: `기꺼이 경험하며 기록하기 - 행동`,
  },
  //9-12-A
  {
    taskId: `9-12-A`,
    title: `몸의 감각 느끼기`,
  },
  //9-13-A
  {
    taskId: `9-13-A`,
    title: `어려움을 기꺼이 경험하기`,
  },

  //행동활성화 프로그램
  {
    taskId: `100-0-A`,
    title: `디스턴싱 시작하기`,
  },
  {
    taskId: `100-1-A`,
    title: `무기력의 악순환`,
  },
  {
    taskId: `101-1-1-A`,
    title: `행동 이해하기`,
  },
  {
    taskId: `101-1-2-A`,
    title: `활동 기록하기`,
  },
  {
    taskId: `101-1-3-T`,
    title: `활동기록지`,
  },
  {
    taskId: `101-2-1-A`,
    title: `나의 악순환 알아보기`,
  },
  {
    taskId: `101-2-2-A`,
    title: `악순환 패턴 정리하기`,
  },
  {
    taskId: `101-2-3-A`,
    title: `방향성 되찾기`,
  },
  {
    taskId: `101-2-4-A`,
    title: `나의 가치 정하기`,
  },
  {
    taskId: `101-2-5-A`,
    title: `선순환 패턴 정리하기`,
  },
  {
    taskId: `102-1-A`,
    title: `무기력 평가하기`,
  },
  {
    taskId: `102-2-A`,
    title: `무기력 평가하기`,
  },
  {
    taskId: `103-1-A`,
    title: `밖에서부터 안으로`,
  },
  {
    taskId: `104-0-A`,
    title: `N번째 활동 - 고르기`,
  },
  {
    taskId: `104-1-A`,
    title: `N번째 활동 - M단계 계획하기`,
  },
  {
    taskId: `104-2-A`,
    title: `N번째 활동 - M단계 실행하기`,
  },
  // ...Array.from({ length: 20 }, (v, i) => i + 1).flatMap((N) => [
  //   {
  //     taskId: `104-0-A#${N}`,
  //     title: `N번째 활동 - 고르기`,
  //   },
  //   ...Array.from({ length: 5 }, (v, j) => j + 1).map((M) => ({
  //     taskId: `104-1-A#${N}#${M}`,
  //     title: `N번째 활동 - M단계 계획하기`,
  //   })),
  //   ...Array.from({ length: 5 }, (v, j) => j + 1).map((M) => ({
  //     taskId: `104-2-A#${N}#${M}`,
  //     title: `N번째 활동 - M단계 실행하기`,
  //   })),
  // ]),

  //tool
  {
    taskId: `meditationTool`,
    title: `명상`,
  },
];

export function isTaskIdValid(taskId: string) {
  const taskIndex = TaskMetaData.findIndex(
    (element) => element.taskId === taskId
  );
  return taskIndex > -1;
}