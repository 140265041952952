import { automaticThoughtIdList } from "api/conceptApi";
import { translation_0_0_A } from "./activity/v1/0-0-A";
import { translation_0_1_A } from "./activity/v1/0-1-A";
import { translation_0_2_A } from "./activity/v1/0-2-A";
import { translation_0_3_A } from "./activity/v1/0-3-A";
import { translation_1_1_A } from "./activity/v1/1-1-A";
import { translation_1_2_A } from "./activity/v1/1-2-A";
import { translation_1_3_A } from "./activity/v1/1-3-A";
import { translation_2_1_A } from "./activity/v1/2-1-A";
import { translation_2_2_T } from "./activity/v1/2-2-T";
import { translation_3_2_A } from "./activity/v1/3-2-A";
import { translation_3_3_A } from "./activity/v1/3-3-A";
import { translation_4_1_A } from "./activity/v1/4-1-A";
import { translation_4_2_A } from "./activity/v1/4-2-A";
import { translation_4_3_1_A } from "./activity/v1/4-3-1-A";
import { translation_4_3_2_A } from "./activity/v1/4-3-2-A";
import { translation_4_4_1_A } from "./activity/v1/4-4-1-A";
import { translation_4_4_2_A } from "./activity/v1/4-4-2-A";
import { translation_4_4_3_A } from "./activity/v1/4-4-3-A";
import { translation_4_4_4_A } from "./activity/v1/4-4-4-A";
import { translation_4_4_5_A } from "./activity/v1/4-4-5-A";
import { translation_4_4_6_A } from "./activity/v1/4-4-6-A";
import { translation_4_5_1_A } from "./activity/v1/4-5-1-A";
import { translation_4_5_2_A } from "./activity/v1/4-5-2-A";
import { translation_4_5_3_A } from "./activity/v1/4-5-3-A";
import { translation_4_5_4_A } from "./activity/v1/4-5-4-A";
import { translation_4_5_5_A } from "./activity/v1/4-5-5-A";
import { translation_4_5_6_A } from "./activity/v1/4-5-6-A";
import { translation_5_1_A } from "./activity/v1/5-1-A";
import { translation_5_2_A } from "./activity/v1/5-2-A";
import { translation_5_3_A } from "./activity/v1/5-3-A";
import { translation_5_4_A } from "./activity/v1/5-4-A";
import { translation_5_5_A } from "./activity/v1/5-5-A";
import { translation_6_1_A } from "./activity/v1/6-1-A";
import { translation_6_2_A } from "./activity/v1/6-2-A";
import { translation_6_3_A } from "./activity/v1/6-3-A";

import { translation_6_4_A } from "./activity/v1/6-4-A";
import { translation_6_5_A } from "./activity/v1/6-5-A";
import { translation_6_6_A } from "./activity/v1/6-6-A";
import { translation_6_7_A } from "./activity/v1/6-7-A";

import { translation_6_8_A } from "./activity/v1/6-8-A";
import { translation_6_9_A } from "./activity/v1/6-9-A";

import { translation_6_97_A } from "./activity/v1/6-97-A";
import { translation_6_98_A } from "./activity/v1/6-98-A";
import { translation_6_99_A } from "./activity/v1/6-99-A";
import { thinkingTrapResource } from "./thinkingTrap";
import { thoughtmapResource } from "./thoughtmap";

export const en = {
  ui: {
    "생각과 거리두고\n가치로 나아가세요": "Better Mental Health,\nDistancing",
    "안전하고 효과적인 비약물 인지치료,\n정신건강 전문 코치들이 이끕니다.":
      "A science-based cognitive therapy program\nto manage depression and anxiety",
    "과학에 기반한 인지치료 프로그램,\n전문가와 함께 우울과 불안을 해결하세요.":
      "A science-based cognitive therapy\nfor depression and anxiety led by experts.",
    "생각과 거리두고\n우울증에서 벗어나세요":
      "Distance yourself\nfrom depression",
    "초기 우울증을 위한 디지털 인지치료\n전문가와 함께 우울과 무기력을 해결합니다. ":
      "Cognitive therapy for early-stage depression",
    처음이에요: "It's my first time here",
    "이미 코치를 배정받았어요": "I've already been assigned a coach",
    로그인: "Sign in with {{type}}",
    "로그인 완료": "Signed in Successfully",

    "사업자 정보": "Business Information",
    "(대) 서비스 이용약관": "Terms of Service",
    "(대) 개인정보 처리방침": "Privacy Policy",

    "서비스 이용약관": "terms of service",
    "개인정보 처리방침": "privacy policy",
    확인: "Okay",

    "앱에서 진행해주세요": "Hop into the app to get started!",
    "다른 계정으로 로그인하기": "Sign in with a different account",

    "꾸준히 하는 활동": "Ongoing",
    "다음 할 활동": "To-do",
    "마친 활동": "Completed",

    "구독 관리": "Subscription",
    로그아웃: "Sign out",
    회원탈퇴: "Delete account",
    "정말로 로그아웃할까요?": "Ready to sign out?",
    "정말로 탈퇴할까요?": "Delete your account?",
    "탈퇴하기 전에 이용 중인 구독을 먼저 취소해 주세요":
      "Please cancel any active subscriptions before deleting account.",
    "구독 취소하기": "Cancel subscription",
    "로그아웃 하기": "Sign out",
    탈퇴하기: "Delete account",
    "문의사항은 여기로 보내주세요.": "Got a question? Just drop us a line at:",
    "사용 중인 닉네임을 알려주시면 더 빠르게 처리해 드릴게요.":
      "If you let us know your nickname, we can help you out even faster!",

    "주어진 활동을 모두 마쳤어요": "You've nailed all the activities assigned!",
    "좌측 사이드바에서 주어진 활동을 진행해주세요":
      "Take a peek at the left sidebar and dive into your activities",
    "코치 선생님이 확인 후에 메시지를 드릴 거예요.":
      "Once your coach has a look, they'll ping you with a message.",
    "'다음 할 활동' 목록에서 순서대로 하나씩 마쳐주세요.":
      "Just work your way down the 'To-do' list, one task at a time.",
    "첫 번째 활동을 마쳤어요": "You've nailed the first activitiy",
    "평일 업무시간에 코치 선생님이 확인 후에 메시지를 드릴 거예요.": "You've nailed the first activitiy",
    "잠시 뒤 코치 선생님이 배정될 거예요.":
      "Hang tight! Your coach will be assigned shortly.",
    "오늘 자정에 새 활동이 업데이트돼요": "오늘 자정에 새 활동이 업데이트돼요",
    "그동안 일상에서 배운 디스턴싱을 실천해보세요.":
      "그동안 일상에서 배운 디스턴싱을 실천해보세요.",

    "사이드바 열기": "Open Sidebar",

    고민: "Concern",
    목표: "Goal",
    "요즈음 나를 괴롭히는 고민은?": "What's troubling me these days?",
    "내가 만들고 싶은 변화는?": "What change do I want to make?",

    "진행 중": "In progress",
    접기: "Hide",
    "전체 진도 보기": "Show overall progress",
    마치기: "Finish",

    "검사 결과가 없어요.": "No results found yet.",

    "알림 허용하기": "Allow Notifications",
    "알림 허용됨": "Notifications Allowed",
    "음성 안내 재생하기": "Play Voice Guidance",
    "이어서 재생하기": "Play Voice Guidance",
    "리뷰 남기기": "Write a Review",

    "새로운 기록 쓰기": "Add a New Log",

    "더 보기": "More",
    "더 알아보기": "Learn more",
  },
  thoughtmap: {
    "핵심 경험": "Core Experience",
    "핵심 믿음": "Core Belief",
    "대처 전략": "Coping Strategy",
    패턴: "Pattern",
    "아래 핵심 믿음을 먼저 찾은 뒤에 핵심 경험을 찾아요.":
      "Find the core beliefs below first, then move to the core experiences.",
    "아래 대처 전략을 먼저 찾은 뒤에 핵심 믿음을 찾아요.":
      "Find the coping strategies below first, then move to the core beliefs.",
    "아래 자동적 사고를 먼저 찾은 뒤에 대처 전략을 찾아요.":
      "Find the automatic thoughts below first, then move to the coping strategies.",
    "코치 선생님과 함께 찾아봐요.": "Explore with your coach.",
    "지금의 나를 만든 과거의 경험은?": "What past shaped me?",
    "내 마음속 깊이 자리 잡은 생각은?": "What deep-rooted thoughts in me?",
    "오늘날 내가 삶을 헤쳐나가는 방식은?": "How do I tackle life today?",
    "일상에서 내가 겪는 불편은?": "What daily struggles do I face?",
    "찾은 생각함정": "Thinking traps",
    ...thoughtmapResource.en,
  },
  selfCheck: {
    우울: "Depression",
    불안: "Anxiety",
    스트레스: "Stress",
  },
  thinkingTrap: {
    ...thinkingTrapResource.en,
  },
  task: {
    progress: {
      "패턴 찾아내기": "Uncover Patterns",
      "일상에서 어떻게 불편함을 겪는지 알아봐요.":
        "Identify how everyday happenings affect me.",
      "생각 살펴보기": "Examine Thoughts",
      "정확하지 않거나 도움이 되지 않는 생각을 다뤄요.":
        "Deal with inaccurate and unhelpful thoughts.",
      "기꺼이 하기": "Embrace Willingly",
      "나의 감정과 생각을 마주하고 그대로 경험해요.":
        "Accept and face my feelings and thoughts.",
      "뿌리 이해하기": "Explore Roots",
      "나의 과거가 어떻게 오늘날을 만드는지 이해해요.":
        "Understand how my past shapes current patterns.",
      "가치대로 살기": "Live Values",
      "나의 가치에 맞게 행동하며 풍성한 삶을 만들어요.":
        "Align my actions with my values to lead a fulfilling life.",
    },
    title: {
      "나의 생각 지도": "My Thought Map",
      "나의 진행 상황": "My Progress",
      대시보드: "Dashboard",
      "월 구독": "Monthly Plan",
      "월 이용권": "Monthly Pass",

      "디스턴싱 시작하기": "Welcome to Distancing",
      "프로그램 구성하기": "Setting Up My Program",
      "나의 고민 알아보기": "Understanding My Concern",
      "나의 고민과 목표": "My Concerns and Goals",
      "생각기록지 작성 연습하기": "Practicing Logging Thoughts",
      생각기록지: "Thought Log",
      "부정적 반응 측정하기": "Tracking Negative Reactions",
      "생각과 팝콘과 나": "Thoughts, Popcorn, and Me",
      "자동적인 생각": "Automatic Thoughts",
      "자동적 사고 살펴보기": "Exploring Automatic Thought",
      생각함정: "Thinking Traps",
      "다양한 관점에서 생각해보기": "Exploring from Different Perspectives",
      "정확하지 않은 생각들": "Inaccurate Thoughts",
      "도움이 되지 않는 생각들": "Unhelpful Thoughts",
      "생각함정 1. 책임 과다": "Thinking Trap 1: Extra Blame",
      "생각함정 2. 근거 부족": "Thinking Trap 2: Little Evidence",
      "생각함정 3. 부정 편향": "Thinking Trap 3: Negative Bias",
      "생각함정 4. 평가": "Thinking Trap 4: Judgment",
      "생각함정 5. 개념화": "Thinking Trap 5: Conceptualization",
      "생각함정 6. 반추": "Thinking Trap 6: Rumination",
      "책임 과다 다루기": "Handling Extra Blame",
      "근거 부족 다루기": "Handling Little Evidence",
      "부정 편향 다루기": "Handling Negative Bias",
      "평가 다루기": "Handling Judgment",
      "개념화 다루기": "Handling Conceptualization",
      "반추 다루기": "Handling Rumination",
      "뇌의 재활훈련": "Brain Rehabilitation Training",
      "기꺼이 하기": "Willing Embrace",
      "생각 바라보기": "Observing Thoughts",
      "감정 바라보기": "Observing Emotions",
      "신체 감각 바라보기": "Observing Body Feelings",

      "생각 지도": "Thought Map", //6-1-A
      "중간 믿음": "Intermediate Beliefs", //6-2-A
      "중간 믿음 살펴보기": "Exploring Intermediate Beliefs", //6-3-A
      "핵심 믿음": "Core Beliefs", //6-4-A
      "핵심 믿음 찾아보기": "Uncovering Core Beliefs", //6-5-A
      "핵심 믿음 살펴보기": "Exploring Core Beliefs", //6-6-A
      "핵심 경험": "Core Experiences", //6-7-A
      "핵심 경험 살펴보기": "Exploring Core Experiences", //6-8-A
      "생각 지도 이해하기": "Understanding Thought Map", //6-9-A
      "지난 기록 돌아보기": "Looking Back at Past Logs", //6-10-A

      "핵심 경험 찾아보기": "Uncovering Core Experiences", //6-97-A
      "대처 전략 살펴보기": "Exploring Coping Strategy", //6-98-A
      "대처 전략과 자동적 사고": "Coping Strategies and Automatic Thoughts", //6-99-A

      "효과적이지 않은 대처": "Ineffective Coping",
      "이번 주 돌아보기": "Looking Back on This Week",
    },
    activity: {
      common: {
        lastCard1:
          "How was this activity? Feel free to share your thoughts. If not, please tap the arrow to move on.",
        lastCard2: "You've completed the activity. Please tap [Finish].",
        예시: "Example",
        "나의 고민": "My Concern",
        고민: "Concern",
        목표: "Goal",
        날짜: "Date",

        // 상황: "Situation",
        // 생각: "Thought",
        // "영향 정도": "Impact level",
        // "감정/신체": "Emotion/Body",
        // 행동: "Behavior",
        // "자동적 사고": "Automatic thought",
        ...thoughtmapResource.en,
        $A$: "$A$",
        selfcheck_evaluation1_1: "Not at all",
        selfcheck_evaluation1_2: "Several days",
        selfcheck_evaluation1_3: "More than 7 days",
        selfcheck_evaluation1_4: "Nearly every day",
        selfcheck_evaluation2_1: "Never",
        selfcheck_evaluation2_2: "Almost Never",
        selfcheck_evaluation2_3: "Sometimes",
        selfcheck_evaluation2_4: "Fairly often",
        selfcheck_evaluation2_5: "Very often",

        요인: "Factor",
        "나의 요인": "My Factor",
        기여도: "Contribution",
        "통제 가능 여부": "Controllability",
        "통제 가능": "Controllable",
        "통제 불가능": "Uncontrollable",
        근거: "Reason",
        요소: "Point",
        "해당하는 근거": "Why",
        "해당하지 않는 근거": "Why not",
        "해당하는 정도": "Relevance",
        "무력화 가능성": "Possibility to stop",
        확률: "Probability",
        "다른 가능성": "Possibility",
        "최악의 가능성": "Worst Possibility",
        "대처 전략": "Coping plan",
        "도움 대상": "Help source",
        단계: "Step",
        "기술 (상황과 나의 직접적인 특성)":
          "Description (Direct traits of situations and myself) ",
        "평가 (상황과 나에 대한 가치 판단)":
          "Judgment (Value assessments of situations and myself) ",
        "나의 평가": "My judgment",
        "마음속에 떠오른 평가": "Original judgment",
        "거리를 두고 바라본 평가": "Judgment with distance",
        "나의 개념화": "My conceptualization",
        "마음속에 떠오른 개념화": "Original conceptualization",
        "거리두기를 통해 바라본 개념화": "Conceptualization with distance",
        "나의 반추": "My rumination",
        "마음속에 떠오른 반추": "Original rumination",
        "거리두기를 통해 바라본 반추": "Rumination with distance",

        "그 사람이 겪을 상황": "Their situation",
        "그 사람의 마음속에 떠오를 것 같은 생각": "Their thought",
        "그 사람의 상황": "Their situation",
        "그 사람의 생각": "Their thought",
        "생각에 반응했을 때의 이점": "Benefits",
        "생각에 반응했을 때의 손해": "Drawbacks",
        이점: "Benefits",
        손해: "Drawbacks",
        생각함정: "Thinking trap",
        반증: "Evidence against",
        "사실이라는 근거": "Evidence for",
        "사실이 아니라는 근거": "Evidence against",
        "기술 (경험에 대한 사실)":
          "Description (Direct traits of situations and myself)",
        "평가 (경험에 대한 가치 판단)":
          "Judgment (Value assessments of situations and myself)",
        "개념화 (정당화하기 위한 이유)":
          "Conceptualization (Reason to justify)",
        "거리두기 후 영향 정도": "Impact level after distancing",

        "MMM D일 ddd요일": "MMM D ddd",

        굴복: "Surrender",
        과잉보상: "Overcompensation",
        회피: "Avoidance",

        규칙: "Rule",
        태도: "Attitude",
        가정: "Assumption",

        "핵심 믿음": "Core belief",
        나: "Me",
        타인: "Others",
        세상: "The world",

        "스스로에 대해": "About myself",
        "다른 사람들 대해": "About others",
        "이 세상에 대해": "About the world",

        "영향 정도 placeholder":
          "0: Not at all, 100: Greatly affecting my life",
        "소감 placeholder": "This pops up in the coach's chat",
      },
      "0-0-A": translation_0_0_A.en,
      "0-2-A": translation_0_2_A.en,
      "0-3-A": translation_0_3_A.en,

      "1-1-A": translation_1_1_A.en,
      "1-2-A": translation_1_2_A.en,

      "2-1-A": translation_2_1_A.en,
      "2-2-T": translation_2_2_T.en,
      "0-1-A": translation_0_1_A.en,

      "1-3-A": translation_1_3_A.en,
      "3-2-A": translation_3_2_A.en,

      ...automaticThoughtIdList
        .map((each) => ({
          [`3-3-A_${each}`]: translation_3_3_A.en,
          [`4-2-A_${each}`]: translation_4_2_A.en,
          [`4-5-1-A_${each}`]: translation_4_5_1_A.en,
          [`4-5-2-A_${each}`]: translation_4_5_2_A.en,
          [`4-5-3-A_${each}`]: translation_4_5_3_A.en,
          [`4-5-4-A_${each}`]: translation_4_5_4_A.en,
          [`4-5-5-A_${each}`]: translation_4_5_5_A.en,
          [`4-5-6-A_${each}`]: translation_4_5_6_A.en,
          [`5-3-A_${each}`]: translation_5_3_A.en,
          [`5-4-A_${each}`]: translation_5_4_A.en,
          [`5-5-A_${each}`]: translation_5_5_A.en,
        }))
        .reduce(function (acc, obj) {
          // Object.keys(obj)를 사용하여 객체의 키를 가져옵니다.
          var keys = Object.keys(obj);

          // 각 키에 대해 acc 객체에 키와 값을 추가합니다.
          keys.forEach(function (key) {
            acc[key] = obj[key];
          });

          return acc;
        }, {}),

      "4-1-A": translation_4_1_A.en,
      "4-3-1-A": translation_4_3_1_A.en,
      "4-3-2-A": translation_4_3_2_A.en,

      "4-4-1-A": translation_4_4_1_A.en,
      "4-4-2-A": translation_4_4_2_A.en,
      "4-4-3-A": translation_4_4_3_A.en,
      "4-4-4-A": translation_4_4_4_A.en,
      "4-4-5-A": translation_4_4_5_A.en,
      "4-4-6-A": translation_4_4_6_A.en,

      "5-1-A": translation_5_1_A.en,
      "5-2-A": translation_5_2_A.en,

      "6-1-A": translation_6_1_A.en,
      "6-2-A": translation_6_2_A.en,
      "6-3-A": translation_6_3_A.en,

      "6-4-A": translation_6_4_A.en,
      "6-5-A": translation_6_5_A.en,
      "6-6-A": translation_6_6_A.en,
      "6-7-A": translation_6_7_A.en,
      "6-8-A": translation_6_8_A.en,
      "6-9-A": translation_6_9_A.en,

      "6-97-A": translation_6_97_A.en,
      "6-98-A": translation_6_98_A.en,
      "6-99-A": translation_6_99_A.en,
    },
  },
  payment: {
    "n일 뒤 구독이 만료되어요":
      "{{expiryDaysLeft}} days until your plan expires",
    "구독을 유지하고 프로그램을 이어가세요.":
      "Keep your plan and keep going with the program.",
    "구독이 만료되었어요": "Your plan has ended",
    "다시 구독하고 프로그램을 이어가세요.":
      "Renew your plan and keep going with the program.",
    "구독하고 이용해주세요": "Please subscribe and get started",
    "코치 선생님과 프로그램을 시작하세요.":
      "Start the program with your coach.",
    "결제 후 이용해주세요": "Access it after payment",
    "스스로를 깊게 이해해보세요": "Get to know yourself better",
    "변화를 꾸준히 트래킹하세요": "Keep track of your progress",
    "나만의 프로그램을 구성하세요": "ild your own program",
    "7일 무료 체험": "Try 7 days free",
    "월별 결제, 언제든 취소 가능": "Pay monthly, cancel anytime",
    "더 알아보기": "Learn more",

    "무료 체험 시작하기": "Start Free Trial",
    "구독 시작하기": "Start Using",
    "계속 이용하기": "Continue Using",
    "7일의 무료 체험 후 구독이 자동으로 시작됩니다.":
      "After the 7-day free trial, the plan kicks in automatically.",
    "7일의 무료 체험 후 월 이용권이 자동으로 갱신됩니다.":
      "After a 7-day free trial, Monthly Pass will automatically renew.",
    "생각과 거리두고\n가치로 나아가세요": "Better Mental Health,\nDistancing",
    "생각과 거리두고\n우울증에서 벗어나세요":
      "Distance yourself\nfrom depression",
    "디스턴싱은 우리 삶을\n변화시킵니다": "Distancing changes\nour lives",
    "이제 당신 차례입니다": "{{nickname}},\nNow, it's your turn",

    "월 n원": "${{amount}} Monthly",
    "한 달 이용권 n원": "Monthly Pass for ${{amount}}",
    "첫 달은 50% 할인된 가격에 이용하세요": "First month at 50% off",
    "한 달 동안 모든 프로그램을 무제한으로 이용하세요":
      "Unlimited access to all programs for a month",
    "코치와 함께 프로그램을 진행하세요":
      "Progress through the program with a coach",

    "부담없는 월별 결제": "Stress-Free Monthly Billing",
    "월별 결제": "Monthly Billing",
    "정신건강 전문 코치 선생님의 리딩": "Guidance from Expert Coaches",
    "정신건강 전문 AI 코치의 리딩": "Guidance from Expert AI Coaches",
    "전문 코치 선생님": "Expert Coaches",
    "전문 AI 코치": "Expert AI Coaches",
    "스스로 우울증을 해결하는 지속가능한 관리 도구":
      "Self-Help Tools for Long-Term care",
    "지속가능한 관리 도구": "Long-Term care",
    "체계적인 인지치료 프로그램": "Science-backed CBT Program",
    "체계적인 인지치료": "Science-backed",

    "스스로를 이해하고 한결 나에게 여유로워졌어요. 이제는 마음이 설레는 일도 시작하려고요.":
      "I've come to understand myself better and have made room for easier, more relaxed moments. I'm even starting to pursue things that excite me.",
    "코트니, 대학생": "Courtney, College Student",
    "편안한 나로 있는 방법을 터득했어요. 있는 그대로의 나의 모습을 보여주니 회사 사람들과의 인간관계도 편해졌어요.":
      "Distancing has taught me how to be comfortable in my own skin. Now, relationships with my coworkers are easier because I'm showing them the real me.",
    "보나, 사무직 근로자": "Bona, Office Worker",
    "하고 싶으면 하고, 가고 싶으면 가고, 먹고 싶으면 먹는 삶이 이렇게나 자유롭고 행복할 수 있다는 걸 알게 되어서 정말 기뻐요.":
      "Doing what I love, going places I want, and eating what I crave has opened my eyes to the freedom and happiness life offers.",
    "허니비, 기업 교육 전문가": "Honeybee, Corporate Trainer",
    "끊임없는 걱정의 악순환에서 벗어나니, 그 에너지를 더 소중한 일들에 투자할 수 있게 되었어요.":
      "Breaking free from constant worries has allowed me to focus my energy on things that really matter.",
    "도토리, SW 개발자": "Dotori, SW Developer",
    "현재의 순간에서 기쁨을 알아차리는 법과, 타인의 인정에서 벗어나 스스로를 사랑하고 돌보는 법을 알게 되었어요.":
      "I've learned to recognize joy in the present and understand that self-love and self-care isn't about getting approval from others.",
    "나다운, 브랜드 마케터": "Nadaun, Brand Marketer",
    "나쁜 평가를 두려워하며 아예 시작도 안하던 일들에 이제는 자신있게 도전하고 있어요.":
      "Instead of fearing negative feedback and never starting, now, I take on challenges confidently.",
    "냠냠, 인사 관리자": "Yumyum, HR Manager",

    디스턴싱: "Distancing",
    "상담 치료": "Counseling",
    "약물 치료": "Medication",
    "예약 없는 이용": "No Appointment",
    "비밀 보장": "Privacy Guaranteed",
    "데일리 관리": "Daily Care",
    "유연한 개인 대응": "Personalized Support",
    "최신 인지과학 기반": "Latest Cognitive Science Approach",
    "일상에서의 실습": "Everyday Practice",
    "약물 미사용": "No Medication",
    "종결 이후 관리": "Aftercare",

    "전체 기간은 얼마나 되나요?": "How long does the whole thing take?",
    "프로그램을 마치기까지 보통 3개월 정도 걸려요. 사람마다 다를 수는 있어요.":
      "It's usually a 3-month journey, but it varies per person.",
    "코치 선생님이 도와준다고요?": "Does the coach help out?",
    "AI 코치가 도와준다고요?": "Does the AI coach help out?",
    "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 전문 수련 과정을 마친 심리치료 전문가로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요.":
      "Your coach, a trained mental health professional, will guide you through daily activities and lead the program. You can work with your coach anonymously.",
    "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 디스턴싱 코칭 과정을 학습한 AI로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요.":
      "Your coach, a trained mental health AI, will guide you through daily activities and lead the program. You can work with your coach anonymously.",
    "누가 도움 받을 수 있나요?": "Who can this help?",
    "우울함, 수치심, 무기력, 폭식을 비롯한 식이장애, 불안감, 강박적인 걱정, 공황장애, 사회공포증, 발표불안, 자기 파괴적 완벽주의, 업무에 대한 부담감, 수면 문제 그리고 이외 복합적인 정서 문제에 도움이 될 수 있어요.":
      "It can help those dealing with depression, shame, lack of energy, binge eating, anxiety, obsessive worries, panic attacks, social anxiety, fear of public speaking, self-destructive perfectionism, work stress, sleep issues, and other complex emotional challenges.",
    "환청, 환각, 자살사고 등 시급한 의료적 접근이 필요한 분들의 경우 당장의 도움이 될 수 없어요. 단, 의료적 접근을 통해 위 문제들이 안정화가 된 상태에서는 보다 장기적이고 지속적인 변화를 위해 디스턴싱을 시작해봐도 괜찮아요.":
      "For urgent issues like hallucinations, delusions, or suicidal thoughts, immediate medical attention is needed, and this program might not be suitable right away. However, once stabilized medically, Distancing can be a great option for long-term, sustainable change.",
    "이런 경우 환불해드려요": "We offer refunds in these situations",
    "결제 후 서비스를 이용하지 않은 경우 언제든 환불이 가능해요. 결제 후 1회 이상 서비스를 이용한 경우 환불 금액은 판매자에게 문의해주세요.":
      "If you haven't used the service after paying, you can get a refund anytime. If you've used the service more than once after payment, please contact the seller for the refund details.",
    "서버 문제, 개발 오류 등 회사의 사정으로 인해 일주일을 초과하는 기간 동안 서비스가 원활하게 전개되지 않는 경우에는, 문제 발생 21일 전부터 문제 발생 시점까지 진행된 결제 건에 대해 모두 환불해드려요.":
      "If service disruptions last more than a week due to server issues or development errors, we'll refund all payments made from 21 days before the problem started until the issue is resolved.",
    "자세한 규정이 궁금해요": "Want to know more about the rules?",
    "자세한 내용은 서비스 이용약관과 개인정보 처리방침에서 확인해주세요.":
      "Check out the terms of service and privacy policy for detailed information.",
  },
};