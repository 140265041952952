import { useEffect, useState } from "react";
import { Stack, Textarea } from "@mui/joy";
import * as _ from "lodash";
import SaveConceptNoteWithReasonButton from "../../ai/SaveConceptNoteWithReasonButton";
import { ConceptFieldKeyType } from "../../../api/conceptApi";
import ReferenceListV2 from "./ReferenceListV2";

export default function ConceptNoteInput({
  referenceByConceptFieldKey,
  initialValue,
  onSave,
}: {
  referenceByConceptFieldKey?: ConceptFieldKeyType;
  initialValue?: string;
  onSave?: (updatedValue: string) => void;
}) {
  const [value, setValue] = useState<string>(initialValue ?? "");
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [modificationReason, setModificationReason] = useState<string>("");

  useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  useEffect(() => {
    setIsEdited(!_.isEqual((initialValue ?? "").trim(), value.trim()));
  }, [initialValue, value]);

  const handleUpdate = () => {
    // 상위로 새로운 값을 넘기기
    onSave?.(value);
  };

  return (
    <Stack spacing={0.5}>
      <Textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sx={{
          px: "10px",
          py: "12px",
          minHeight: "50px",
          width: "100%",
          borderWidth: "1px",
          borderColor: "divider",
          backgroundColor: "background.level1",
          boxShadow: "none",
          "--joy-focus-thickness": "0px",
          "--variant-borderWidth": "0px",
          "--Textarea-gap": "0px",
        }}
      />

      <Stack direction="row" sx={{ width: "100%" }} justifyContent="end">
        <Stack sx={{ flex: 1 }}>
          {(value || "").length > 0 && referenceByConceptFieldKey && (
            <ReferenceListV2 conceptFieldKey={referenceByConceptFieldKey!!} />
          )}
        </Stack>

        {isEdited && (
          <SaveConceptNoteWithReasonButton
            type={"textarea"}
            aiValue={initialValue}
            value={value}
            isRequireModificationReason={false}
            modificationReason={modificationReason}
            setModificationReason={setModificationReason}
            updateAction={handleUpdate}
          />
        )}
      </Stack>
    </Stack>
  );
}