export const translation_2_3_A = {
  en: {},
  ko: {
    v1: {
      typography_0:
        "자, 이제부터 생각과 거리를 두는 디스턴싱 연습을 시작할 텐데요.",
      typography_1: "디스턴싱의 기본은 ‘분리하기'에서 시작해요.",
      typography_2: "분리하기가 뭔지 조금 더 자세히 알아볼게요.",
      typography_3: "꾸러미로 떠오르는 생각",
      typography_4:
        "앞서 거리를 두고 마음속에 떠오르는 생각을 바라보는 것이 디스턴싱이라고 설명해 드렸는데요.",
      typography_5:
        "하지만 실제 우리가 마음속에서 마주하는 건 그보다 더 복잡하답니다.",
      typography_6:
        "사실 우리 마음속에서는 생각, 감정, 신체 반응, 행동 등이 복잡하게 뒤섞여 하나의 ‘꾸러미'처럼 떠올라요.",
      typography_7:
        "물론 때로는 그저 단편적인 생각들만 마음속에 떠오르는 것 같기도 해요.",
      typography_8: `“이따 밥 뭐 먹지?”, “오늘 피곤하네"`,
      typography_9:
        "이런 생각들을 인식하는 데에는 큰 어려움이 없고, 그렇기 때문에 그것을 거리두고 바라보기도 더 용이하죠.",
      typography_10:
        "하지만 제법 부정적인 생각을 마주했을 때는 상황이 조금 달라요.",
      typography_11: `“이번 프로젝트는 내가 다 망친 거야.”`,
      typography_12:
        "이런 생각이 마음속에 떠오르면 곧바로 아주 강렬한 감정이 찾아오죠.",
      typography_13: "죄책감, 후회, 미련, 분노, 슬픔, 자괴감.",
      typography_14: "때론 강력한 신체적인 감각이 동반되기도 해요.",
      typography_15: "두통이 찾아오고, 가슴이 답답하고, 호흡이 가빠오죠.",
      typography_16: "특정한 행동을 하고 싶은 충동도 느껴져요.",
      typography_17: "‘다 그만둘까?’, ‘퇴사하고 싶어'",
      typography_18:
        "이처럼 강렬한 감정을 마주했을 때 생각은 감정, 신체 반응, 행동과 함께 하나의 꾸러미처럼 떠오른답니다.",
      typography_19: "가장 먼저 해야 하는 일, 분리",
      typography_20:
        "하지만 마음속에 떠오른 생각을 꾸러미로 받아들이면 생각과 거리를 두는 일은 어렵게만 느껴진답니다.",
      typography_21:
        "정확히 어떤 생각이 마음속에 떠오르는지 이해하기도 힘들고,",
      typography_22: "또 그것이 주는 강렬한 감정이 너무 커보이기도 하거든요.",
      typography_23:
        "따라서 우리는 이 꾸러미를 각각의 개별 요소로 분리할 필요가 있어요.",
      typography_24: "이러한 과정을 분리하기(segmentation)라고 부른답니다.",
      typography_25: "방법은 간단해요.",
      typography_26: "어떤 강렬한 심리적 경험을 마주할 때,",
      typography_27: "그 내용을 일기장에 감정을 털어놓듯 무작정 옮기지 말고,",
      typography_28:
        "상황, 생각, 감정, 신체 반응, 행동을 나눠서 적어 보는 거예요.",
      typography_29:
        "이렇게 각각의 요소로 해체하여 관찰하면 각 요소를 그저 마음속에 떠오르는 하나의 ‘심리적 사건’으로 바라보기 더 쉽답니다.",
      typography_30:
        "그뿐만 아니라 앞으로 함께 초점을 맞춰볼 생각을 더 정확하게 알아차릴 수도 있을 거예요.",
      typography_31:
        "자, 다음 활동에서는 분리하기를 어떻게 하는 건지 함께 알아볼게요.",
    },
  },
};
