import { ProgramType } from "data/programData";
import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const MEDITATION_RECORD_DOMAIN = "meditation-record";

export const MEDITATION_RECORD_LOAD_ENDPOINT = "data/load";
export const MEDITATION_RECORD_SAVE_ENDPOINT = "data/save";
export const MEDITATION_RECORD_DONE_ENDPOINT = "done";
export const MEDITATION_RECORD_UNDONE_ENDPOINT = "undone";
export const MEDITATION_RECORD_REMOVE_ENDPOINT = "remove";
export const MEDITATION_RECORD_LIST_ENDPOINT = "list";
export const MEDITATION_RECORD_ANALYSIS_DETAIL_ENDPOINT = "analysis/detail";

interface LoadedDataType {
  data: ProgramType;
}

export type MeditationType = "practice" | "regulation";

export interface MeditationRecordType {
  meditationRecordKey: string;
  isDone?: boolean;
  title?: string;
  date?: string;
  isHighlight?: boolean;
  hasAiRecommendation?: boolean;
  lastDoneAt?: string;
  durationMinutes?: number;
  type?: MeditationType;
  shouldBeDone?: boolean; // 프론트에서만 씀
}

export interface MeditationAnalysisType {
  cumulativeByWeekNumber: { weekNumber: number; minutes: number }[];
  averageByWeekNumber: { weekNumber: number; minutes: number }[];
  goalDayOfWeekList: number[];
  historyList: { date: string; isDone: boolean }[];
}

export const meditationRecordApi = {
  getData: (mrk: string) =>
    axiosInstance.get<CustomResponseFormat<LoadedDataType>>(
      `${MEDITATION_RECORD_DOMAIN}/${MEDITATION_RECORD_LOAD_ENDPOINT}`,
      { params: { mrk: mrk } }
    ),
  saveData: (mrk: string, pid: string, body?: ProgramType) =>
    axiosInstance.post<void>(
      `${MEDITATION_RECORD_DOMAIN}/${MEDITATION_RECORD_SAVE_ENDPOINT}`,
      { data: body },
      {
        params: { mrk: mrk, pid: pid },
      }
    ),
  done: (mrk: string) =>
    axiosInstance.post<void>(
      `${MEDITATION_RECORD_DOMAIN}/${MEDITATION_RECORD_DONE_ENDPOINT}`,
      undefined,
      { params: { mrk: mrk } }
    ),
  undone: (mrk: string, pid: string) =>
    axiosInstance.post<void>(
      `${MEDITATION_RECORD_DOMAIN}/${MEDITATION_RECORD_UNDONE_ENDPOINT}`,
      undefined,
      { params: { mrk: mrk, pid: pid } }
    ),

  remove: (mrk: string) =>
    axiosInstance.post<void>(
      `${MEDITATION_RECORD_DOMAIN}/${MEDITATION_RECORD_REMOVE_ENDPOINT}`,
      undefined,
      { params: { mrk: mrk } }
    ),

  getList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<MeditationRecordType[]>>(
      `${MEDITATION_RECORD_DOMAIN}/${MEDITATION_RECORD_LIST_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  getAnalysisDetail: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<MeditationAnalysisType>>(
      `${MEDITATION_RECORD_DOMAIN}/${MEDITATION_RECORD_ANALYSIS_DETAIL_ENDPOINT}`,
      { params: { pid: pid } }
    ),
};