import Typography from "@mui/joy/Typography";
import { Stack } from "@mui/joy";
import ConceptNoteInput from "../ConceptNoteInput";
import {
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_CONCERN_AND_GOAL_DETAIL_ENDPOINT,
  conceptApi,
  ConceptFieldKey,
  ConcernAndGoal,
} from "../../../../api/conceptApi";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { patientAtom } from "../../../../recoil/patientAtom";

export default function ConcernAndGoalItem({
  concernAndGoal,
  setConcernAndGoal,
}: {
  concernAndGoal: ConcernAndGoal;
  setConcernAndGoal: React.Dispatch<
    React.SetStateAction<ConcernAndGoal | undefined>
  >;
  /** 변경사항을 상위로 전달(옵션) */
}) {
  const patientState = useRecoilValue(patientAtom);
  const queryClient = useQueryClient();

  const { mutate: updateNote } = useMutation<
    void,
    unknown,
    ConceptFieldKey
  >(
    async (payload) => {
      await conceptApi.noteUpdate(`${patientState?.patientId}`, payload);
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_CONCERN_AND_GOAL_DETAIL_ENDPOINT,
          patientState?.patientId,
        ]);
      },
      onError: (error) => {
        console.error("Note update failed:", error);
      },
    }
  );

  const handleSaveConcern = (newConcernValue: string) => {
    setConcernAndGoal((prev) => ({
      ...prev,
      concern: newConcernValue ?? prev!!.concern,
    }));
    updateNote({ concern: newConcernValue });
  };

  const handleSaveGoal = (newGoalValue: string) => {
    setConcernAndGoal((prev) => ({
      ...prev,
      goal: newGoalValue ?? prev!!.goal,
    }));
    updateNote({ goal: newGoalValue });
  };

  return (
    <Stack spacing={1}>
      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction={"row"} spacing={1}>
          <Typography level="body-xs">{"고민"}</Typography>
        </Stack>
      </Stack>

      <ConceptNoteInput
        initialValue={concernAndGoal.concern}
        onSave={handleSaveConcern}
      />

      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction={"row"} spacing={1}>
          <Typography level="body-xs">{"목표"}</Typography>
        </Stack>
      </Stack>

      <ConceptNoteInput
        initialValue={concernAndGoal.goal}
        onSave={handleSaveGoal}
      />
    </Stack>
  );
}