import {
  Header1,
  Instruction,
  InstructionWithImage,
} from "../../BlockComponent";
import { LastCard } from "../common";

export const Content_2_3_A = [
  Instruction("typography_0"),
  Instruction("typography_1"),
  Instruction("typography_2"),

  Header1("typography_3"),
  Instruction("typography_4"),
  Instruction("typography_5"),
  Instruction("typography_6"),
  Instruction("typography_7"),
  Instruction("typography_8"),

  Instruction("typography_9"),
  Instruction("typography_10"),

  Instruction("typography_11"),
  Instruction("typography_12"),
  Instruction("typography_13"),
  Instruction("typography_14"),

  Instruction("typography_15"),
  Instruction("typography_16"),
  Instruction("typography_17"),
  Instruction("typography_18"),

  Header1("typography_19"),
  Instruction("typography_20"),
  Instruction("typography_21"),
  Instruction("typography_22"),
  Instruction("typography_23"),
  Instruction("typography_24"),
  Instruction("typography_25"),

  Instruction("typography_26"),
  InstructionWithImage("typography_27", "1"),
  InstructionWithImage("typography_28", "2"),
  Instruction("typography_29"),
  Instruction("typography_30"),
  Instruction("typography_31"),

  ...LastCard,
];