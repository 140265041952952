import { AccordionGroup, Stack } from "@mui/joy";
import AccordionTemplate from "./AccordionTemplate";

export default function QnAV2() {
  return (
    <AccordionGroup
      sx={{
        display: "flex",
        "& .MuiAccordionSummary-button": {
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
          "&:hover, &:focus, &:active, &.Mui-focusVisible": {
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            outline: "none !important",
          },
        },
        // 혹시 다른 슬롯도 필요하면 추가
        "& .MuiAccordionSummary-content": {
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Stack gap="10px">
        <AccordionTemplate
          summary={"3개월 뒤에도 계속 이용할수 있나요?"}
          details={[
            "가능합니다.",
            <br />,
            <br />,
            "구글 플레이스토어의 경우 첫 세 달 이후 월 99,000원에 자동 갱신됩니다.",
            <br />,
            <br />,
            "애플 앱스토어의 경우 첫 세 달 이후 구독은 일시 정지되며, 월 99,000원에 구독을 다시 시작해주셔야 합니다.",
          ]}
        />
        <AccordionTemplate
          summary={"코치 선생님이 도와준다고요?"}
          details={
            "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 전문 수련 과정을 마친 심리치료 전문가로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요."
          }
        />
        <AccordionTemplate
          summary={"누가 도움 받을 수 있나요?"}
          details={[
            "우울함, 수치심, 무기력, 폭식을 비롯한 식이장애, 불안감, 강박적인 걱정, 공황장애, 사회공포증, 발표불안, 자기 파괴적 완벽주의, 업무에 대한 부담감, 수면 문제 그리고 이외 복합적인 정서 문제에 도움이 될 수 있어요.",
            <br />,
            <br />,
            "환청, 환각, 자살사고 등 시급한 의료적 접근이 필요한 분들의 경우 당장의 도움이 될 수 없어요. 단, 의료적 접근을 통해 위 문제들이 안정화가 된 상태에서는 보다 장기적이고 지속적인 변화를 위해 디스턴싱을 시작해봐도 괜찮아요.",
          ]}
        />
        <AccordionTemplate
          summary={"심리상담과 무엇이 다른가요?"}
          details={[
            "디스턴싱은 일주일에 한 번 진행되는 심리 상담과 달리 매일 진행되는 프로그램으로, 일상 속에서 자신의 마음을 돌볼 수 있도록 돕습니다. 최신 인지과학에 기반한 접근 방식을 통해 자기 주도적으로 인지 구조를 파악하고, 스스로 변화를 이끌 수 있도록 합니다.",
          ]}
        />
        <AccordionTemplate
          summary={"환불이 가능한가요?"}
          details={[
            "결제 이후 활동지를 마치시지 않은 경우에는 직전 결제 건 전액에 대해 환불이 가능합니다. 결제 이후 1회 이상 활동지를 마치신 경우에는 환불이 어렵습니다.",
            <br />,
            <br />,
            "환불 요청은 서비스 내 ‘고객센터'에서 접수해주시면 됩니다.",
          ]}
        />
        <AccordionTemplate
          summary={"200% 페이백이 가능한가요?"}
          details={[
            "첫 3개월을 모두 이용하신 뒤에도 변화를 느끼지 못하신 경우, 결제 금액의 200%를 페이백해드립니다.",
            <br />,
            <br />,
            "페이백 요청은 서비스 내 ‘고객센터'에서 접수해주시면 됩니다.",
            <br />,
            <br />,
            "첫 3개월 이후에는 200% 페이백 제도를 운영하고 있지 않습니다.",
          ]}
          noBorder
        />
      </Stack>
    </AccordionGroup>
  );
}