import {
  Box,
  ListItemButton,
  ListItemContent,
  Stack,
  Typography,
} from "@mui/joy";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { ThoughtRecordType } from "api/thoughtRecordApi";
import { memo, useEffect, useState } from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import RemoveThoughtRecordButton from "./RemoveThoughtRecordButton";

function ThoughtRecordListItem({
  width,
  direction = "column",
  isFirstRecordOfMonth,
  thoughtRecord,
  isSelected,
  setCurrentThoughtRecord,
  shouldBeDone,
}: {
  width: string;
  direction?: "row" | "column";
  isFirstRecordOfMonth: boolean;
  thoughtRecord: ThoughtRecordType;
  isSelected?: boolean;
  setCurrentThoughtRecord: React.Dispatch<
    React.SetStateAction<ThoughtRecordType | undefined>
  >;
  shouldBeDone?: boolean;
}) {
  const {
    thoughtRecordKey,
    date,
    situation,
    isHighlight,
    hasAiRecommendation,
  } = thoughtRecord;

  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const isManual = isCoach && user?.isCoachAiSupportDisabled;

  const hasAiComment = isCoach && !isManual && hasAiRecommendation;

  const [contextMenuOpenThoughtRecordKey, setContextMenuOpenThoughtRecordKey] =
    useState<string | undefined>(undefined);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      setContextMenuOpenThoughtRecordKey(undefined);
    };
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <Stack direction={direction} key={thoughtRecordKey}>
      {isFirstRecordOfMonth && (
        <Typography
          level="body-xs"
          sx={{
            px: "12px",
            py: "8px",
            opacity: "0.5",
            fontWeight: 500,
            whiteSpace: "nowrap",
          }}
        >
          {`${dayjs(date).format("MMM")}`}
        </Typography>
      )}
      <ListItemButton
        sx={{ width: width }}
        onClick={() => {
          setCurrentThoughtRecord(thoughtRecord);
          (document.activeElement as HTMLElement)?.blur();
        }}
        onContextMenu={(e) => {
          if (isCoach) {
            e.preventDefault();
            setContextMenuOpenThoughtRecordKey(thoughtRecordKey);
          }
        }}
      >
        {contextMenuOpenThoughtRecordKey === thoughtRecordKey && (
          <Box className="absolute z-[30] right-0 top-[50%]">
            <List
              sx={{
                borderWidth: 1,
                borderColor: "#background.level3",
                backgroundColor: "background.level2",
                "--List-gap": 0,
                "--ListItem-paddingY": 0,
              }}
            >
              <ListItem>
                <RemoveThoughtRecordButton
                  thoughtRecordKey={thoughtRecordKey}
                  thoughtRecordSummary={`${date} ${situation}`}
                  onClose={() => {
                    setContextMenuOpenThoughtRecordKey(undefined);
                  }}
                  onRemove={(removedKey) => {
                    // 현재 선택된 기록이라면 해제
                    setCurrentThoughtRecord((cur) =>
                      cur?.thoughtRecordKey === removedKey ? undefined : cur
                    );
                  }}
                />
              </ListItem>
            </List>
          </Box>
        )}
        <ListItemContent>
          <Box
            sx={{
              py: "12px",
              borderRadius: "8px",
              backgroundColor: isSelected ? "background.level2" : "transparent",
            }}
          >
            <Stack
              key={`thoughtRecord_${thoughtRecordKey}`}
              direction={"row"}
              alignItems="center"
              sx={{
                position: "relative",
              }}
            >
              {isHighlight && (
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    width: "4px",
                    height: "100%",
                    borderRadius: "4px",
                    backgroundColor: "primary.solid",
                  }}
                />
              )}

              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                sx={{
                  width: "48px",
                  minWidth: "48px",
                  mr: "8px",
                }}
              >
                <Typography
                  level={"title-lg"}
                  {...(shouldBeDone && { color: "danger" })}
                >
                  {dayjs(date).get("date")}
                </Typography>
                <Typography
                  sx={{ opacity: 0.5 }}
                  {...(shouldBeDone && { color: "danger" })}
                >
                  {dayjs(date).format("ddd")}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  flex: 1,
                  opacity: direction === "row" ? 0 : 1,
                  pr: hasAiComment ? "12px" : "20px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {situation}
              </Typography>
              {/* {hasAiComment && (
                <SvgIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    mr: "8px",
                    color: "primary.solid",
                  }}
                >
                  <AutoAwesomeIcon />
                </SvgIcon>
              )} */}
            </Stack>
          </Box>
        </ListItemContent>
      </ListItemButton>
    </Stack>
  );
}

export default memo(ThoughtRecordListItem);