import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CONCEPT_DOMAIN, CONCEPT_NOTE_AUTOMATIC_THOUGHT_DETAIL_ENDPOINT,
  CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
  conceptApi,
} from 'api/conceptApi'

import ButtonWithModal from "components/common/ButtonWithModal";
import Link from "components/common/Link";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function ResetAutomaticThoughtButton({
  automaticThoughtId,
  thoughtRecordKey,
  isForValue = false,
}: {
  automaticThoughtId: string;
  thoughtRecordKey?: string,
  isForValue?: boolean
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const resetAutomaticThoughtQueryFn = useCallback(
    () =>
      conceptApi.resetAutomaticThought(
        `${patientState?.patientId}`,
        `${automaticThoughtId}`,
        isForValue
      ),
    [patientState?.patientId, automaticThoughtId, isForValue]
  );

  const { mutate: deleteAutomaticThought } = useMutation(
    resetAutomaticThoughtQueryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
          patientState?.patientId,
        ]);

        if (thoughtRecordKey) {
          queryClient.invalidateQueries([
            CONCEPT_DOMAIN,
            CONCEPT_NOTE_AUTOMATIC_THOUGHT_DETAIL_ENDPOINT,
            patientState?.patientId,
            thoughtRecordKey,
          ]);
        }
      },
    }
  );

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={"이 자동적 사고를 초기화할까요? 진행 중인 활동지들은 모두 초기화됩니다."}
      render={<Link text="초기화" opacity={1} textColor="primary.solid" />}
      actionButtonColor={"danger"}
      actionText={"초기화하기"}
      action={deleteAutomaticThought}
    />
  );
}