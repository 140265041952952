import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const PROMOTION_DOMAIN = "promotion";
export const PROMOTION_BENEFIT_REQUEST_SUBMIT_ENDPOINT =
  "benefit-request/submit";

export const promotionApi = {
  submitPhoneNumber: (uid: string, type: string, phoneNumber: string) =>
    axiosInstance.post<CustomResponseFormat<void>>(
      `${PROMOTION_DOMAIN}/${PROMOTION_BENEFIT_REQUEST_SUBMIT_ENDPOINT}`,
      {
        type: type,
        phoneNumber: phoneNumber,
      },
      { params: { uid: uid } }
    ),
};