import { useCallback, useEffect, useState } from "react";
import TaskHeader from "components/task/header/TaskHeader";
import TaskContent from "components/task/content/TaskContent";
import { Box, Stack } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import ThoughtRecordList from "./ThoughtRecordList";
import useGetThoughtRecordData from "hooks/useGetThoughtRecordData";
import { hasAiRecommendationBlock, isAllUserFieldFilled } from "logic/logics";
import {
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
  thoughtRecordApi,
  ThoughtRecordType,
} from "api/thoughtRecordApi";
import useInterval from "hooks/useInterval";
import { useRecoilState, useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { userAtom } from "recoil/userAtom";
import useGetCertainEventFromApp from "hooks/useGetCertainEventFromApp";
import useOpenNewThoughtRecord from "hooks/useOpenNewThoughtRecord";
import { useNavigate, useSearchParams } from "react-router-dom";
import useIsInappWebview from "hooks/useIsInappWebview";
import { selectedThoughtRecordKeyAtom } from "../../../recoil/selectedThoughtRecordAtom";

export default function ThoughtRecord({ taskKey }: { taskKey: string }) {
  const [searchParams] = useSearchParams();
  const currentThoughtRecordKey = searchParams.get("trk");

  const patientState = useRecoilValue(patientAtom);
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";
  const isManual = isCoach && user.isCoachAiSupportDisabled;

  const isTaskContainerSmall = useIsInappWebview();
  const [data, setData] = useState<ProgramContentType[] | undefined>();
  const [prevData, setPrevData] = useState<ProgramContentType[] | undefined>();

  const [thoughtRecordList, setThoughtRecordList] = useState<
    ThoughtRecordType[] | undefined
  >();
  const navigate = useNavigate();

  const [currentThoughtRecord, setCurrentThoughtRecord] = useState<
    ThoughtRecordType | undefined
  >();

  const [selectedThoughtRecordKey, setSelectedThoughtRecordKey] =
    useRecoilState(selectedThoughtRecordKeyAtom);

  useEffect(() => {
    if (thoughtRecordList) {
      if (currentThoughtRecordKey) {
        setCurrentThoughtRecord(
          thoughtRecordList.find(
            (element) => element.thoughtRecordKey === currentThoughtRecordKey
          ) || {
            thoughtRecordKey: currentThoughtRecordKey,
          }
        );

        navigate(`${window.location.pathname}`, { replace: true });
      }
    }
  }, [thoughtRecordList, currentThoughtRecordKey]);

  const [isEmpty, setIsEmpty] = useState<boolean | undefined>(undefined);

  const [hasAiCommentTrkList, setHasAiCommentTrkList] = useState<string[]>([]);

  const {
    data: fetchedData,
    refetch,
    isFetching,
  } = useGetThoughtRecordData({
    trk: currentThoughtRecord?.thoughtRecordKey,
    isFirst: isEmpty,
    enabled: !!thoughtRecordList,
  });

  // useInterval(
  //   () => {
  //     setPrevData(data);
  //     //변화 없으면 다시 가져와
  //     if (data && prevData && _.isEqual(data, prevData)) {
  //       refetch();
  //     }
  //   },
  //   10000,
  //   []
  // );


  const refetchActivityEvent = useGetCertainEventFromApp({
    eventName: "refetch_activity",
  });

  useEffect(() => {
    if (refetchActivityEvent) {
      refetch();
    }
  }, [refetchActivityEvent]);

  //기록 추가한 직후
  useEffect(() => {
    if (
      currentThoughtRecord &&
      !thoughtRecordList?.find(
        (thoughtRecord) =>
          thoughtRecord.thoughtRecordKey ===
          currentThoughtRecord?.thoughtRecordKey
      )
    ) {
      setThoughtRecordList((list) => [
        ...(list || []),
        { ...currentThoughtRecord, shouldBeDone: false },
      ]);
    }
  }, [currentThoughtRecord]);

  const patientId = patientState?.patientId; // 원시값으로 추출

  const getThoughtRecordListqueryFn = useCallback(
    () => thoughtRecordApi.getList(`${patientId}`),
    [patientId]
  );

  useEffect(() => {
    if (currentThoughtRecord?.thoughtRecordKey) {
      setSelectedThoughtRecordKey(currentThoughtRecord.thoughtRecordKey!!);
    }
  }, [currentThoughtRecord]);

  useEffect(() => {
    return () => {
      setSelectedThoughtRecordKey(undefined);
    };
  }, []);

  const openNewThoughtRecord = useOpenNewThoughtRecord();

  useQuery(
    [THOUGHTRECORD_DOMAIN, THOUGHTRECORD_LIST_ENDPOINT],
    getThoughtRecordListqueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (data) => {
        const list = data.result?.map((each) => ({
          ...each,
          shouldBeDone: each?.isDone
            ? false
            : thoughtRecordList?.find(
                (element) => element.thoughtRecordKey === each.thoughtRecordKey
              )?.shouldBeDone,
        }));
        if (
          currentThoughtRecord &&
          !list?.find(
            (thoughtRecord) =>
              thoughtRecord.thoughtRecordKey ===
              currentThoughtRecord?.thoughtRecordKey
          )
        ) {
          list?.push({ ...currentThoughtRecord, shouldBeDone: false });
        }

        setThoughtRecordList((prevList) => {
          if (_.isEqual(prevList, list)) return prevList;
          return list;
        });

        if (!list || list.length === 0) {
          setIsEmpty(true);
          if (!currentThoughtRecord) {
            openNewThoughtRecord();
          }
        } else {
          list.sort((a, b) => {
            return +new Date(b.date || "") - +new Date(a.date || "");
          });
          setIsEmpty(false);
          setHasAiCommentTrkList(
            list
              .filter((each) => each.hasAiRecommendation)
              .map((each) => each.thoughtRecordKey)
          );
          let currentIndex = 0;
          if (!currentThoughtRecord) {
            const incompleteDataIndex = isCoach
              ? list.findIndex((element) => element.isHighlight)
              : list.findIndex((element) => !element.isDone);
            currentIndex = Math.max(incompleteDataIndex, 0);
          } else {
            currentIndex = list.findIndex(
              (element) =>
                element.thoughtRecordKey ===
                currentThoughtRecord.thoughtRecordKey
            );
          }
          setCurrentThoughtRecord(list[currentIndex]);
        }
      },
    }
  );

  useEffect(() => {
    if (data && data.length > 0 && currentThoughtRecord?.thoughtRecordKey) {
      const thoughtRecordKey = currentThoughtRecord?.thoughtRecordKey;
      if (hasAiRecommendationBlock(data)) {
        if (!hasAiCommentTrkList.includes(thoughtRecordKey)) {
          setHasAiCommentTrkList((hasAiCommentTrkList) =>
            hasAiCommentTrkList.concat([thoughtRecordKey])
          );
        }
      } else {
        setHasAiCommentTrkList((hasAiCommentTrkList) =>
          hasAiCommentTrkList.filter((element) => element !== thoughtRecordKey)
        );
      }
    }
    if (currentThoughtRecord) {
      if (
        currentThoughtRecord.lastDoneAt &&
        !currentThoughtRecord.isDone &&
        isAllUserFieldFilled(data, false)
      ) {
        setThoughtRecordList((thoughtRecordList) => {
          if (thoughtRecordList) {
            const data_temp = _.cloneDeep(thoughtRecordList);
            data_temp.splice(
              data_temp.findIndex(
                (element) =>
                  element.thoughtRecordKey ===
                  currentThoughtRecord?.thoughtRecordKey
              ),
              1,
              { ...currentThoughtRecord, shouldBeDone: true }
            );

            return data_temp;
          }
          return thoughtRecordList;
        });
      } else {
        setThoughtRecordList((thoughtRecordList) => {
          if (thoughtRecordList) {
            const data_temp = _.cloneDeep(thoughtRecordList);
            data_temp.splice(
              data_temp.findIndex(
                (element) =>
                  element.thoughtRecordKey ===
                  currentThoughtRecord?.thoughtRecordKey
              ),
              1,
              { ...currentThoughtRecord, shouldBeDone: false }
            );

            return data_temp;
          }
          return thoughtRecordList;
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (fetchedData) {
      if (!_.isEqual(fetchedData.content, data)) {
        setData(fetchedData.content);
      }
    }
  }, [fetchedData]);

  const [moveToIndex, setMoveToIndex] = useState<number | undefined>(undefined);

  return (
    <>
      <Box
        sx={{
          flex: "none",
          zIndex: 100,
          // px: { xs: "16px", sm: "24px" },
        }}
      >
        <TaskHeader
          taskKey={taskKey}
          thoughtRecordKey={currentThoughtRecord?.thoughtRecordKey}
          // title={`${dayjs(currentThoughtRecord?.date).format(
          //   t_common("MMM D일 ddd요일")
          // )}`}
          title={`생각기록지`}
          contentData={data}
          setContentData={setData}
          setMoveToIndex={setMoveToIndex}
          isDone={currentThoughtRecord?.isDone}
          lastDoneAt={currentThoughtRecord?.lastDoneAt}
          {...(isTaskContainerSmall && {
            extraContent: (
              <ThoughtRecordList
                thoughtRecordList={thoughtRecordList}
                currentThoughtRecord={currentThoughtRecord}
                setCurrentThoughtRecord={setCurrentThoughtRecord}
                hasAiCommentTrkList={hasAiCommentTrkList}
              />
            ),
          })}
        />
      </Box>
      <Stack sx={{ flex: 1, overflow: "hidden" }} direction={"row"}>
        {!isTaskContainerSmall && (
          <ThoughtRecordList
            thoughtRecordList={thoughtRecordList}
            currentThoughtRecord={currentThoughtRecord}
            setCurrentThoughtRecord={setCurrentThoughtRecord}
            hasAiCommentTrkList={hasAiCommentTrkList}
          />
        )}

        {currentThoughtRecord?.thoughtRecordKey && (
          <Stack sx={{ flex: 1, overflow: "hidden" }}>
            <TaskContent
              taskKey={taskKey}
              thoughtRecordKey={currentThoughtRecord?.thoughtRecordKey}
              data={data}
              setData={setData}
              moveToIndex={moveToIndex}
              setMoveToIndex={setMoveToIndex}
              isDone={currentThoughtRecord?.isDone}
              translationVersion={fetchedData?.translationVersion}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
}