import { useCallback, useEffect, useRef } from "react";
import {
  getHighlightedBlockNumber,
  getReferenceData,
  getTaskIdFromTaskKey,
} from "logic/logics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskApi } from "api";
import { ProgramContentType } from "data/BlockComponent";
import { usePrevious } from "@uidotdev/usehooks";
import * as _ from "lodash";
import {
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
  thoughtRecordApi,
} from "api/thoughtRecordApi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import { programDataAtom } from "recoil/programDataAtom";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";
import {
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
  meditationRecordApi,
} from "api/meditationRecordApi";

export default function useSaveContentData({
  taskKey,
  data,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  translationVersion,
  isFetching,
}: {
  taskKey: string;
  data?: ProgramContentType[];
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  translationVersion?: string;
  isFetching?: boolean;
}) {
  const user = useRecoilValue(userAtom);
  const previousData = usePrevious(data);
  const isThoughtRecord = taskKey?.includes("2-2-T") && thoughtRecordKey;
  const isActivityRecord = activityRecordKey !== undefined;
  const isMeditationRecord = meditationRecordKey !== undefined;

  const patientState = useRecoilValue(patientAtom);
  const setProgramDataState = useSetRecoilState(programDataAtom);
  const taskId = getTaskIdFromTaskKey(taskKey);
  const title = useGetTaskTitleFromTaskKey(taskKey);
  const queryClient = useQueryClient();

  const saveActivityQueryFn = useCallback(
    () =>
      taskApi.saveData(`${taskKey}`, {
        taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
        title: title,
        content: data || [],
        translationVersion: translationVersion,
      }),
    [data]
  );

  const { mutate: saveActivityData } = useMutation(saveActivityQueryFn);

  const saveThoughtRecordQueryFn = useCallback(
    (isRefetchList: boolean) =>
      thoughtRecordApi.saveData(
        `${thoughtRecordKey}`,
        `${patientState?.patientId}`,
        {
          taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
          title: title,
          content: data || [],
          translationVersion: translationVersion,
          thoughtRecordKey: thoughtRecordKey,
        }
      ),
    [data]
  );

  const { mutate: saveThoughtRecordData } = useMutation(
    saveThoughtRecordQueryFn,
    {
      onSuccess: (_, isRefetchList) => {
        if (isRefetchList) {
          setTimeout(() => {
            queryClient.invalidateQueries([
              THOUGHTRECORD_DOMAIN,
              THOUGHTRECORD_LIST_ENDPOINT,
            ]);
          }, 3000);
        }
      },
    }
  );

  const saveMeditationRecordQueryFn = useCallback(
    (isRefetchList: boolean) =>
      meditationRecordApi.saveData(
        `${meditationRecordKey}`,
        `${patientState?.patientId}`,
        {
          taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
          title: title,
          content: data || [],
          translationVersion: "v1",
        }
      ),
    [data]
  );

  const { mutate: saveMeditationRecordData } = useMutation(
    saveMeditationRecordQueryFn,
    {
      onSuccess: (_, isRefetchList) => {
        if (isRefetchList) {
          queryClient.invalidateQueries([
            MEDITATION_RECORD_DOMAIN,
            MEDITATION_RECORD_LIST_ENDPOINT,
          ]);
        }
      },
    }
  );

  function isEqualExceptImpactScoreAfter(
    previousData: ProgramContentType[] | undefined,
    data: ProgramContentType[] | undefined
  ) {
    const removeImpactScoreAfter = (data?: ProgramContentType[]) =>
      data?.filter((block) => block.blockId !== "impactScore_after");

    return _.isEqual(
      removeImpactScoreAfter(previousData),
      removeImpactScoreAfter(data)
    );
  }

  function save(data: ProgramContentType[]) {
    if (user?.accessToken) {
      if (isThoughtRecord) {
        const previousTrk = getReferenceData(
          previousData || [],
          "thoughtRecordKey"
        )?.value;

        const currentTrk = getReferenceData(
          data || [],
          "thoughtRecordKey"
        )?.value;
        if (
          previousTrk === currentTrk &&
          currentTrk === thoughtRecordKey &&
          !isEqualExceptImpactScoreAfter(previousData, data)
        ) {
          const trk = getReferenceData(data || [], "thoughtRecordKey")?.value;
          const isRefetchList =
            previousTrk === trk &&
            ([
              "sentiment",
              "date",
              "situation",
              "situation_indirect",
              "situation_direct",
            ].some(
              (id) =>
                !_.isEqual(
                  getReferenceData(previousData || [], id)?.value,
                  getReferenceData(data || [], id)?.value
                )
            ) ||
              getHighlightedBlockNumber(data || []) !==
                getHighlightedBlockNumber(previousData || []));

          if (!isEqualExceptImpactScoreAfter(previousData, data)) {
            console.warn(JSON.stringify(previousData))
            console.error(JSON.stringify(data))
          }

          saveThoughtRecordData(isRefetchList);

          if (
            data
              .flat()
              .find((e) =>
                e.lines?.flat()?.find((a) => a?.content?.id === "고민")
              )
          ) {
            saveActivityData();
          }
        }
      } else if (isMeditationRecord) {
        if (
          getReferenceData(data || [], "meditationRecordKey")?.value ===
            meditationRecordKey &&
          !_.isEqual(previousData, data)
        ) {
          const previousMrk = getReferenceData(
            previousData || [],
            "thoughtRecordKey"
          )?.value;
          const mrk = getReferenceData(data || [], "thoughtRecordKey")?.value;
          const isRefetchList =
            previousMrk === mrk &&
            (["coping_or_routine"].some(
              (id) =>
                !_.isEqual(
                  getReferenceData(previousData || [], id)?.value,
                  getReferenceData(data || [], id)?.value
                )
            ) ||
              getHighlightedBlockNumber(data || []) !==
                getHighlightedBlockNumber(previousData || []));

          saveMeditationRecordData(isRefetchList);
        }
      } else {
        saveActivityData();
      }
    }
  }

  useEffect(() => {
    if (isFetching) return;

    if (data && data.length > 0) {
      save(data);
    }
  }, [data, thoughtRecordKey, isFetching]);
}
