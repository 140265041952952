import { Stack } from "@mui/joy";
import TaskSideBar from "./TaskSideBar";
import PatientSideBar from "./PatientSideBar";

export default function SidebarLayout() {
  return (
    <Stack direction="row" sx={{ height: "100vh", overflow: "hidden" }}>
      <PatientSideBar />
      <TaskSideBar />
    </Stack>
  );
}