import { isAllCoachFieldFilled } from "logic/logics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TASK_DOMAIN, TASK_PRESET_DETAIL_ENDPOINT, taskApi } from "api";
import { ProgramContentType } from "data/BlockComponent";
import {
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
  thoughtRecordApi,
} from "api/thoughtRecordApi";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import {
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
  meditationRecordApi,
} from "api/meditationRecordApi";
import useRefetchTaskList from "hooks/useRefetchTaskList";
import React, { useEffect, useState } from "react";
import useGetBlankTaskData from "hooks/useGetBlankTaskData";
import { ProgramType } from "../../../data/programData";
import ButtonWithModalDoubleAction from "../../common/ButtonWithModalDoubleAction";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import { Box, Button, Typography } from "@mui/joy";

export default function TaskUndoneButton({
  taskKey,
  setData,
  contentData,
  setContentData,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  isDone = false,
  isInitialized,
}: {
  taskKey: string;
  setData?: React.Dispatch<React.SetStateAction<ProgramType | undefined>>;
  contentData?: ProgramContentType[];
  setContentData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  isDone?: boolean;
  isInitialized?: boolean;
}) {
  const isThoughtRecord = taskKey.includes("2-2-T") && thoughtRecordKey;
  const isMeditationRecord = meditationRecordKey !== undefined;
  const queryClient = useQueryClient();

  const blankTaskData = useGetBlankTaskData({ taskKey: taskKey });

  const patientState = useRecoilValue(patientAtom);

  const refetchTaskList = useRefetchTaskList();

  const [disabled, setDisabled] = useState<boolean>(true);
  const [openConfirmResetModal, setOpenConfirmResetModal] = useState(false);

  useEffect(() => {
    setDisabled(!(contentData && isDone && isAllCoachFieldFilled(contentData)));
  }, [contentData, isDone]);

  const { mutate: activityUndone } = useMutation(
    () => taskApi.undone(`${taskKey}`),
    {
      onSuccess: () => {
        refetchTaskList();
      },
    }
  );

  const { mutate: thoughtRecordUndone } = useMutation(
    () =>
      thoughtRecordApi.undone(
        `${thoughtRecordKey}`,
        `${patientState?.patientId}`
      ),
    {
      onSuccess: () => {
        refetchTaskList();
        queryClient.invalidateQueries([
          THOUGHTRECORD_DOMAIN,
          THOUGHTRECORD_LIST_ENDPOINT,
        ]);
      },
    }
  );

  const { mutate: meditationRecordUndone } = useMutation(
    () =>
      meditationRecordApi.undone(
        `${meditationRecordKey}`,
        `${patientState?.patientId}`
      ),
    {
      onSuccess: () => {
        refetchTaskList();
        queryClient.invalidateQueries([
          MEDITATION_RECORD_DOMAIN,
          MEDITATION_RECORD_LIST_ENDPOINT,
        ]);
      },
    }
  );

  useEffect(() => {
    if (setData && isInitialized) {
      setData(blankTaskData);
    }
  }, [isInitialized]);

  const { mutate: activityReset } = useMutation(
    () => taskApi.reset(`${taskKey}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          TASK_DOMAIN,
          TASK_PRESET_DETAIL_ENDPOINT,
          taskKey,
        ]);
      },
    }
  );

  function handleResetAndUndone() {
    if (setData) {
      setData(undefined);
    }
    activityReset();
    handleUndone();
  }

  function handleUndone() {
    if (isThoughtRecord) {
      thoughtRecordUndone();
    } else if (isMeditationRecord) {
      meditationRecordUndone();
    } else {
      activityUndone();
    }
  }

  return (
    <>
      <ButtonWithModalDoubleAction
        buttonText={"다시 주기"}
        size="sm"
        variant="solid"
        color="primary"
        title={`${
          isThoughtRecord
            ? "생각기록지를 다시 열어줄까요?"
            : isMeditationRecord
              ? "명상훈련지를 다시 열어줄까요?"
              : "활동지를 다시 열어줄까요?"
        }`}
        disabled={disabled}
        disableModalOpen={disabled}
        actionPrimary={() => handleUndone()}
        actionPrimaryText="다시 주기"
        {...(!isThoughtRecord &&
          !isMeditationRecord && {
            actionSecondary: () => setOpenConfirmResetModal(true),
            actionSecondaryText: "초기화해서 다시 주기",
          })}
      />
      {/* 추가 확인 모달 TODO 리팩토링*/}
      <Modal
        open={openConfirmResetModal}
        onClose={() => setOpenConfirmResetModal(false)}
        sx={{
          "&>.MuiModal-backdrop": {
            backdropFilter: "none",
            backgroundColor: "rgba(0,0,0,0.3)",
          },
        }}
      >
        <ModalDialog>
          <Typography variant="Subtitle" sx={{ mb: 1, fontWeight: 700 }}>
            {"정말로 초기화할까요?"}
          </Typography>
          <Typography variant="Body">
            {"*초기화하면 복구할 수 없습니다."}
          </Typography>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button
              size={"lg"}
              variant="solid"
              color="danger"
              onClick={() => {
                handleResetAndUndone();
                setOpenConfirmResetModal(false);
              }}
              sx={{ width: "auto" }}
            >
              초기화 하기
            </Button>
            <Button
              variant="plain"
              onClick={() => setOpenConfirmResetModal(false)}
            >
              취소
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}