import { Box, Stack } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import PatientList from "components/patient-list/PatientList";
import useIsInappWebview from "hooks/useIsInappWebview";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import UserDropDown from "../user/UserDropDown";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import CoachFilter from "../patient-list/CoachFilter";

export default function PatientSideBar() {
  const user = useRecoilValue(userAtom);
  const isInappWebview = useIsInappWebview();
  const backgroundColor = isInappWebview
    ? "background.level1"
    : "background.level2";

  // CoachFilter 상태 관리
  const [selectedCoach, setSelectedCoach] = useState<string | undefined>(
    undefined
  );
  const [coachList, setCoachList] = useState<string[]>([]);

  return (
    <Sheet
      sx={{
        width: "220px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.level2",
        overflow: "hidden",
      }}
    >
      {/* 헤더 영역 추가 */}
      <Box
        sx={{
          width: "100%",
          minHeight: "var(--StickyHeader-height)",
          height: "var(--StickyHeader-height)",
          position: "sticky",
          top: 0,
          backgroundColor: backgroundColor,
          borderBottom: 1,
          borderColor: "divider",
          zIndex: 10,
          px: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {user?.role === "admin" && coachList.length > 0 ? (
              <CoachFilter
                coachList={coachList}
                selectedCoach={selectedCoach}
                setSelectedCoach={setSelectedCoach}
              />
            ) : (
              <Typography level="title-md">내담자 목록</Typography>
            )}
          </Stack>
        </Stack>
      </Box>

      {/* PatientList 영역 (스크롤 영역) */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          px: "10px",
          py: "10px",
          minHeight: 0,
          "&::-webkit-scrollbar": {
            width: "6px",
            opacity: 0,
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: "3px",
          },
          "&:hover::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.3)",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgba(0,0,0,0.4)",
          },
        }}
      >
        <PatientList
          backgroundColor={backgroundColor}
          selectedCoach={selectedCoach}
          setCoachList={setCoachList}
        />
      </Box>

      {/* 하단 UserDropDown 영역 (고정 영역) */}
      <Box
        sx={{
          borderTop: "1px solid",
          borderColor: "divider",
          px: "10px",
          py: "10px",
          flexShrink: 0,
        }}
      >
        <Stack direction={"row"} justifyContent="space-between">
          {user && user.accessToken && <UserDropDown user={user} />}
        </Stack>
      </Box>
    </Sheet>
  );
}