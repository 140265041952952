import React, { useState } from "react";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { isAllCoachFieldFilled, isAllUserFieldFilled } from "logic/logics";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { Box } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import TaskProgressBar from "./progressbar/TaskProgressBar";
import {
  AddConceptNoteButton,
  DoneButton,
  TaskUndoneButton,
} from "components/task/change-status-button";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";
import { ProgramType } from "data/programData";
import { taskListAtom } from "recoil/taskListAtom";
import BackStyleSidebarOpenButton from "./BackStyleSidebarOpenButton";
import BackButton from "../../common/BackButton";
import useIsLatest from "hooks/useIsLatest";
import useGoBack from "hooks/useGoBack";
import AddCommentButton from "../change-status-button/AddCommentButton";

export default function TaskHeader({
  taskKey,
  title,
  contentData,
  setContentData,
  setData,
  setMoveToIndex,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  isDone,
  isInitialized,
  extraContent,
  lastDoneAt,
}: {
  taskKey: string;
  title?: string;
  contentData?: ProgramContentType[];
  setContentData?: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  setData?: React.Dispatch<React.SetStateAction<ProgramType | undefined>>;
  setMoveToIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  isDone?: boolean;
  isInitialized?: boolean;
  extraContent?: React.ReactNode;
  lastDoneAt?: string;
}) {
  const hasData = !!contentData;
  const isThoughtRecord = taskKey.includes("2-2-T") && thoughtRecordKey;
  const isMyConcernTask = taskKey.includes("1-1-A");
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";

  const taskTitle = useGetTaskTitleFromTaskKey(taskKey);

  const taskListState = useRecoilValue(taskListAtom);
  const isOpen = taskListState?.find(
    (element) => element.taskKey === taskKey
  )?.isOpen;

  const [isAICommentEmpty, setIsAiCommentEmpty] = useState<boolean>(false);

  const isLatest = useIsLatest();
  const goBack = useGoBack();

  return (
    <>
      <Stack>
        <Stack
          direction={"column"}
          sx={{
            px: 0,
            height: "var(--StickyHeader-height)",

            ...(!hasData && {
              borderBottomWidth: "1px",
              borderColor: "divider",
            }),
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            spacing={"8px"}
            sx={{
              flex: 1,
              height: "100%",
              px: "20px",
            }}
          >
            {isLatest ? (
              <BackButton onBack={goBack} />
            ) : (
              <BackStyleSidebarOpenButton />
            )}
            <Stack
              direction="row"
              alignItems={"center"}
              spacing={1}
              flexGrow={1}
            >
              {isCoach &&
                contentData &&
                !isAllCoachFieldFilled(contentData) && (
                  <Box
                    sx={{
                      color: "danger.500",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span className="material-symbols-outlined ">error</span>
                  </Box>
                )}
              <Stack direction="row" alignItems={"center"} spacing={2}>
                <Typography
                  level="title-md"
                  flexGrow={1}
                  sx={{
                    fontWeight: 700,
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {title || taskTitle}
                </Typography>
              </Stack>
            </Stack>

            {hasData && (
              <Stack
                direction="row"
                alignItems={"center"}
                spacing={1.5}
                sx={{ py: 0 }}
              >
                {!isCoach ? (
                  <>
                    {thoughtRecordKey &&
                      lastDoneAt &&
                      !isDone &&
                      isAllUserFieldFilled(contentData, false) && (
                        <Typography
                          level="body-xs"
                          color="danger"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          마치기를 눌러주세요
                        </Typography>
                      )}
                    <DoneButton
                      taskKey={taskKey}
                      data={contentData}
                      thoughtRecordKey={thoughtRecordKey}
                      activityRecordKey={activityRecordKey}
                      meditationRecordKey={meditationRecordKey}
                      isDone={isDone}
                      size="sm"
                    />
                  </>
                ) : (
                  <>
                    {(isThoughtRecord || isMyConcernTask) && (
                      <AddCommentButton
                        taskKey={taskKey}
                        thoughtRecordKey={thoughtRecordKey}
                        setContentData={setContentData!!}
                        setMoveToIndex={setMoveToIndex!!}
                        setIsAiCommentEmpty={setIsAiCommentEmpty}
                      />
                    )}
                    {isThoughtRecord && (
                      <AddConceptNoteButton
                        thoughtRecordKey={thoughtRecordKey!!}
                      />
                    )}
                    {setContentData && (
                      <TaskUndoneButton
                        taskKey={taskKey}
                        setData={setData}
                        contentData={contentData}
                        setContentData={setContentData}
                        thoughtRecordKey={thoughtRecordKey}
                        activityRecordKey={activityRecordKey}
                        meditationRecordKey={meditationRecordKey}
                        isDone={isDone}
                        isInitialized={isInitialized}
                      />
                    )}
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        {extraContent}
      </Stack>
      {setMoveToIndex && (
        <TaskProgressBar
          taskKey={taskKey}
          data={contentData}
          setMoveToIndex={setMoveToIndex}
        />
      )}
    </>
  );
}