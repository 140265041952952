import { Button, VariantProp } from "@mui/joy";
import { useState } from "react";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsVariantOverrides } from "@mui/joy/Button/ButtonProps";
import { SxProps } from "@mui/joy/styles/types";

export default function InstallDeepLinkButton({
  variant = "Action",
  sx,
  buttonText,
  deeplink,
}: {
  variant?: OverridableStringUnion<VariantProp, ButtonPropsVariantOverrides>;
  sx?: SxProps;
  buttonText: string;
  deeplink: string;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false); //중복 방지
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleClick = () => {
    window.open(deeplink, "_blank");
  };

  return (
    <Button
      loading={isLoading}
      disabled={disabled}
      onClick={handleClick}
      variant={variant}
      sx={{
        maxWidth: "345px",
        height: "54px",
        fontWeight: 700,
        borderRadius: "12px",
        fontSize: "16px",
        lineHeight: "132%",
        ...sx,
      }}
    >
      {buttonText}
    </Button>
  );
}
