import { ReactElement } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/joy";
import { theme } from "styles/theme";

export default function AccordionTemplate({
  summary,
  details,
  noBorder,
}: {
  summary: string;
  details: (string | ReactElement) | (string | ReactElement)[];
  noBorder?: boolean;
}) {
  return (
    <Accordion
      sx={{
        px: "0px",
        py: "10px",
        pt: "0px",
        ...(noBorder && { borderBottomWidth: "0px" }),
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          m: 0,
        }}
      >
        <Typography
          variant={"Subtitle"}
          sx={{ fontWeight: 700, color: theme.vars.palette.Content.Strong }}
        >
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant={"Body"}
          sx={{ fontWeight: 500, color: theme.vars.palette.Content.Strong }}
        >
          {details}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}