import { Button } from "@mui/joy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_AUTOMATIC_THOUGHT_DETAIL_ENDPOINT,
  CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
  conceptApi,
} from "api/conceptApi";
import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function AddConceptNoteButton({
  thoughtRecordKey,
}: {
  thoughtRecordKey: string;
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);
  const [disabled, setDisabled] = useState<boolean>(true);

  const getAutomaticThoughtDetailQueryFn = useCallback(
    () =>
      conceptApi.noteAutomaticThoughtDetail(
        `${patientState?.patientId}`,
        thoughtRecordKey
      ),
    [patientState?.patientId, thoughtRecordKey]
  );

  const { refetch } = useQuery(
    [
      CONCEPT_DOMAIN,
      CONCEPT_NOTE_AUTOMATIC_THOUGHT_DETAIL_ENDPOINT,
      patientState?.patientId,
      thoughtRecordKey,
    ],
    getAutomaticThoughtDetailQueryFn,
    {
      enabled: !!patientState?.patientId && !!thoughtRecordKey,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setDisabled(data.result !== undefined);
        }
      },
    }
  );

  const addAutomaticThoughtQueryFn = useCallback(
    () =>
      conceptApi.addAutomaticThought(
        `${patientState?.patientId}`,
        `${thoughtRecordKey}`
      ),
    [patientState?.patientId, thoughtRecordKey]
  );

  const { mutate: addAutomaticThought } = useMutation(
    addAutomaticThoughtQueryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
          patientState?.patientId,
        ]);

        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_AUTOMATIC_THOUGHT_DETAIL_ENDPOINT,
          patientState?.patientId,
          thoughtRecordKey,
        ]);
      },
    }
  );

  return (
    <Button
      size="sm"
      variant="solid"
      color="primary"
      disabled={disabled}
      onClick={() => {
        addAutomaticThought();
      }}
    >
      개념화 노트에 추가하기
    </Button>
  );
}