import * as React from "react";
import ButtonWithModal from "../../common/ButtonWithModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
  thoughtRecordApi,
} from "api/thoughtRecordApi";
import {
  HOME_DOMAIN,
  HOME_THOUGHTRECORD_LIST_ENDPOINT,
} from "../../../api/homeApi";

export default function RemoveThoughtRecordButton({
  thoughtRecordKey,
  thoughtRecordSummary,
  onClose,
  onRemove,
}: {
  thoughtRecordKey: string;
  thoughtRecordSummary: string;
  onClose: () => void;
  onRemove: (removedKey: string) => void;
}) {
  const queryClient = useQueryClient();
  const patientId = thoughtRecordKey.split(":")[0];

  const { mutate: thoughtRecordRemove } = useMutation(
    () => thoughtRecordApi.remove(`${thoughtRecordKey}`),
    {
      onSuccess: () => {
        onRemove(thoughtRecordKey);

        queryClient.invalidateQueries([
          THOUGHTRECORD_DOMAIN,
          THOUGHTRECORD_LIST_ENDPOINT,
        ]);

        queryClient.invalidateQueries([
          HOME_DOMAIN,
          HOME_THOUGHTRECORD_LIST_ENDPOINT,
          patientId,
        ]);
      },
    }
  );

  return (
    <ButtonWithModal
      startDecorator={undefined}
      buttonText="생각기록지 삭제"
      title={"정말로 삭제하시겠습니까?"}
      subtitle={`*삭제하면 복구할 수 없습니다.`}
      content={`${thoughtRecordSummary}`}
      cancelText="취소"
      actionText={"확인"}
      action={thoughtRecordRemove}
      isListItemButton
      actionButtonColor={"danger"}
      onClose={onClose}
    />
  );
}