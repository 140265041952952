import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
  conceptApi,
} from "api/conceptApi";

import ButtonWithModal from "components/common/ButtonWithModal";
import Link from "components/common/Link";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function AutomaticThoughtConfirmButton({
  automaticThoughtId,
  isOtherAutomaticThoughtAlreadyProgress = false,
  disabled,
  isForValue = false,
}: {
  automaticThoughtId: string;
  isOtherAutomaticThoughtAlreadyProgress?: boolean;
  disabled?: boolean;
  isForValue?: boolean;
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const confirmConceptNoteQueryFn = useCallback(
    () =>
      conceptApi.confirmConceptNoteAutomaticThought(
        `${patientState?.patientId}`,
        `${automaticThoughtId}`,
        isForValue
      ),
    [patientState?.patientId, automaticThoughtId, isForValue]
  );

  const { mutate: confirmConceptNote } = useMutation(
    confirmConceptNoteQueryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_AUTOMATIC_THOUGHT_LIST_ENDPOINT,
          patientState?.patientId,
        ]);
      },
    }
  );

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={
        isOtherAutomaticThoughtAlreadyProgress
          ? `현재 다루고 있는 자동적 사고가 있어요. 끝나면 이어서 ${isForValue ? "<가치 살펴보기>" : "<자동적 사고 살펴보기>"}를 진행할까요?`
          : `이 자동적 사고로 ${isForValue ? "<가치 살펴보기>" : "<자동적 사고 살펴보기>"}를 진행해도 될까요?`
      }
      render={
        <Link
          text="확정"
          opacity={disabled ? 0.4 : 1}
          textColor="primary.solid"
        />
      }
      disableModalOpen={disabled}
      actionText={
        isOtherAutomaticThoughtAlreadyProgress ? "미리 확정해두기" : "확정하기"
      }
      action={confirmConceptNote}
    />
  );
}