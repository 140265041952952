import { useEffect, useState } from "react";
import { Option, Select, Stack, Textarea } from "@mui/joy";
import * as _ from "lodash";
import SaveConceptNoteWithReasonButton from "../../../ai/SaveConceptNoteWithReasonButton";
import { valueDomainList } from "../../../../api/dashboardApi";

interface AutomaticThoughtInputProps {
  type: "textarea" | "select";
  isForValue?: boolean;
  initialValue?: string;
  onSave?: (updatedValue: string) => void;
}

export default function AutomaticThoughtInput({
  type,
  isForValue = false,
  initialValue,
  onSave,
}: AutomaticThoughtInputProps) {
  const [value, setValue] = useState<string>(initialValue ?? "");
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [modificationReason, setModificationReason] = useState<string>("");

  const thoughtTrapOptions: string[] = [
    "책임 과다",
    "근거 부족",
    "부정 편향",
    "평가",
    "개념화",
    "반추",
    "잘 모르겠어요",
  ];

  const valueDomainOptions = [...valueDomainList, "잘 모르겠어요"];

  useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  useEffect(() => {
    setIsEdited(!_.isEqual((initialValue ?? "").trim(), value.trim()));
  }, [initialValue, value]);

  return (
    <Stack spacing={0.5}>
      {type === "textarea" ? (
        <Textarea
          value={value ?? ""}
          onChange={(e) => setValue(e.target.value)}
          sx={{
            px: "10px",
            py: "12px",
            minHeight: "50px",
            width: "100%",
            borderWidth: "1px",
            borderColor: "divider",
            backgroundColor: "background.level1",
            boxShadow: "none",
            "--joy-focus-thickness": "0px",
            "--variant-borderWidth": "0px",
            "--Textarea-gap": "0px",
          }}
        />
      ) : (
        <Select
          value={value}
          onChange={(event, newVal) => {
            // newVal: Option value
            const selected = newVal || "";
            setValue(selected === "잘 모르겠어요" ? "" : selected);
          }}
          sx={{
            px: "10px",
            py: "12px",
            minHeight: "50px",
            borderWidth: "1px",
            borderColor: "divider",
            backgroundColor: "background.level1",
            boxShadow: "none",
            "--joy-focus-thickness": "0px",
          }}
        >
          {isForValue
            ? valueDomainOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))
            : thoughtTrapOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
        </Select>
      )}

      <Stack direction="row" sx={{ width: "100%" }} justifyContent="end">
        {isEdited && (
          <SaveConceptNoteWithReasonButton
            type={type}
            aiValue={initialValue}
            value={value}
            modificationReason={modificationReason}
            setModificationReason={setModificationReason}
            updateAction={() => onSave?.(value)}
          />
        )}
      </Stack>
    </Stack>
  );
}
