import { useRecoilValue } from "recoil";
import { Stack, Typography } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import TestResult from "components/dashboard/TestResult";
import { patientAtom } from "recoil/patientAtom";
import {
  DASHBOARD_DETAIL_ENDPOINT,
  DASHBOARD_DOMAIN,
  dashboardApi,
  DashboardType,
} from "api/dashboardApi";
import { useQueries } from "@tanstack/react-query";
import ContentTemplate from "../content/ContentTemplate";
import Progress from "components/dashboard/Progress";
import MyConcernAndGoal from "components/dashboard/MyConcernAndGoal";
import { taskListAtom } from "recoil/taskListAtom";
import {
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_CONCERN_AND_GOAL_DETAIL_ENDPOINT,
  conceptApi,
  ConcernAndGoal,
} from "../../../api/conceptApi";

export default function MyProgressContent() {
  const patientState = useRecoilValue(patientAtom);
  const taskListState = useRecoilValue(taskListAtom);

  const [dashboardData, setDashboardData] = useState<DashboardType>();
  const [concernAndGoal, setConcernAndGoal] = useState<
    ConcernAndGoal | undefined
  >(undefined);

  const concernAndGoalQueryFn = useCallback(
    () => conceptApi.noteConcernAndGoalDetail(`${patientState?.patientId}`),
    [patientState]
  );

  const dashBoardQueryFn = useCallback(
    () => dashboardApi.load(`${patientState?.patientId}`),
    [patientState]
  );

  const results = useQueries({
    queries: [
      {
        enabled: !!patientState?.patientId,
        queryKey: [
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_CONCERN_AND_GOAL_DETAIL_ENDPOINT,
          patientState?.patientId,
        ],
        queryFn: concernAndGoalQueryFn,
      },
      {
        enabled: !!patientState?.patientId,
        queryKey: [
          DASHBOARD_DOMAIN,
          DASHBOARD_DETAIL_ENDPOINT,
          patientState?.patientId,
        ],
        queryFn: dashBoardQueryFn,
      },
    ],
  });

  const concernAndGoalResult = results[0].data?.result;
  const dashBoardResult = results[1].data?.result;

  useEffect(() => {
    if (concernAndGoalResult) {
      setConcernAndGoal(concernAndGoalResult);
    }
  }, [patientState, concernAndGoalResult]);

  useEffect(() => {
    if (dashBoardResult) {
      setDashboardData(dashBoardResult);
    }
  }, [patientState, dashBoardResult]);

  useEffect(() => {
    if (!patientState?.patientId) {
      setDashboardData({
        concept: {},
        history: {},
        selfCheckResult: {},
      });
    }
  }, [patientState]);

  const is1_2_A_done = taskListState.find(
    (element) => element.taskId === "1-2-A"
  )?.lastDoneAt;

  return (
    <ContentTemplate>
      {dashboardData && (
        <Stack spacing={"100px"} sx={{}}>
          <Stack spacing={"16px"}>
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              나의 프로그램
            </Typography>
            <MyConcernAndGoal
              concern={concernAndGoal?.concern}
              goal={concernAndGoal?.goal}
            />
          </Stack>

          {is1_2_A_done && <Progress />}
          <Stack spacing={2}>
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              부정적 반응
            </Typography>
            <TestResult {...dashboardData.selfCheckResult} />
          </Stack>
        </Stack>
      )}
    </ContentTemplate>
  );
}