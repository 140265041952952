import { memo } from "react";
import { getTaskIdFromTaskKey } from "logic/logics";
import useGetLocale from "hooks/useGetLocale";

function ImageCell({ taskKey, url }: { taskKey: string; url: string }) {
  const locale = useGetLocale();

  const isPng = url.includes(".png");

  const getImagePath = (): string => {
    const taskId = taskKey.includes("_")
      ? taskKey.split("_")[0]
      : getTaskIdFromTaskKey(taskKey);
    const localeSuffix = isPng ? "" : `_${locale}.png`;
    return require(
      `../../../../static/activity/${taskId}/${url}${localeSuffix}`
    );
  };

  let imagePath;
  if (taskKey.startsWith("4-5")) {
    imagePath = require(
      `../../../../static/activity/4-5-A/${url}${isPng ? "" : `_${locale}.png`}`
    );
  } else {
    imagePath = getImagePath();
  }

  return <img style={{ borderRadius: "6px" }} alt={"empty"} src={imagePath} />;
}

export default memo(ImageCell);